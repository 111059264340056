import React, { useEffect, useState } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import { Col, Container, Card, Row, CardBody } from "reactstrap";
import { MDBBtn, MDBCard, MDBCardBody, MDBCardTitle, MDBCol, MDBIcon, MDBRow } from "mdb-react-ui-kit";
import PhoneInput, { isValidPhoneNumber } from 'react-phone-number-input'
import { emailVerify, getAllPricingPlans, numberSignUp } from "../../../services/apiServices";
import { useNavigate, useLocation } from "react-router";
import { isEmail, isEmpty } from "../../../validators/helper.js";

function Offersignup() {
    const [selectedTerm, setSelectedTerm] = useState("6");
    const [showPlans, setShowPlans] = useState(false);
    const [otp, setOtp] = useState("");
    const [notp, setNotp] = useState("");
    const [eotp, setEotp] = useState("");
    const [enotp, setEnotp] = useState("");
    const [error, setError] = useState("");
    const [eError, setEerror] = useState("");
    const [verror, setVerror] = useState({})
    const [euserExists, setEuserExists] = useState(false);
    const [phnumber, setPhnumber] = useState("");
    const [userExists, setUserExists] = useState(false);
    const [captchaValue, setCaptchaValue] = useState(false);
    const navigate = useNavigate();
    const [plansinfo, setPlanInfo] = useState([])
    const [signupForm, setsignupForm] = useState({
        firstname: "",
        lastname: "",
        number: "",
        email: "",
        password: "",
        confirmp: "",
        tempcaptcha: "",
        businessname: "",
        businessexperience: ""
    });
    const [everify, setEverify] = useState(false)
    const [verify, setVerify] = useState(false)
    const [planid, setPlanid] = useState("")
    const [selectplan, setSelectplan] = useState("Lean")
    const handleTermSelection = (term) => {
        setSelectedTerm(term);
        setShowPlans(term === "6" || term === "12");
        if (term == "1") {
            const filterPlan = plansinfo && plansinfo.length > 0 ? plansinfo.filter((data) => data.name === "Free Starter")[0]._id : ""
            console.log(filterPlan);

            setPlanid(filterPlan)
            setSelectplan(term == "1" ? "Free Starter" : "")
        }

    };

    console.log(error);

    const handlePlanSelection = (term) => {
        console.log(term);
        setSelectplan(term)
        const filterPlan = plansinfo && plansinfo.length > 0 ? plansinfo.filter((data) => data.name === term)[0]._id : ""
        console.log(filterPlan);

        setPlanid(filterPlan)
    };
    useEffect(() => {
        fetchData()
    }, [])

    const fetchData = async () => {
        const response = await getAllPricingPlans();
        if (!response || response === "false") {
            return;
        }
        const recinfo = await response.json();
        console.log(recinfo);

        if (!recinfo) {
            return;
        }
        if (recinfo === "false") {
            return;
        }
        setPlanInfo(recinfo.pricingPlans);
        const filterPlan = recinfo.pricingPlans && recinfo.pricingPlans.length > 0 ? recinfo.pricingPlans.find(item => item.name === "Lean")._id : ""

        setPlanid(filterPlan)
        setSelectplan("Lean")
    };

    const finalamount = () => {
        const filterPlan = plansinfo && plansinfo.length > 0 ? plansinfo.find(item => item.name === selectplan) : {};
        console.log(filterPlan);

        return selectedTerm == "12" ? filterPlan.priceannual && Number(filterPlan.priceannual.fpricemontly) * 12 : filterPlan.pricemonthly && Number(filterPlan.pricemonthly * 6)
    }
    const evalidation = async (e) => {
        e.preventDefault();

        if (eotp !== enotp) {
            setEerror("Invalid OTP.Please try again.");
        }
        else {
            setEerror("OTP verified Sucessfully");
            setEverify(true)
            localStorage.setItem("email", JSON.stringify(signupForm.email));
            // if (planId) {
            // navigate(`/billingsubscriptionplan?id=${planId}&value=${planTimePeriod}`);
            // } else {
            // navigate(`/signup`);
            // }
        }

    }

    const eotpfield = async (e) => {
        e.preventDefault();
        if (!isEmail(signupForm.email)) {
            setEerror("Please enter valid email!");
        }
        else {
            const response = await emailVerify({ email: signupForm.email, name: signupForm.firstname + " " + signupForm.lastname });
            let resData = await response.json();
            console.log(resData);
            if (resData.status !== 200) {
                setEerror(resData.status === 400 ? "Please try again." : "email already exits in the system!")
            }
            else {
                setEerror("OTP Sent Sucessfully");
                setEuserExists(true);
                setEnotp(resData.otp);
                // setsignupForm({
                //     ...signupForm,
                //     email: phnumber
                // })
            }


        }
    }
    const validation = async (e) => {
        e.preventDefault();
        console.log(phnumber);
        console.log(otp, notp);

        if (otp !== notp) {
            setError("Invalid OTP.Please try again.");
        }
        else {
            setError("OTP verified Sucessfully");
            setVerify(true)
            localStorage.setItem("number", JSON.stringify(phnumber));
            // if (planId) {
            // navigate(`/billingsubscriptionplan?id=${planId}&value=${planTimePeriod}`);
            // } else {
            // navigate(`/signup`);
            // }
        }

    }

    const otpfield = async (e) => {
        e.preventDefault();
        if (!isValidPhoneNumber(phnumber)) {
            setError("Please enter valid contact number!");
        }
        else {
            const response = await numberSignUp({ phone: phnumber });
            let resData = await response.json();
            // console.log(resData);
            if (resData.status !== 200) {
                setError(resData.status === 400 ? "Please try again." : "Number already exits in the system!")
            }
            else {
                setError("OTP Sent Sucessfully");
                setUserExists(true);
                setNotp(resData.otp);
                setsignupForm({
                    ...signupForm,
                    number: phnumber
                })
            }


        }
    }


    function updateLoginUser(value) {
        // e.preventDefault();
        setPhnumber(value);
        setOtp("");
        setUserExists(false);
        setError("");
    }

    function updateForm(value) {
        return setsignupForm((prev) => {
            return { ...prev, ...value };
        });
    }
    console.log(signupForm);

    let validateSForm = (e) => {
        e.preventDefault();
        // e.stopPropagation();
        console.log("Validating form...");

        try {

            let errors = {};
            console.log(signupForm);
            if (isEmpty(signupForm.firstname)) {
                errors.name = "Name can't be blank";
            }
            if (isEmpty(signupForm.lastname)) {
                errors.name = "Name can't be blank";
            }
            if (isEmpty(signupForm.businessname)) {
                errors.businessname = "Businessname can't be empty."
            }
            if (isEmpty(signupForm.businessexperience)) {
                errors.businessexperience = "Field can't be empty."
            }
            if (isEmpty(signupForm.email)) {
                errors.email = "Email can't be blank";
            } else if (!isEmail(signupForm.email)) {
                errors.email = "Please enter valid email";
            } else if (!captchaValue) {
                errors.tempcaptcha = "Please check the captcha"
            }
            // }
            // if (!isEmpty(phnumber)) {
            //     errors.number = "Please Click Verify";
            // } else {
            if (isEmpty(signupForm.number)) {
                errors.number = "Field can't be empty.";
            } else if (!isEmpty(signupForm.number) && !isValidPhoneNumber(signupForm.number)) {
                errors.number = "Please enter valid Phone Number";
            }

            // }

            if (isEmpty(errors)) {
                return true;
            } else {
                return errors;
            }
        } catch (e) {
            console.log(e);

        }
    };

    const submitHandler = async (e) => {
        e.preventDefault();
        e.stopPropagation();

        const updates = {
            firstname: signupForm.firstname,
            lastname: signupForm.lastname,
            email: signupForm.email,
            number: signupForm.number,
            businessname: signupForm.businessname,
            businessexperience: signupForm.businessexperience,
            planid: planid,
            from: "offer"
        };
        let noerrors = validateSForm(e);
        console.log(noerrors);
        if (noerrors === true) {
            console.log(updates, '00000000000');

            localStorage.setItem("landingpageinfo", JSON.stringify(updates));
            if (planid !== "") {


                const value = (selectedTerm == "12" ? 1 : 0)
                console.log(selectedTerm);
                navigate(`/billingsubscriptionplan?id=${planid}&value=${value}`);
            }
            //   let saverep = await createGBussinessInfo(updates);
            //   if (saverep === "false") {
            //     setApiError(true)
            //     return;
            //   }
            //   let jsOBj = await saverep.json();

            //   if (saverep.status !== 200) {
            //     console.log("Email already exits in the system!");
            //   }
            //   else {
            //     console.log(jsOBj);
            //     console.log(jsOBj.token);
            //     setToken(jsOBj.token);
            //     await setUserSession(jsOBj.userInfo);
            //     navigate("/dashboard");

            //   }
        } else {
            setVerror(noerrors)
        }
    };


    function verifyCaptchaCallback(resp) {
        console.log("Respons for captcha");
        console.log(resp);
        if (!resp) {
            setCaptchaValue(false);
        }
        else {
            console.log("true");
            setCaptchaValue(true);
        }
    }
    return (
        <>
            <MDBRow className="d-flex align-items-start">

                {/* Content Card */}
                <MDBCol sm={12} lg={6} md={6} className="content-card">
                    <div className="title-heading">
                        {/* <p className="para-desc text-muted mt-5 text-large">
                            Announcing the launch of <b>EasyMembr</b>, our all-in-one gym management solution. To celebrate, we’re offering an exclusive launch discount:
                        </p>
                        <ul className="para-desc text-muted text-large">
                            <li><b>10% OFF</b> on all plans</li>
                            <li><b>1 extra month free</b></li>
                        </ul>
                        <p className="para-desc text-muted text-large">
                            Simply use the coupon code <b>"NEWYEAR"</b> in the next screen when signing up before <b>31st December</b> to take advantage of this offer.
                        </p>
                        {selectedTerm == "1" && (
                            <p className="para-desc text-muted text-large">You pay <b>INR 0</b> now to activate the trial account for <b>30 days</b>. <b>No Credit Card</b> required</p>
                        )} */}
                        <br />
                        <h3 className="mt-2">Why Choose EasyMembr?</h3>
                        <ul>
                            {/* <li><strong>Risk-Free Trial:</strong> No upfront worries, just results.</li> */}
                            <li><strong>World-Class Gym Management Software:</strong> Simplify your operations and focus on growth.</li>
                            <li><strong>Satisfaction Guaranteed:</strong> Experience the Easymembr difference, or get your money back.</li>
                        </ul>
                        <br />
                        <br /> <br />
                        <h3>🎉 Don’t Miss Out!</h3>
                        <p>Grab these festive deals before they disappear!</p>
                        <p><strong>Valid from:</strong> January 11, 2025, to January 25, 2025.</p>
                        <p class="highlight">Act Now and Take Your Fitness Business to New Heights with EasyMembr!</p>
                    </div>
                </MDBCol>

                <MDBCol sm={12} lg={6} md={6} className="mt-4 pt-2 mt-sm-0 pt-sm-0">
                    <div>
                        <MDBCard className="border w-100">
                            <MDBCardBody>
                                {/* <MDBCardTitle>Sign Up</MDBCardTitle> */}
                                <div className="title-heading my-lg-auto">
                                    <div className="border-0" style={{ zIndex: 1 }}>
                                        <CardBody className="p-0">
                                            <form className="login-form mt-4" onSubmit={submitHandler}>
                                                <Row>

                                                    <div>Business Informaion</div>
                                                    {/* First Name */}
                                                    <Col md={6} className="mb-3">
                                                        <div>
                                                            <label className="form-label">
                                                                First Name<span className="text-danger">*</span>
                                                            </label>
                                                            <input
                                                                type="text"
                                                                className="form-control"
                                                                placeholder="First Name"
                                                                onChange={(e) => updateForm({ firstname: e.target.value })} value={signupForm.firstname}
                                                            />
                                                        </div>
                                                        {verror.name && (
                                                            <>
                                                                <p className="clr-red">{verror.name}</p>
                                                            </>
                                                        )}
                                                    </Col>


                                                    {/* Last Name */}
                                                    <Col md={6} className="mb-3">
                                                        <div>
                                                            <label className="form-label">
                                                                Last Name<span className="text-danger">*</span>
                                                            </label>
                                                            <input
                                                                type="text"
                                                                className="form-control"
                                                                placeholder="Last Name"
                                                                onChange={(e) => updateForm({ lastname: e.target.value })} value={signupForm.lastname}
                                                            />
                                                        </div>

                                                        {verror.name && (
                                                            <>
                                                                <p className="clr-red">{verror.name}</p>
                                                            </>
                                                        )}
                                                    </Col>

                                                    {/* Business Name */}
                                                    <Col md={6} className="mb-3">
                                                        <div>
                                                            <label className="form-label">
                                                                Business Name<span className="text-danger">*</span>
                                                            </label>
                                                            <input
                                                                type="text"
                                                                className="form-control"
                                                                placeholder="Business Name"
                                                                onChange={(e) => updateForm({ businessname: e.target.value })} value={signupForm.businessname}
                                                            />
                                                        </div>
                                                        {verror.businessname && (
                                                            <>
                                                                <p className="clr-red">{verror.businessname}</p>
                                                            </>
                                                        )}
                                                    </Col>

                                                    {/* Business Experience */}
                                                    <Col md={6} className="mb-3">
                                                        <div>
                                                            <label className="form-label">
                                                                Business Age<span className="text-danger">*</span>
                                                            </label>
                                                            <div className="d-flex justify-content-center align-items-center">
                                                                <input
                                                                    type="text"
                                                                    className="form-control"
                                                                    placeholder='Ex:- 1/1.5/ 2'
                                                                    onChange={(e) => updateForm({ businessexperience: e.target.value })} value={signupForm.businessexperience}
                                                                />
                                                                <label>year(s)</label>
                                                            </div>
                                                        </div>

                                                        {verror.businessexperience && (
                                                            <>
                                                                <p className="clr-red">{verror.businessexperience}</p>
                                                            </>
                                                        )}
                                                    </Col>





                                                    {/* ReCAPTCHA */}
                                                    <Col md={12} className="mt-3">
                                                        <div>
                                                            <ReCAPTCHA
                                                                className="mt-3 w-100 recapthca"
                                                                sitekey={"6LfQZGglAAAAAPPUCjiIsnyCvmGisjTTowbcFP3_"}
                                                                onChange={
                                                                    // (e) =>
                                                                    verifyCaptchaCallback
                                                                    // ({ captcha: e.target.value })
                                                                }
                                                            />
                                                        </div>
                                                        {verror.tempcaptcha && (
                                                            <>
                                                                <p className="clr-red">{verror.tempcaptcha}</p>
                                                            </>
                                                        )}
                                                    </Col>

                                                    {captchaValue && <div>Contact Informaion</div>}
                                                    {/* Email */}
                                                    {captchaValue && <Col md={12}>
                                                        <div>
                                                            <label className="form-label">
                                                                Your Email<span className="text-danger">*</span>
                                                            </label>  <div className="d-flex align-items-center">
                                                                <input
                                                                    type="email"
                                                                    className="form-control w-80"
                                                                    placeholder="Email"
                                                                    onChange={(e) => updateForm({ email: e.target.value })} value={signupForm.email}
                                                                />
                                                                {(euserExists === true ? (!everify ? <MDBBtn style={{ whiteSpace: "nowrap" }} className="btn-primary ms-2 px-2 w-20" onClick={(e) => eotpfield(e)}>Resent OTP</MDBBtn> : <MDBIcon fas icon="check-circle" color="success" size="xl" className="ms-3" />)
                                                                    : <MDBBtn style={{ whiteSpace: "nowrap" }} className="btn-primary ms-2 px-2 w-20" onClick={(e) => eotpfield(e)}>Get OTP</MDBBtn>)}
                                                            </div></div>
                                                        {captchaValue && verror.email && (
                                                            <>
                                                                <p className="clr-red">{verror.email}</p>
                                                            </>
                                                        )}
                                                    </Col>}
                                                    {captchaValue && <div style={(eError === "OTP Sent Sucessfully" || eError === "OTP verified Sucessfully") ? { color: "green" } : { color: "red" }}>{eError}</div>}

                                                    {euserExists && captchaValue && (
                                                        <div>
                                                            <label className="form-label">Enter OTP<span className="text-danger">*</span></label>
                                                            <div className="d-flex">

                                                                <input type="text"
                                                                    className="form-control w-80" placeholder="Enter OTP"
                                                                    value={eotp}
                                                                    disabled={everify}
                                                                    onChange={(e) => setEotp(e.target.value)}
                                                                    required />
                                                                {!everify && euserExists === true && <MDBBtn style={{ whiteSpace: "nowrap" }} className="btn-primary ms-2 px-2 w-20" onClick={(e) => evalidation(e)}>Verify</MDBBtn>}
                                                            </div>
                                                        </div>
                                                    )}

                                                    {/* Mobile Number */}
                                                    {captchaValue && <div>
                                                        <label className="form-label mt-2">Your Mobile No.<span className="text-danger">*</span></label>
                                                        <div className="d-flex align-items-center">
                                                            <PhoneInput
                                                                className="form-control w-80"
                                                                defaultCountry="IN"
                                                                placeholder="Mobile Number"
                                                                value={phnumber}
                                                                disabled={userExists}
                                                                onInput={(e) => e.target.value = e.target.value.slice(0, 15)}
                                                                onChange={(value) => updateLoginUser(value)}
                                                            />
                                                            {(userExists === true ? (!verify ? <MDBBtn style={{ whiteSpace: "nowrap" }} className="btn-primary ms-2 px-2 w-20" onClick={(e) => otpfield(e)}>Resent OTP</MDBBtn> : <MDBIcon fas icon="check-circle" color="success" size="xl" className="ms-3" />)
                                                                : <MDBBtn style={{ whiteSpace: "nowrap" }} className="btn-primary ms-2 px-2 w-20" onClick={(e) => otpfield(e)}>Get OTP</MDBBtn>)}
                                                        </div>
                                                    </div>

                                                    }
                                                    {captchaValue && verror.number && (
                                                        <>
                                                            <p className="clr-red">{verror.number}</p>
                                                        </>
                                                    )}
                                                    {captchaValue && <div style={(error === "OTP Sent Sucessfully" || error === "OTP verified Sucessfully") ? { color: "green" } : { color: "red" }}>{error}</div>}

                                                    {userExists && captchaValue && (
                                                        <div >
                                                            <label className="form-label">Enter OTP<span className="text-danger">*</span></label>
                                                            <div className="d-flex"><input type="text"
                                                                className="form-control w-80" placeholder="Enter OTP"
                                                                value={otp}
                                                                disabled={verify}
                                                                onChange={(e) => setOtp(e.target.value)}
                                                                required />
                                                                {!verify && userExists === true && <MDBBtn style={{ whiteSpace: "nowrap" }} className="btn-primary ms-2 px-2 w-20" onClick={(e) => validation(e)}>Verify</MDBBtn>}
                                                            </div></div>
                                                    )}

                                                    {/* Terms Selection */}
                                                    <Col md={12} className="mt-3">
                                                        <label className="form-label">
                                                            Choose your term<span className="text-danger">*</span>
                                                        </label>
                                                        <div className="d-flex gap-2">
                                                            {/* <button
                                                                type="button"
                                                                className={`btn ${selectedTerm === "1" ? "btn-primary" : "btn-outline-primary"
                                                                    }`}
                                                                onClick={() => handleTermSelection("1")}
                                                            >
                                                                1 Month Free
                                                            </button> */}
                                                            <button
                                                                type="button"
                                                                className={`btn ${selectedTerm === "6" ? "btn-primary" : "btn-outline-primary"
                                                                    }`}
                                                                onClick={() => handleTermSelection("6")}
                                                            >
                                                                6 Months
                                                            </button>
                                                            <button
                                                                type="button"
                                                                className={`btn ${selectedTerm === "12" ? "btn-primary" : "btn-outline-primary"
                                                                    }`}
                                                                onClick={() => handleTermSelection("12")}
                                                            >
                                                                12 Months
                                                            </button>
                                                        </div>
                                                    </Col>

                                                    {/* Plans Selection */}

                                                    <Col md={12} className="mt-3">
                                                        <label className="form-label">
                                                            Choose your plan<span className="text-danger">*</span>
                                                        </label>
                                                        <div className="d-flex gap-2">
                                                            <button type="button" className={`btn ${selectplan == "Lean" ? "btn-primary" : "btn-outline-primary"}`}
                                                                onClick={() => handlePlanSelection("Lean")}
                                                            >
                                                                Lean Plan
                                                            </button>
                                                            <button type="button" className={`btn ${selectplan == "Pro" ? "btn-primary" : "btn-outline-primary"}`}

                                                                onClick={() => handlePlanSelection("Pro")}>
                                                                Pro Plan
                                                            </button>
                                                            <button type="button" className={`btn ${selectplan == "Premium" ? "btn-primary" : "btn-outline-primary"}`}
                                                                onClick={() => handlePlanSelection("Premium")}>
                                                                Premium Plan
                                                            </button>
                                                        </div>
                                                    </Col>

                                                    <span className="mt-2">Your final price exclusive of taxes is <span style={{ color: "green" }}>&#8377;{finalamount()}</span></span>


                                                    {/* Submit Button */}
                                                    <Col md={12}>
                                                        <div className="d-grid mt-4">
                                                            <button type="submit" className="btn btn-primary" disabled={phnumber != "" && !verify ? true : false}>
                                                                Signup
                                                            </button>
                                                        </div>
                                                    </Col>
                                                </Row>
                                            </form>
                                        </CardBody>
                                    </div>
                                </div>
                            </MDBCardBody>
                        </MDBCard>
                    </div>
                </MDBCol>
            </MDBRow>
        </>

    );
}

export default Offersignup;
