import React, { useState, useEffect, useRef } from "react";
import { MDBAlert, MDBCard, MDBCardBody, MDBCardHeader, MDBCardFooter } from "mdb-react-ui-kit";
import Webcam from "react-webcam";
import jsQR from "jsqr";
import { getMemberTransactions, getBusinessName } from "../src/services/apiServices";
import { useNavigate } from 'react-router-dom'; // Assuming you're using react-router for navigation
import GymLogo from "./components/images/Easy-MembrLogo.png";
import PageError from "../src/views/PageError";

const QRScanner = () => {
    const webcamRef = useRef(null);
    const [qrData, setQrData] = useState(null);
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();
    const [error, setError] = useState("")
    const [businessData, setBusinessData] = useState(null);
    const [checkinMsg, setCheckinMsg] = useState("");
    const [apiError, setApiError] = useState(false);















    useEffect(() => {
        const token = localStorage.getItem("token");

        if (!token) {
            navigate("/qrlogin");
        }
    }, [navigate]);




    const videoConstraints = {
        facingMode: { ideal: "environment" },
        width: 350,
        height: 350,

    };

    useEffect(() => {
        const storedData = localStorage.getItem("checkinmsg");

        if (storedData) {
            setCheckinMsg(storedData);

            const timer = setTimeout(() => {
                setCheckinMsg("");
                localStorage.removeItem("checkinmsg");
            }, 2000);

            return () => clearTimeout(timer);
        }
    }, []);


    const scanQRCode = () => {
        const video = webcamRef.current?.video;

        if (video && video.readyState === 4) {
            const canvas = document.createElement("canvas");
            canvas.width = video.videoWidth;
            canvas.height = video.videoHeight;

            const ctx = canvas.getContext("2d");
            ctx.drawImage(video, 0, 0, canvas.width, canvas.height);

            const imageData = ctx.getImageData(0, 0, canvas.width, canvas.height);
            const code = jsQR(imageData.data, imageData.width, imageData.height);

            if (code) {
                setQrData(code.data);
            }
        }
    };

    useEffect(() => {
        let animationFrameId;

        const render = () => {
            scanQRCode();
            animationFrameId = requestAnimationFrame(render);
        };

        render();

        return () => cancelAnimationFrame(animationFrameId);
    }, []);

    const requestCameraPermission = () => {
        navigator.mediaDevices
            .getUserMedia({ video: true })
            .then(() => {
                console.log("Camera access granted");
            })
            .catch((err) => {
                console.error("Camera access denied:", err);
            });
    };

    useEffect(() => {
        if (webcamRef.current) {
            requestCameraPermission();
        }
    }, []);

    useEffect(() => {
        const fetchApiData = async () => {
            if (!qrData) return;

            try {
                setLoading(true);

                let parsedData;
                try {
                    parsedData = JSON.parse(qrData);
                } catch (err) {
                    setError("Invalid QR code")
                    throw new Error("Invalid QR code. Unable to parse as JSON.");
                }

                const mid = parsedData?.mid;
                const bId = parsedData?.bid;
                const bid = JSON.parse(localStorage.getItem("loggedUserInfo")).gymid
                console.log("iddddddddddddd", bid, bId)
                if (!mid) {
                    throw new Error("MID is missing in the QR code.");
                }

                if (bid !== bId) {
                    setError("QR code doesn't match the logged-in gym.")
                    return;
                }

                const response = await getMemberTransactions(mid);
                if (!response.ok) {
                    throw new Error(`API call failed with status: ${response.status}`);
                }
                if (!response) {
                    return;
                }
                if (response === "false") {
                    setApiError(true)
                }

                const data = await response.json();
                if (!data || (Array.isArray(data) && data.length === 0)) {
                    setError("Invalid QR code. Please scan again.")
                    return;
                }
                navigate("/qrcheckin", { state: { data } });
            } catch (err) {
                console.error("Error:", err.message);

                setQrData(null);
            } finally {
                setLoading(false);
            }
        };

        fetchApiData();
    }, [qrData, navigate]);




    useEffect(() => {
        const fetchData = async () => {
            try {
                const datar = JSON.parse(localStorage.getItem("loggedUserInfo")).gymid
                if (datar) {
                    const updates = { bId: datar };
                    console.log("Request payload:", updates);

                    const result = await getBusinessName(updates);
                    if (!result) {
                        return;
                    }
                    if (result === "false") {
                        setApiError(true)
                    }
                    console.log("API result:", result);

                    if (result && result.json) {
                        const jsonData = await result.json();
                        setBusinessData(jsonData);
                        console.log("Business data set:", jsonData);
                    } else {
                        console.error("API returned no data or null.");
                    }
                } else {
                    console.warn("datar is undefined or null. Skipping API call.");
                }
            } catch (error) {
                console.error("Error fetching business name:", error);
            }
        };

        fetchData();
    }, []);



    useEffect(() => {
        if (error && !loading) {
            const timer = setTimeout(() => {
                setError("");
            }, 5000);

            return () => clearTimeout(timer);
        }
    }, [error, loading]);



    const handleReload = () => {
        window.location.reload();
    }



    return (


        <div className="h-100vhWave">
            {apiError ? <PageError handleReload={handleReload} /> : <MDBCard className="waveForm custom-shadow">
                <MDBCardHeader>

                    <div className="mobile-Head mb-4">
                        <div className="d-flex align-items-center justify-content-between Waiver-logo">
                            <img src={GymLogo} className="w-30 mob-w-40" alt="Business Logo" />
                            <div>

                            </div>
                            {businessData?.logourl && <img
                                src={businessData?.logourl || ""}
                                alt={GymLogo}
                                className="w-20 mob-w-40"
                            />}

                        </div>
                    </div>
                </MDBCardHeader>
                <MDBCardBody className="py-3 position-relative" style={{ minHeight: "calc(100vh - 170px)" }}>

                    <div className="mb-4">
                        {checkinMsg && (
                            <div className="alert alert-success text-center" role="alert">
                                {checkinMsg}
                            </div>
                        )}

                    </div>

                    <h4 className="mb-4 text-center">Scan QR Code</h4>

                    <div className="qr-webcamContainer">
                        <div className="qrDiv">
                            <Webcam
                                ref={webcamRef}
                                style={{ height: "350px", width: "350px" }}
                                videoConstraints={videoConstraints}
                                className="qr-webcam"
                            />
                            <div className="scanner-overlay">
                                <div className="corner top-left"></div>
                                <div className="corner top-right"></div>
                                <div className="corner bottom-left"></div>
                                <div className="corner bottom-right"></div>
                            </div>
                        </div>
                    </div>

                    {loading && (
                        <div className="text-center mt-3">
                            <p>Loading...</p>
                        </div>
                    )}


                    {error && !loading && (
                        <MDBAlert
                            color={["Invalid QR code", "please show valid QR cod", "QR code doesn't match the logged-in gym."].includes(error) ? "danger" : "success"}
                            autohide
                            position="top-center"
                            delay={5000}
                            appendToBody
                            open={error !== ""}
                            onClose={() => setError("")}
                        >
                            {error}
                        </MDBAlert>
                    )}


                </MDBCardBody>
                <MDBCardFooter className="border-0 text-center">
                    <p class="mt-2 text-muted">© 2025 Zencruz.</p>
                </MDBCardFooter>
            </MDBCard>
            }

        </div>
    );
};

export default QRScanner;
