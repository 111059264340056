import React, { forwardRef, useEffect, useState } from "react";
import { deleteGroupData, getGroupData, getPlans, planSSummary, updateGroupData } from '../services/apiServices';
import { format, parseISO } from "date-fns";
import { MDBBtn, MDBCard, MDBCardBody, MDBCardText, MDBCardTitle, MDBCheckbox, MDBCol, MDBDatatable, MDBIcon, MDBInput, MDBModal, MDBModalBody, MDBModalContent, MDBModalDialog, MDBModalFooter, MDBModalHeader, MDBModalTitle, MDBRow, MDBSelect } from 'mdb-react-ui-kit';
import DatePicker from 'react-datepicker';
import calImg from "./../components/images/calendar.svg";
import ExcelExportButton from "../components/exportToExcel.js";
import ReportsGraph from "../components/ReportsGraph";
import PageError from "./PageError";
import ReactGA from 'react-ga';
import Loader from "../components/Loader";


function PlanSummary() {
    const [apiError, setApiError] = useState(false);

    const [DayFilter1, setDayFilter1] = useState("Date");
    const [DayFilter, setDayFilter] = useState("4");
    const [customdate, setcustomdate] = useState(DayFilter === "6");
    const [basicModal, setBasicModal] = useState(false);
    const [graphData, setGraphData] = useState({})
    const toggleOpen = () => setBasicModal(!basicModal);
    const [graphCheck, setGraphCheck] = useState(false)
    const [selectedTrans, setSelectedTrans] = useState([])
    const [dateArray, setDateArray] = useState({});
    const [selectValue, setSelectValue] = useState([]);
    const [graphinfo, setGraphinfo] = useState({})
    const [selectData, setSelectData] = useState([
        { text: 'Select', value: "Select" },

    ]);
    const [loading, setLoading] = useState(false)
    const [dataFormat, setDataFormat] = useState("count")
    const [card, setCard] = useState({})
    const [plans, setPlans] = useState([]);
    const [basicData, setBasicData] = useState({
        columns: [],
        rows: [],
    });
    const today = new Date();
    const currentTime = new Date();
    const startOfToday = new Date(currentTime);
    startOfToday.setHours(0, 0, 0, 0);

    const endOfToday = new Date(currentTime);
    endOfToday.setHours(23, 59, 59, 999);

    const yesterday = new Date(currentTime);
    yesterday.setDate(currentTime.getDate() - 1);
    const startOfYesterday = new Date(yesterday);
    startOfYesterday.setHours(0, 0, 0, 0);

    const endOfYesterday = new Date(yesterday);
    endOfYesterday.setHours(23, 59, 59, 999);

    const startOfWeek = new Date(currentTime);
    startOfWeek.setDate(currentTime.getDate() - currentTime.getDay());
    startOfWeek.setHours(12, 0, 0, 0);
    const endOfWeek = new Date(currentTime);
    endOfWeek.setDate(startOfWeek.getDate() + 6);
    endOfWeek.setHours(12, 0, 0, 0);

    const startOfMonth = new Date(currentTime);
    startOfMonth.setDate(1);
    startOfMonth.setHours(0, 0, 0, 0);

    const endOfMonth = new Date(currentTime);

    const startOfYear = new Date(currentTime);
    startOfYear.setMonth(0, 1);
    startOfYear.setHours(0, 0, 0, 0);

    const endOfYear = new Date(currentTime);
    endOfYear.setMonth(11, 31);
    endOfYear.setHours(23, 59, 59, 999);
    today.setHours(23, 59, 0, 0);
    const [dateRange, setDateRange] = useState([startOfMonth, endOfMonth]);
    const [startDate, endDate] = dateRange;
    const [applyFilterClicked, setApplyFilterClicked] = useState(false)
    const [resetFilterClicked, setResetFilterClicked] = useState(false)
    const [basicData2, setBasicData2] = useState({
        columns: ["S.no", 'Order Id', 'Member', 'Transaction Date', "Plan Name", "Plan Price", 'Start Date', "Expirydate"],
        rows: [],
    })

    const [centredModal, setCentredModal] = useState(false);
    const toggleOpenPlan = () => setCentredModal(!centredModal);

    // console.log(plans);
    const [selectedPlans, setSelectedPlans] = useState([]);
    const [groupedPlans, setGroupedPlans] = useState([]);
    const [groupedPlansNew, setGroupedPlansNew] = useState([]);
    const [editingGroupName, setEditingGroupName] = useState(null);
    const [newGroupName, setNewGroupName] = useState("");
    const [groupInfo, setGropInfo] = useState({})
    const [selectedPlanType, setSelectedPlanType] = useState("Normal");
   

    useEffect(() => {
        ReactGA.pageview(window.location.pathname);
    }, []);


    const CalenderInput = forwardRef(({ value, onClick }, ref) => (
        <>
            <span className="gym-input forminput h-40">
                {value === "" ? "Custom Range" : value}
                &nbsp;
                <img
                    src={calImg}
                    onClick={onClick}
                    ref={ref}
                    alt="custom Range"
                    title="Select Custom Range"
                />
            </span>
        </>
    ));

    const getDates = (obj) => {
        setDateRange(obj);
    };


    const handleApplyAndToggle = () => {
        const sDate = DayFilter === "1" ? startOfToday : (DayFilter === "2" ? startOfYesterday : (DayFilter === "3" ? startOfWeek : (DayFilter === "4" ? startOfMonth : (DayFilter === "5" ? startOfYear : (DayFilter === "6" ? startDate : null)))))
        const eDate = DayFilter === "1" ? endOfToday : (DayFilter === "2" ? endOfYesterday : (DayFilter === "3" ? endOfWeek : (DayFilter === "4" ? endOfMonth : (DayFilter === "5" ? endOfYear : (DayFilter === "6" ? endDate : null)))))
        if (sDate !== null && eDate !== null) {
            setDateRange([sDate, eDate])
        } else {
            setDateRange([])
        }

        setApplyFilterClicked(true)
    }
    const handleResetAndToggle = () => {
        setSelectValue([]);
        setDataFormat("count")
        setDayFilter("4")
        setSelectedPlanType("Normal")
        setDateRange([startOfMonth, endOfMonth])
        setDayFilter1("Date")
        setGroupedPlans([])
        handleDeleteGroup()
        setResetFilterClicked(true)

    }

    useEffect(() => {
        fetchData();
    }, [applyFilterClicked, resetFilterClicked])

    useEffect(() => {
        fetchGData();
    }, [])
    useEffect(() => {
        setcustomdate(DayFilter === "6" ? true : false);
    }, [DayFilter]);

    async function fetchGData() {
        // console.log('hhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhh');

        const response = await getGroupData(
            localStorage.getItem("loggedUserInfo") && JSON.parse(localStorage.getItem("loggedUserInfo")).gymid, "planSummary"
        );
        // console.log(response);

        if (response === "false") {
            setApiError(true)
        } else {
            const groupdata = await response.json();
            // console.log(groupdata, '9iehhgjgkfj');
            setGropInfo(groupdata)
            setGroupedPlans(groupdata.groupdata)
            setGroupedPlansNew(groupdata.groupdata)
        }
    }

    async function fetchData() {
        setLoading(true)
        let startOfDate = startDate
        let endOfDate = endDate
        let Dateobj = {}

        if ((startOfDate && endOfDate)) {
            const startDatef = new Date(startOfDate)
            startDatef.setHours(0, 0, 0, 0)
            const utcstartDate = startDatef.toISOString();
            // location.filter.startDate = utcstartDate;
            let lastoftheday = new Date(endOfDate);
            lastoftheday.setHours(23, 59, 59, 999);
            const utcEndDate = lastoftheday.toISOString();

            //             location.filter.endDate = utcEndDate;
            Dateobj = {
                startDate: utcstartDate,
                endDate: utcEndDate,
            }
        }
        const response = await planSSummary(
            JSON.parse(localStorage.getItem("loggedUserInfo")).gymid, Dateobj
        );
        if (!response) {
            return;
        }
        if (response === "false") {
            setApiError(true)
            return;
        }

        const transactions = await response.json();
        // console.log(transactions);
        const resp = await getPlans(
            JSON.parse(localStorage.getItem("loggedUserInfo")).gymid
        );
        if (!resp) {
            return;
        }
        if (resp === "false") {
            setApiError(true)
            return;
        }
        const plans = await resp.json();
        // console.log(plans);


        const transactionCounts = plans.map(plan => {
            const count = transactions.alltrans.filter(transaction => transaction.plan._id === plan._id).length;
            return { ...plan, transactionCount: count };
        });

        transactionCounts.sort((a, b) => b.transactionCount - a.transactionCount);
        // console.log(transactionCounts);

        setPlans(transactionCounts);

        let filteredData = transactionCounts
        if (selectValue.length > 0) {


            const filteredSelectValue = selectValue.filter(value => typeof value === "string" && !value.startsWith("g:"));
            // if (groupedPlans.length > 0) {

            const filteredGroupValue = selectValue
                .filter(value => typeof value === "string" && value.startsWith("g:"))
                .map(value => value.replace("g:", ""));

            let groupPlanIds = [];
            filteredGroupValue.forEach(groupName => {
                const group = groupedPlans.find(g => g.groupname === groupName);
                if (group) {
                    groupPlanIds = groupPlanIds.concat(group.planids);
                }
            });

            const combinedPlanIds = [...filteredSelectValue, ...groupPlanIds];


            filteredData = transactionCounts.filter(item => combinedPlanIds.includes(item._id));
            // } else {

            //     filteredData = transactionCounts.filter(item => selectValue.includes(item._id));
            //     console.log(transactionCounts.filter(item => selectValue.includes(item._id)), "not groupedPlans");
            // }

        }


        console.log(transactions.alltrans, DayFilter1, filteredData);

        processData(transactions.alltrans, DayFilter1, filteredData)
        // setSelectValue(selectValue)

        setApplyFilterClicked(false)
        setResetFilterClicked(false)

    }




    const processData = (trans, dateformate, plan) => {


        if (dateformate === "Month") {


            const formattedData = trans.reduce((acc, cur) => {
                const date = new Date(cur.createdAt);
                const month = date.toLocaleString('default', { month: 'short' });
                const year = date.getFullYear();
                const monthYearKey = `${month}-${year}`;

                if (!acc[monthYearKey]) {
                    acc[monthYearKey] = { date: `${month}-${year}`, data: [] };
                }
                acc[monthYearKey].data.push(cur);
                return acc;
            }, {});

            const finalResponse = Object.values(formattedData);


            generateTableData(finalResponse, plan)

        } else if (dateformate === "Week") {


            const formattedData = trans.reduce((acc, cur) => {
                const date = new Date(cur.createdAt);
                const month = date.toLocaleString('default', { month: 'short' });
                const year = date.getFullYear();
                const weekOfMonth = getWeekOfMonth(date);
                const weekLabel = `${month} ${weekOfMonth === 1 ? weekOfMonth + "st" : weekOfMonth === 2 ? weekOfMonth + "nd" : weekOfMonth === 3 ? weekOfMonth + "rd" : weekOfMonth + "th"} week ${year}`;

                if (!acc[weekLabel]) {
                    acc[weekLabel] = { date: weekLabel, data: [] };
                }
                acc[weekLabel].data.push(cur);
                return acc;
            }, {});

            const finalResponse = Object.values(formattedData);

            generateTableData(finalResponse, plan)


        } else if (dateformate === "Year") {
            const formattedData = trans.reduce((acc, cur) => {
                const date = new Date(cur.createdAt);
                const year = date.getFullYear().toString();
                if (!acc[year]) {
                    acc[year] = { date: year, data: [] };
                }
                acc[year].data.push(cur);
                return acc;
            }, {});

            const finalResponse = Object.values(formattedData);
            generateTableData(finalResponse, plan)

        } else {
            const formattedData = trans.reduce((acc, cur) => {
                const dateKey = format(new Date(parseISO(cur.createdAt)), "yyyy-MM-dd");

                if (!acc[dateKey]) {
                    acc[dateKey] = { date: dateKey, data: [] };
                }
                acc[dateKey].data.push(cur);
                return acc;
            }, {});

            const finalResponse = Object.values(formattedData);
            // console.log(finalResponse);


            generateTableData(finalResponse, plan)

        }
    }

    const getWeekOfMonth = (date) => {
        const firstDayOfMonth = new Date(date.getFullYear(), date.getMonth(), 1);
        const dayOfWeek = firstDayOfMonth.getDay();
        const firstWeekStart = firstDayOfMonth - (dayOfWeek * 86400000);
        const weekNumber = Math.floor((date - firstWeekStart) / (7 * 86400000)) + 1;
        return weekNumber;
    };



    const generateInvoice = (price, discount, tax) => {
        const hasIncluded = tax && tax.length > 0 ? tax.some(taxItem => taxItem.TaxCheck === "Included") : true;
        let planPrice = hasIncluded ? parseFloat(price / (1 + ((tax && tax.length > 0) ? (tax.reduce((acc, obj) => acc + (obj.percentage ? (parseFloat(obj.percentage) / 100) : 0), 0)) : 0))).toFixed(2) : price
        const totalTaxAmount = (tax && tax.length > 0) && tax.reduce((accumulator, taxItem) => accumulator + (Number(taxItem.amount) || 0), 0);
        const discountedPrice = planPrice - discount;
        const finalPrice = discountedPrice + totalTaxAmount;
        if (isNaN(finalPrice)) {
            return 0;
        }
        return Math.round(finalPrice);
    }

    const generateTableData = (data1, plans) => {

        const groupedPlanMap = {};

        // Populate groupedPlanMap only if "Group" is selected
        if (selectedPlanType === "Group") {
            groupedPlans.forEach(group => {
                group.planids.forEach(planId => {
                    groupedPlanMap[planId] = group.groupname;
                });
            });
        }

        // Extract selected groups and plans from selectValue
        const selectedGroups = selectValue && selectValue.length > 0 && selectValue.filter(value => value.startsWith("g:")).map(value => value.replace("g:", ""));
        const selectedPlans = selectValue && selectValue.length > 0 && selectValue.filter(value => !value.startsWith("g:"));

        // Determine the plan names to display in the table
        let planNames;
        if (selectedPlanType === "Group") {
            // Show only selected groups, otherwise show all groups
            planNames = selectedGroups.length > 0
                ? [...new Set(groupedPlans.filter(group => selectedGroups.includes(group.groupname)).map(group => group.groupname))]
                : [...new Set(Object.values(groupedPlanMap))];
        } else {
            // Show only selected plans, otherwise show all plans
            planNames = selectedPlans.length > 0
                ? plans.filter(plan => selectedPlans.includes(plan._id.toString())).map(plan => groupedPlanMap[plan._id] || plan.name)
                : plans.map(plan => groupedPlanMap[plan._id] || plan.name);
        }

      

        const columns = ['Date', ...planNames];
      if (dataFormat !== "count") {
            columns.push('Invoice Amount');
        } else {
            columns.push('Total');
        }

        const rows = {};
      
        const processedTIds = {};
      
        data1.forEach(data2 => {
            const date = data2.date;

            if (!rows[date]) {
                rows[date] = Array(planNames.length).fill(0);
                rows[date].push(0);
                processedTIds[date] = new Set();
            }
            console.log("12222222222222222", data2)

            data2.data.forEach(transaction => {
                const planName = transaction.plan?.name;

                if (planName) {
                    const groupName = groupedPlanMap[transaction.itemid];
                    const index = planNames.indexOf(groupName || planName);

                    if (index !== -1) {
                        if (dataFormat === "count") {
                            rows[date][index]++;
                        } else {
                            const billInvoice = generateInvoice(
                                transaction.payment.itemprice,
                                transaction.payment.discount ? transaction.payment.discount.amount : 0,
                                transaction.payment.taxamounts
                            );
                            rows[date][index] += billInvoice;
                            rows[date][planNames.length] += billInvoice;
                            if (!processedTIds[date].has(transaction.tId)) {
                                processedTIds[date].add(transaction.tId);
                            }
                        }
                    }
                }
            });
        });

        if (dataFormat === "count") {
            Object.entries(rows).forEach(([date, counts]) => {
                rows[date][planNames.length] = counts.slice(0, planNames.length).reduce((acc, count) => acc + count, 0);
            });
        }

        const nonZeroColumnIndexes = new Set();
        columns.forEach((column, index) => {
            if (index === 0 || Object.values(rows).some(row => row[index - 1] !== 0)) {
                nonZeroColumnIndexes.add(index);
            }
        });

       const nonZeroColumns = columns.filter((_, index) => nonZeroColumnIndexes.has(index));

        const tableData = Object.entries(rows).map(([date, counts]) => {
            const filteredCounts = counts.filter((_, index) => nonZeroColumnIndexes.has(index + 1));
            return [date, ...filteredCounts];
        });

        setBasicData({
            columns: nonZeroColumns,
            rows: tableData.map(row => {
                return row.map((cell, index) => {
                    if (index === 0) {
                        return cell;
                    } else {
                        const planName = nonZeroColumns[index];
                        const date = row[0];
                        return (
                            <button className="popupmember button-link link-color"
                                key={planName}
                                onClick={() =>
                                    handleClik({
                                        date: date,
                                        plan: planName,
                                        data: data1.filter(item => item.date === date)
                                    })
                                }
                            >
                                {dataFormat === "count" ? cell : `₹${cell ? cell.toLocaleString('en-IN') : 0}`}
                            </button>
                        );
                    }
                });
            })
        });
        setLoading(false);
    };






    const handleClik = (tran) => {
        // console.log(tran);  
        if (tran.plan === 'Total' || tran.plan === 'Total Received' || tran.plan === "Invoice Amount") {
            // const totalInfo = []
            let filteredData = plans.length > 0 ? plans : [];
            if (selectValue.length > 0) {


                const filteredSelectValue = selectValue.filter(value => typeof value === "string" && !value.startsWith("g:"));

                const filteredGroupValue = selectValue
                    .filter(value => typeof value === "string" && value.startsWith("g:"))
                    .map(value => value.replace("g:", ""));

                let groupPlanIds = [];
                filteredGroupValue.forEach(groupName => {
                    const group = groupedPlans.find(g => g.groupname === groupName);
                    if (group) {
                        groupPlanIds = groupPlanIds.concat(group.planids);
                    }
                });

                const combinedPlanIds = [...filteredSelectValue, ...groupPlanIds];


                filteredData = plans.length > 0 ? plans.filter(item => combinedPlanIds.includes(item._id)) : [];
                const filteredTransactions = tran.data.length > 0 && tran.data[0].data.filter(transaction =>
                    filteredData.some(plan => plan._id === transaction.itemid)
                );

                // console.log(filteredTransactions);
                setSelectedTrans(filteredTransactions)
            } else {
                setSelectedTrans(tran.data.length > 0 && tran.data[0].data)
            }


        } else {
            let alldata;

            const group = groupedPlans.find(group => group.groupname === tran.plan);
            if (group) {
                alldata = tran.data.length > 0 && tran.data[0].data.filter(item => group.planids.includes(item.plan._id));
            } else {

                alldata = tran.data.length > 0 && tran.data[0].data.filter(item => item.plan.name === tran.plan);
            }

            // console.log(alldata);
            setSelectedTrans(alldata);
        }
        setDateArray(tran)
        setBasicModal(true)
    }

    // console.log(selectedTrans);

    useEffect(() => {

        const popupRow = [];
        let snumber = 1;

        selectedTrans && selectedTrans.length > 0 && selectedTrans.forEach((item, index) => {
            const rarray = [];
            rarray.push(snumber++);
            rarray.push(item.token);
            rarray.push(item.members.fname + " " + item.members.lname);
            rarray.push(<span className='trans-date'>{new Date(item.createdAt).toLocaleString("en-IN", { timeZone: "Asia/Kolkata" })}</span>);
            rarray.push(item.plan.name);
            rarray.push(item.plan.price);
            rarray.push(<span className='trans-date'>{new Date(item.startdate).toLocaleString("en-IN", { timeZone: "Asia/Kolkata" })}</span>);
            rarray.push(<span className='trans-date'>{new Date(item.expirydate).toLocaleString("en-IN", { timeZone: "Asia/Kolkata" })}</span>);
            popupRow.push(rarray);
        });
        setBasicData2({
            columns: ["S.no", 'Order Id', 'Member', 'Transaction Date', "Plan Name", "Plan Price", 'Start Date', "Expirydate"],
            rows: popupRow
        })
    }, [selectedTrans])

    const handleClickOutside = () => {

        setBasicData2({
            columns: ["S.no", 'Order Id', 'Member', 'Transaction Date', "Plan Name", "Plan Price", 'Start Date', "Expirydate"],
            rows: []
        })
        setSelectedTrans([])

        toggleOpen();

    }

    const downloaddata = (data, title) => {
        console.log(data, title);

        const cleanedData = data.rows.map(row => {
            return row.map(cell => {

                if (React.isValidElement(cell)) {
                    const value = cell.props.children;
                    if (typeof value === 'string') {

                        const cleanedValue = value.replace(/[₹,]/g, '');
                        const numericValue = Number(cleanedValue);

                        if (!isNaN(numericValue)) {
                            return {
                                ...cell,
                                props: {
                                    ...cell.props,
                                    children: numericValue
                                }
                            };
                        }
                    }
                }
                return cell;
            });
        });

        console.log(cleanedData);
        const list = {
            columns: data.columns,
            rows: cleanedData
        }
        ExcelExportButton(list, title);
    }


    useEffect(() => {

        const generatingGraphData = () => {
            const basrow = basicData.rows;
            const columns = basicData.columns;
            // console.log(basrow);
            // Extracting labels (months)
            const labels = basrow.map(row => row[0]);

            // Extracting datasets
            const datasets = [];
            for (let i = 1; i < columns.length - 1; i++) {
                // const str = ;
                // const number = parseFloat(str.replace('₹', ''));
                const columnName = columns[i];
                const data = basrow.map(row => {

                    const value = row[i].props.children;
                    if (typeof value === 'string' && value.includes('₹')) {
                        const numericValue = parseFloat(value.replace('₹', '').replace(/,/g, ''));
                        return numericValue;
                    } else {
                        return parseFloat(value);
                    }
                });

                datasets.push({
                    label: columnName,
                    data,
                    backgroundColor: `rgb(${Math.floor(Math.random() * 256)}, ${Math.floor(Math.random() * 256)}, ${Math.floor(Math.random() * 256)})`,
                });
            }


            setGraphinfo({
                title: "Plan Summary",
                bar: "Check-In(s)",
                xtitle: `${dataFormat === "count" ? "Member(s)" : "Amount"}`,
                ytitle: `${DayFilter1}(s)`,
            })
            setGraphData({
                labels,
                datasets,
            });
        };
        const maxmin = () => {
            const sums = {};
            const basrow = basicData.rows;
            const columns = basicData.columns;

            // console.log(basrow, columns);

            for (let i = 1; i < columns.length - 1; i++) {
                const columnName = columns[i];
                sums[columnName] = 0;

                for (let j = 0; j < basrow.length; j++) {
                    const columnValue = basrow[j][i];
                    // console.log(columnValue)
                    // Access the value correctly
                    const value = columnValue.props.children;
                    // console.log(value);
                    if (typeof value === 'string') {
                        // Handle value as a currency if it contains '₹'
                        if (value.includes('₹')) {
                            sums[columnName] += parseFloat(value.replace(/[₹,]/g, ''));
                        } else {
                            sums[columnName] += parseFloat(value);
                        }
                    } else {
                        // In case value is not a string
                        // console.log(value);
                        sums[columnName] += parseFloat(value);
                    }
                }
            }

            // Get the labels and data
            const labels = Object.keys(sums);
            const data = Object.values(sums);

            // console.log(labels, data);

            // Function to merge labels and data into a combined format
            const mergedData = mergeLabelsAndData(labels, data);

            // Find the max and min values
            let { max = 0, maxLabel, min = Number.MAX_SAFE_INTEGER, minLabel, rupee } = findMaxMinValuesWithLabels(mergedData);

            // Set the results
            setCard({
                max: { label: maxLabel, value: max > 0 ? max : "--" },
                min: { label: minLabel, value: min < Number.MAX_SAFE_INTEGER ? min : "--" },
                rupee: rupee
            });
        }
        generatingGraphData()
        maxmin()
    }, [basicData, DayFilter1])


    const mergeLabelsAndData = (labels, data) => {
        const mergedData = {};
        labels.forEach((label, index) => {
            mergedData[label] = data[index];
        });
        return mergedData;
    };

    const findMaxMinValuesWithLabels = (data) => {
        let maxLabel, minLabel;
        let rupee = (dataFormat === "count" ? false : true)
        let max = 0;
        let min = Number.MAX_SAFE_INTEGER;
        for (const label in data) {
            if (data.hasOwnProperty(label)) {
                const value = data[label];
                if (value > max) {
                    max = value;
                    maxLabel = label;
                }
                if (value < min && value > 0) {
                    min = value;
                    minLabel = label;
                }
            }
        }

        return { max, maxLabel, min, minLabel, rupee };
    };
    const handleReload = () => {
        window.location.reload();
    };


    useEffect(() => {
        const planSet = new Set();

        if (selectedPlanType === "Group") {
            groupedPlans.forEach(item => {
                planSet.add({ planName: item.groupname, id: `g:${item.groupname}` });
            });
        } else {
            const groupPlanIds = groupedPlans?.map(group => group.planids).flat();
            plans.forEach(plan => {
                if (plan._id) {
                    planSet.add({ planName: plan.name, id: plan._id });
                }
            });
        }

        const uniquePlans = Array.from(planSet);
        const formattedPlans = uniquePlans.map(plan => ({
            text: plan.planName,
            value: plan.id
        }));


        setSelectData(formattedPlans);
    }, [selectedPlanType, plans, groupedPlans]);



    // useEffect(() => {
    //     const planSet = new Set();
    //     if (groupedPlans.length > 0) {
    //         const groupPlanIds = groupedPlans.map(group => group.planids).flat();

    //         groupedPlans.forEach(item => {
    //             planSet.add({ planName: item.groupname, id: `g:${item.groupname}` })
    //         })
    //         plans.forEach(plan => {
    //             if (plan._id && plan._id.length > 0 && !groupPlanIds.includes(plan._id)) {
    //                 planSet.add({ planName: plan.name, id: plan._id });
    //             }
    //         });

    //     } else {
    //         plans.forEach(plan => {
    //             if (plan._id && plan._id.length > 0) {
    //                 planSet.add({ planName: plan.name, id: plan._id });
    //             }
    //         });
    //     }

    //     // plans.forEach(plan => {
    //     //     if (plan._id && plan._id.length > 0) {
    //     //         planSet.add(plan.name);
    //     //     }
    //     // });
    //     // console.log(typeof planSet);

    //     const uniquePlans = Array.from(planSet);
    //     const formattedTrainers = uniquePlans.map(plan => ({
    //         text: plan.planName,
    //         value: plan.id
    //     }));
    //     // if (resetFilterClicked) {
    //     //     setSelectValue(uniquePlans.map(plan => (
    //     //         plan
    //     //     )))
    //     // }


    //     setSelectData(formattedTrainers);
    // }, [plans, resetFilterClicked, groupedPlans]);



    const [optSmModal, setOptSmModal] = useState(false);

    const toggleOpenNew = () => setOptSmModal(!optSmModal);

    // console.log(groupedPlans, selectedPlans, '6666666666663555555555555555553');

    const handleCheckboxChange = (planId, type) => {
        if (type === "plan") {
            if (selectedPlans.includes(planId)) {
                setSelectedPlans(selectedPlans.filter(id => id !== planId));
            } else {
                setSelectedPlans([...selectedPlans, planId]);
            }
        } else if (type === "group") {
            const isGroupSelected = selectedPlans.includes(planId.groupname);

            if (isGroupSelected) {
                setSelectedPlans(selectedPlans.filter(name => name !== planId.groupname));
            } else {
                setSelectedPlans([
                    ...selectedPlans.filter(id => !planId.planids.includes(id)),
                    planId.groupname
                ]);
            }
        }
    };
    // console.log(selectedPlans);

    // const [groupCheck, setGroupCheck] = useState(true)

    // const groupSelectedPlans = () => {
    //     const groupPlanIds = [];

    //     selectedPlans.forEach(selected => {
    //         const group = groupedPlans.find(g => g.groupname === selected);
    //         if (group) {
    //             groupPlanIds.push(...group.planids);
    //         } else {
    //             groupPlanIds.push(selected);
    //         }
    //     });
    //     // console.log(groupPlanIds);

    //     if (groupPlanIds.length > 1) {

    //         const newGroup = {
    //             groupname: plans.find(plan => plan._id === groupPlanIds[0]) !== undefined
    //                 ? plans.find(plan => plan._id === groupPlanIds[0]).name + "(Group)"
    //                 : groupPlanIds[0],
    //             planids: [...new Set(groupPlanIds)],
    //             type: "group"
    //         };

    //         const updatedGroups = groupedPlans.filter(group => !selectedPlans.includes(group.groupname));
    //         setGroupedPlans([...updatedGroups, newGroup]);
    //         setSelectedPlans([]);
    //     }
    // };
    // console.log(selectedPlans);


    const [groupPlanIds, setGroupPlanIds] = useState([]);


    const groupSelectedPlans = () => {
        let tempGroupPlanIds = [];
        let selectedGroups = [];
        let selectedPlansOnly = [];

        selectedPlans.forEach(selected => {
            const group = groupedPlans.find(g => g.groupname === selected);
            // console.log(group);

            if (group) {
                selectedGroups.push(group);

            } else {
                selectedPlansOnly.push(selected);
            }
        });

        // Combine the plan ids from selected groups and selected plans
        selectedGroups.forEach(group => {
            tempGroupPlanIds.push(...group.planids);
        });
        tempGroupPlanIds.push(...selectedPlansOnly);

        setGroupPlanIds([...new Set(tempGroupPlanIds)]);
        // console.log(selectedGroups.length, '63543', selectedPlansOnly.length, "77777hdn");
        if (selectedGroups.length > 1 || (selectedGroups.length == 0 && selectedPlansOnly.length > 1)) {
            // console.log("step 1");

            const updatedGroups = groupedPlans.filter(group => !selectedPlans.includes(group.groupname));
            setGroupedPlans(updatedGroups);

            setSelectedPlans([]);
            setOptSmModal(true);
        } else if (selectedGroups.length === 1 && selectedPlansOnly.length >= 1) {
            // console.log("step 2");
            const updatedGroup = {
                ...selectedGroups[0],
                planids: [...new Set([...selectedGroups[0].planids, ...selectedPlansOnly])]
            };

            const updatedGroups = groupedPlans.map(group =>
                group.groupname === updatedGroup.groupname ? updatedGroup : group
            );
            setGroupedPlans(updatedGroups);

            setSelectedPlans([]);
        }


    };

    // console.log(groupedPlans, groupPlanIds, newGroupName, 'hghfshsj');



    const handleSaveGroup = () => {
        const newGroup = {
            groupname: newGroupName,
            planids: groupPlanIds,

        };
        const updatedGroups = [...groupedPlans, newGroup];
        setGroupedPlans(updatedGroups);
        setNewGroupName("");
        setGroupPlanIds([])
        setOptSmModal(false);
    }



    const handleRemovePlan = (planId) => {
        const updatedGroups = groupedPlans.map(group => ({
            ...group,
            planids: group.planids.filter(id => id !== planId)
        })).filter(group => group.planids.length > 0);
        setGroupedPlans(updatedGroups);
        setSelectedPlans(selectedPlans.filter(id => id !== planId));
    };

    const handleEditGroupName = (groupName) => {
        setEditingGroupName(groupName);
        setNewGroupName(groupName);
    };


    const handleSaveGroupName = (oldGroupName) => {
        const updatedGroups = groupedPlans.map(group => {
            if (group.groupname === oldGroupName) {
                return {
                    ...group,
                    groupname: newGroupName
                };
            }
            return group;
        });
        setGroupedPlans(updatedGroups);
        setEditingGroupName(null);
    };

    // console.log(groupedPlans, '777777777');




    const handleUpdateGrouping = async () => {

        const update = {
            bid: JSON.parse(localStorage.getItem("loggedUserInfo")).gymid,
            groupdata: groupedPlans,
            // reporttype: "planSummary"
        }
        // console.log(update);

        const res = await updateGroupData(JSON.parse(localStorage.getItem("loggedUserInfo")).gymid, update)
        // console.log(res);
        toggleOpenPlan()
    }

    const handleCancel = () => {
        setGroupedPlans(groupedPlansNew)
        setSelectedPlans([])
        toggleOpenPlan()
    }

    const handleDeleteGroup = async () => {
        const res = await deleteGroupData(JSON.parse(localStorage.getItem("loggedUserInfo")).gymid, "planSummary")
        // console.log(res);

    }


    useEffect(() => {
        const targetNode = document.querySelector('.datatable-header');

        if (targetNode) {
            const observer = new MutationObserver(() => {
                const thElements = targetNode.querySelectorAll('th');

                thElements.forEach((th) => {
                    if (th.textContent.trim().includes("Date")) {
                        const icon = th.querySelector('i');
                        if (icon) {
                            icon.style.display = "none";
                            th.style.pointerEvents = "none";
                            th.style.cursor = "default";
                        }
                    }
                });
            });

            observer.observe(targetNode, {
                childList: true,
                subtree: true,
            });

            return () => observer.disconnect();
        }
    }, []);

    return (
        <>
            {apiError ? (
                <PageError handleReload={handleReload} />
            ) : (<div>

                <div >
                    <div className="d-flex align-items-center TrainerSummeryCustomHead">
                        <div className="d-flex">

                            <div class="filters_Count bg-success bg-opacity-10"><p class="text-center mb-1 fw-600">Highest Preferred Plan</p> {card.max ? card.max.label : "--"}: <b>{card.max ? `${!card.rupee ? card.max.value : card.max.value ? ('₹' + card.max.value.toLocaleString('en-IN')) : 0}` : 0} </b></div>
                            <div class="filters_Count bg-danger bg-opacity-10"><p class="text-center mb-1 fw-600">Lowest Preferred Plan</p>{card.min ? card.min.label : "--"}: <b> {card.min ? `${!card.rupee ? card.min.value : card.max.value ? ('₹' + card.min.value.toLocaleString('en-IN')) : 0}` : 0}</b></div>

                        </div>
                    </div>
                    <div className="trainerSummeryFilters d-flex align-items-center mb-3 mt-4">

                        <div className="mob-w-100 plandiv me-2">
                            <label className="me-2 fw-bold"> Plan breakdown: </label>
                            <select onChange={(e) => setSelectedPlanType(e.target.value)} className="form-select" value={selectedPlanType}>
                                <option value="Normal">Normal Plans</option>
                                <option value="Group">Group Plans</option>
                            </select>
                        </div>


                        <div className="mob-w-100 plandiv">
                            {/* <div className="gym-section w-100-mobile">
                                <label className="me-2 fw-bold"> Plan: </label>
                                <MDBSelect
                                    className="ellipseOptions"
                                    multiple
                                    placeholder="All"
                                    data={selectData}
                                    value={selectValue}
                                    onChange={(e) => {
                                        const selectedValues = e.map(item => item.value);
                                        setSelectValue(selectedValues);
                                    }}
                                />
                            </div> */}

                            <label className="me-2 fw-bold"> Plan: </label>
                            <MDBSelect
                                className="ellipseOptions"
                                multiple
                                placeholder="All"
                                data={selectData}
                                value={selectValue}
                                onChange={(e) => {
                                    const selectedValues = e.map(item => item.value);
                                    setSelectValue(selectedValues);
                                }}
                            />

                        </div>


                        <MDBBtn className="ms-2 mt-4 groupbtn" onClick={toggleOpenPlan}>Group</MDBBtn>
                        <div className="d-flex align-items-center ms-0 mob-w-100 ">
                            <div className="gym-section w-100-mobile ms-2">
                                <label className="me-2 fw-bold"> Measure: </label>
                                <div className="mob-w-100 ">
                                    <select
                                        name="shrs"
                                        id="shr"
                                        className="form-select placeholder-active active mob-w-100"
                                        onChange={(e) => setDataFormat(e.target.value)}
                                        value={dataFormat}
                                    >
                                        <option value="count">Member(s) Count</option>
                                        <option value="amount">Revenue</option>
                                    </select>
                                </div>
                            </div>
                            <div className="gym-section w-100-mobile ms-2">
                                <label className="me-2 fw-bold"> Breakdown: </label>
                                <div className="mob-w-100 ">
                                    <select
                                        name="shrs"
                                        id="shr"
                                        className="form-select placeholder-active active mob-w-100"
                                        onChange={(e) => setDayFilter1(e.target.value)}
                                        value={DayFilter1}
                                    >
                                        <option value="Date">Date</option>
                                        <option value="Week">Weekly</option>
                                        <option value="Month">Monthly</option>
                                        <option value="Year">Yearly</option>

                                    </select>
                                </div>
                            </div>
                            <div className="gym-section w-100-mobile ms-2">
                                <label className="me-2 fw-bold"> Days: </label>
                                <div className="mob-w-100 ">
                                    <select
                                        name="shrs"
                                        id="shr"
                                        className="form-select placeholder-active active mob-w-100"
                                        onChange={(e) => setDayFilter(e.target.value)}
                                        value={DayFilter}
                                    >
                                        <option value="7">All</option>
                                        <option value="1">Today</option>
                                        <option value="2">Yesterday</option>
                                        <option value="3">This Week</option>
                                        <option value="4">This Month</option>
                                        <option value="5">This Year</option>
                                        {/* <option value="Week">Weekly</option> */}
                                        {/* <option value="Month">Monthly</option>
                                        <option value="Year">Yearly</option> */}
                                        <option value="6">Custom</option>

                                    </select>
                                </div>
                            </div>
                        </div>
                        {customdate &&
                            <div className="d-block align-items-center ms-2 mob-w-100">
                                <label className="me-2 fw-bold"> Select Custom Date: </label>
                                <div className="gym-xlarge  gym-larg-pos mob-w-100">
                                    <DatePicker
                                        dateFormat={"d/M/yyyy"}
                                        selectsRange={true}
                                        startDate={startDate}
                                        endDate={endDate}
                                        onChange={(update) => {
                                            getDates(update);
                                        }}
                                        isClearable={false}
                                        customInput={<CalenderInput />}
                                    />
                                </div>
                            </div>}

                        <div className="mob-div-center mt-3 pt-1 filterApplyMobile">
                            <MDBBtn className="ms-2" onClick={(e) => handleApplyAndToggle()}>Apply</MDBBtn>
                            <MDBBtn className="ms-2" color="secondary" onClick={(e) => handleResetAndToggle()}>Clear</MDBBtn>
                            <MDBBtn className="ms-2" onClick={() => setGraphCheck(!graphCheck)}>{graphCheck ? <span><MDBIcon fas icon="table" className="me-1" />Table </span> : <span ><MDBIcon fas icon="chart-bar" className="me-1" />Graph</span>}</MDBBtn>
                            {!graphCheck && <MDBBtn className="ms-2" onClick={() => downloaddata(basicData, "planSummary")}>Export</MDBBtn>}

                        </div>
                    </div>
                    {!graphCheck && <div className={dataFormat === "count" ? "trainerSummeryDataTableNew plan mt-4" : "trainerSummeryDataTable plan mt-4"}>
                        {loading ? (<div className="loader-height"> <Loader /></div>) : <><MDBDatatable className="sortOff trainerSummerySort" entries={25} paging={true} data={basicData} /></>}


                    </div>}

                    {graphCheck && <div className="w-100 border p-4 mb-4 shadow-4 mob-w-100 mob-p-0">
                        {loading ? (<div className="loader-height"> <Loader /></div>) : <>
                            <ReportsGraph data={graphData} info={graphinfo} /></>}
                    </div>}

                </div>


                <MDBModal open={basicModal} setOpen={setBasicModal} tabIndex='-1'>
                    <MDBModalDialog size="lg">
                        <MDBModalContent>
                            <MDBModalHeader>
                                <MDBModalTitle><div className="d-flex"><div>{dateArray.plan !== "Total" ? `Plan Name: ${dateArray.plan}` : "Total"}  </div></div></MDBModalTitle>
                                <MDBBtn className='btn-close' color='none' onClick={() => { handleClickOutside() }}></MDBBtn>
                            </MDBModalHeader>
                            <MDBModalBody>
                                {basicModal && <div className="trainerMemberSummeryDataTable">
                                    <MDBDatatable entries={25} data={basicData2} />

                                </div>}
                            </MDBModalBody>
                            <MDBModalFooter className="pe-4 pb-3">
                                <MDBBtn className="ms-2" onClick={() => downloaddata(basicData2, dateArray.plan === "Total" ? "Total" : dateArray.plan)}>download</MDBBtn>
                            </MDBModalFooter>
                        </MDBModalContent>
                    </MDBModalDialog>
                </MDBModal>
                {/* <input
                                                            type="text"
                                                            value={group.groupName}
                                                            onChange={(e) => handleGroupNameChange(e, index)}
                                                            className="form-control mb-2"
                                                        /> */}

                <MDBModal tabIndex='-1' open={centredModal} onClose={() => setCentredModal(false)}>
                    <MDBModalDialog centered size="lg">
                        <MDBModalContent>
                            <MDBModalHeader>
                                <MDBModalTitle>Grouping</MDBModalTitle>
                                <MDBBtn className='btn-close' color='none' onClick={toggleOpenPlan}></MDBBtn>
                            </MDBModalHeader>
                            <MDBModalBody>
                                <MDBRow>
                                    {/* Display Plans */}
                                    <MDBCol size={5}>
                                        <h5>Groups</h5>
                                        <MDBCard>
                                            <MDBCardBody className="GroupingCardHeight">
                                                {/* Display Groups */}
                                                {groupedPlans.length > 0 && groupedPlans.map((group, index) => (
                                                    <div key={`group-${index}`}>
                                                        <div
                                                            className={`my-1 p-2 rounded-3 ${selectedPlans.includes(group.groupname) ? 'bg-success bg-opacity-25' : ''}`}
                                                        // className="my-1 p-2 rounded-3"
                                                        >
                                                            {editingGroupName === group.groupname ? (
                                                                <div className="d-flex align-items-center">
                                                                    <MDBInput
                                                                        value={newGroupName}
                                                                        onChange={(e) => setNewGroupName(e.target.value)}
                                                                    />
                                                                    <MDBBtn size="sm" color="success" className="ms-2" onClick={() => handleSaveGroupName(group.groupname)}>
                                                                        <MDBIcon far icon="save" />
                                                                    </MDBBtn>
                                                                </div>
                                                            ) : (
                                                                <div

                                                                    className="d-flex justify-content-between">
                                                                    <MDBCheckbox
                                                                        name='flexCheck'
                                                                        value={group.groupname}
                                                                        id={`group-${group.groupname}`}
                                                                        label={group.groupname}
                                                                        checked={selectedPlans.includes(group.groupname)}
                                                                        onChange={() => handleCheckboxChange(group, 'group')}
                                                                    />
                                                                    <div size="sm" color="info" onClick={() => handleEditGroupName(group.groupname)}>
                                                                        <MDBIcon far icon="edit" />
                                                                    </div>
                                                                </div>
                                                            )}
                                                        </div>
                                                        <hr class="dropdown-divider" />
                                                    </div>
                                                ))}

                                                {/* Display Plans that are not in any group */}
                                                {plans && plans.length > 0 && plans.map((plan) => {
                                                    if (!groupedPlans.some(group => group.planids.includes(plan._id))) {
                                                        return (
                                                            <>
                                                                {/* bg-success bg-opacity-25 */}
                                                                <div
                                                                    className={`my-1 p-2 rounded-3 ${selectedPlans.includes(plan._id) ? 'bg-success bg-opacity-25' : ''}`}
                                                                    key={plan._id} >
                                                                    <MDBCheckbox
                                                                        name='flexCheck'
                                                                        value={plan._id}
                                                                        id={`plan-${plan._id}`}
                                                                        label={plan.name}
                                                                        checked={selectedPlans.includes(plan._id)}
                                                                        onChange={() => handleCheckboxChange(plan._id, 'plan')}
                                                                    />
                                                                </div>
                                                                <hr class="dropdown-divider" />
                                                            </>
                                                        );
                                                    }
                                                    return null;
                                                })}
                                            </MDBCardBody>
                                        </MDBCard>
                                    </MDBCol>

                                    {/* Group Plans */}
                                    <MDBCol>
                                        <div className="d-flex align-items-center justify-content-center h-100">
                                            <MDBBtn disabled={selectedPlans.length > 1 ? false : true} onClick={groupSelectedPlans}>Group</MDBBtn>
                                        </div>
                                    </MDBCol>

                                    {/* Display Selected Plans */}
                                    <MDBCol size={5}>
                                        <h5>Categories</h5>
                                        <MDBCard>
                                            <MDBCardBody className="p-0 GroupingCardHeight">
                                                {selectedPlans.length > 0 &&
                                                    plans.filter(plan => {
                                                        const isInGroup = groupedPlans.some(group => selectedPlans.includes(group.groupname) && group.planids.includes(plan._id));
                                                        return selectedPlans.includes(plan._id) || isInGroup;
                                                    }).map((item, index, filteredPlans) => (
                                                        <div key={item._id} className={`py-2 px-3 border d-flex justify-content-between align-items-center ${index !== filteredPlans.length - 1 ? 'border-bottom-0' : ''
                                                            }`}>
                                                            {item.name}
                                                            {/* <MDBBtn color='danger' size='sm' onClick={() => handleRemovePlan(item._id)}> */}
                                                            {/* <MDBIcon fas icon='trash' /> */}
                                                            <i className="fw-bold fs-5 fa fa-close ms-2 text-danger" onClick={() => handleRemovePlan(item._id)}></i>
                                                            {/* </MDBBtn> */}
                                                        </div>
                                                    ))}
                                            </MDBCardBody>
                                        </MDBCard>
                                    </MDBCol>
                                </MDBRow>

                                <MDBModal open={optSmModal} tabIndex='-1' onClose={handleCancel}>
                                    <MDBModalDialog size='sm'>
                                        <MDBModalContent>
                                            <MDBModalBody>
                                                <h6>Group Name</h6>
                                                <MDBInput
                                                    label='Enter Group Name'
                                                    value={newGroupName}
                                                    onChange={(e) => setNewGroupName(e.target.value)}
                                                />
                                            </MDBModalBody>
                                            <MDBModalFooter>
                                                <MDBBtn color='secondary' onClick={toggleOpenNew}>
                                                    Close
                                                </MDBBtn>
                                                <MDBBtn onClick={handleSaveGroup}>
                                                    Save
                                                </MDBBtn>
                                            </MDBModalFooter>
                                        </MDBModalContent>
                                    </MDBModalDialog>
                                </MDBModal>


                            </MDBModalBody>
                            <MDBModalFooter>
                                <MDBBtn color='secondary' onClick={handleCancel}>Cancel</MDBBtn>
                                <MDBBtn onClick={handleUpdateGrouping}>Save</MDBBtn>
                            </MDBModalFooter>
                        </MDBModalContent>
                    </MDBModalDialog>
                </MDBModal>
            </div>
            )} </>
    )
}


export default PlanSummary