import React from 'react';
import BusinessPage from "./views/BusinessPage";
import Check from "./views/Check"; 
import MManger from "./views/MManger";
import PlansAndAddons from "./views/PlansAndAddons";
import Staff from "./views/Staff";  
import Transactions from "./views/Transactions";
import ReactGA from 'react-ga';
import { BrowserRouter, Route, Routes } from "react-router-dom";
import "../src/motos_lp/assets/css/materialdesignicons.min.css";
import "../src/motos_lp/assets/scss/themes.scss";
import ErrorBoundary from "./ErrorBoundary";
import Reports from "./components/Reports";
import "./components/css/Responsive.css";
import "./components/css/font.css";
import "./components/css/home.css";
import "./components/css/mdb.min.css";
import MainDashBoard from "./components/dashboard/Main";
import Privacy from "./components/privacy";
import Terms from "./components/terms";
import VEmails from "./components/verifiedemail";
import Pricing from "./motos_lp/component/Pricing";
import ResetPassword from './motos_lp/pages/auth/reset-password';
import Comingsoon from './motos_lp/pages/comingsoon';
import Error from './motos_lp/pages/error';
import Maintenance from './motos_lp/pages/maintenance';
import Saas from './motos_lp/pages/saas';
import FileNotFound from "./views/404";
import ActiveMembers from "./views/ActiveMembers";
import AttendanceList from "./views/AttendanceList";
import BusinessInfo from "./views/BusinessInfo";
import ComingSoon from "./views/ComingSoon";
import CreateAddon from "./views/CreateAddon";
import CreateSlot from "./views/CreateSlot";
// import CreateSlotView from "./views/CreateSlotView";
import Dashboard from "./views/Dashboard";
import DigitalWaiver from "./views/DigitalWaiver";
import EmailTemplateView from "./views/EmailTemplateView";
import HomePage from "./views/HomePage";
import Login from "./views/Login";
import LoginSignup from "./views/LoginSignup";
import MDetails from "./views/MDetails";
import OTPValidation from "./views/OTP_Validation";
import Payments from "./views/Payments";
import ReceiptPrint from "./views/ReicptPrint";
import SMSRemainder from "./views/SMSRemainder";
import Settings from "./views/Settings";
import Checkinreport from "./views/checkinreport";
import CreatePlan from "./views/createPlan";
import Eventtracker from "./views/eventstracker";
import UploadLogo from "./views/uploadLogo";
import EmailTempletes from "./views/EmailTempletes";
import PageError from "./views/PageError";
import MessageSettings from "./views/MessageSettings";
// import DefaultTempletes from "./views/DefaultTempleteslazzy";
import EditMessage from "./views/EditMessage";
import PaymentLinkPage from "./components/CompressPaymentURL";
import RedirectScreen from "./views/RedirectScreen";
import BussinessReport from "./views/accountsReports";
import AccountsEventsReports from "./views/accountsEventsReports";
import BillingSubscriptionPlan from "./views/BillingSubscriptionPlan";
import Addons from "./components/Addons";
import ResetPasswords from "./views/ResetPasswords";
import ConfirmPassword from "./views/ConfirmPassword"
// import Segments from './views/Segments';
import Feature from './motos_lp/component/Feature';
import Contact from './motos_lp/component/Contact';
import Leads from './motos_lp/pages/leads';
import Offers from './motos_lp/pages/offers';
// import ClassDashboard from "./Classes/ClassDashboard";
// import Classes from "./Classes/Classes";
// import ClassSchedules from "./Classes/ClassSchedules";
// import ClassesBooking from "./Classes/ClassesBooking";
// import ClassUsers from "./Classes/ClassUsers";
// import CancelationPolicy from "./Classes/CancelationPolicy";
// import SchudeleCalender from './Classes/SchudeleCalender';
// import QRCheckin from './views/QRCheckin';
// import Segmentation from './views/Segmentation';
// import SegmentationSettings from './views/SegmentationSettings';
// import ScheduleInvoice from './views/ScheduleInvoice';
import QRCheckin from './views/QRCheckin';
import QRScanner from './QRScanner';
import QRLogin from './views/QRLogin';

// import LandingpageTemplateView from "./views/LandingpageTemplateView";

function setToken(userToken) {
  try {
    localStorage.setItem("token", userToken);
  } catch (e) {
    console.log("Error while creating session.. " + e);
  }
}

function getToken() {
  try {
    const tokenString = localStorage.getItem("token");
    console.log(tokenString);
    const userToken = JSON.parse(tokenString);
    return userToken;
  } catch (e) {
    console.log("error :::::: " + e);
    return;
  }
}
let plansInfo = localStorage.getItem("planInfo") ? JSON.parse(localStorage.getItem("planInfo")) : null;

function App() {
  const token = getToken();
  console.log("Token ... " + token);
  if (!token) {
    // return <Login setToken={setToken} />;
  }

  const frontEndEnvData = localStorage.getItem("frontEndEnvData");
  const googleAnalyticId = frontEndEnvData ? JSON.parse(frontEndEnvData)?.reactappgoogleanalyticsid || "G-HWWFT9073S" : "G-HWWFT9073S";


  ReactGA.initialize(googleAnalyticId);
  // ReactGA.initialize(process.env.REACT_APP_GOOGLE_ANALYTICS_ID);
  // ReactGA.initialize("G-0GS34MSL9C");

  return (
    <ErrorBoundary>
      <BrowserRouter>
        <Routes>
          <Route path="/*" element={<FileNotFound />} />
          {/* <Route path="/" index element={<Login setToken={setToken} />} /> */}
          <Route path="/login" index element={<Login setToken={setToken} />} />
          <Route path="/signup" index element={<LoginSignup setToken={setToken} />} />
          <Route path="/home" index element={<HomePage />} />
          <Route path="/transactions" element={<Transactions />} />
          <Route path="/payments" element={<Payments />} />
          <Route path="/printreceitpt" element={<ReceiptPrint />} />
          <Route path="/pricing" element={<Pricing />} />
          <Route path="/features" element={<Feature />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/businfo" element={<BusinessPage />} />
          <Route path="/addplans" element={<CreatePlan />} />
          <Route path="/addaddons" element={<CreateAddon />} />
          {/* <Route path="/addslotsview" element={<CreateSlotView />} /> */}
          <Route path="/addslots" element={<CreateSlot />} />
          <Route path="/bInfo" element={<BusinessInfo />} />
          <Route path="/mmanager" element={<MManger />} />
          <Route path="/mailremainder" element={<SMSRemainder />} />
          <Route path="/adminremainder" element={<ComingSoon />} />
          {(plansInfo === null || (plansInfo.planFeature && plansInfo.planFeature.membercheckin)) &&
            <Route path="/checkin" element={<Check />} />}
          <Route path="/staff" element={<Staff />} />
          <Route path="/plansandaddons" element={<PlansAndAddons />} />
          <Route path="/settings" element={<Settings />} />
          <Route path="/plans" element={<PlansAndAddons />} />
          {(plansInfo === null || (plansInfo.planFeature && plansInfo.planFeature.dashboard)) &&
            <Route path="/dashboard" element={<Dashboard />} />}          <Route path="/mdetails" element={<MDetails />} />
          <Route path="/attendance" element={<AttendanceList />} />
          <Route path="/settingview" element={<Settings viewmode="1" />} />
          <Route path="/activemembers" element={<ActiveMembers />} />
          <Route path="/verifyemail" element={<VEmails />} />
          <Route path="/logEvent" element={<Eventtracker />} />
          <Route path="/saas" element={<Saas />} />
          {/* <Route path="/landingpagetemplateview" element={<LandingpageTemplateView />} /> */}
          <Route path="/" element={<Saas />} />
          <Route path="/maintenance" element={<Maintenance />} />
          <Route path="/error" element={<Error />} />
          <Route path="/comingsoon" element={<Comingsoon />} />
          <Route path="/auth-reset-password" element={<ResetPassword />} />
          <Route path="/privacy" element={<Privacy />} />
          <Route path="/terms" element={<Terms />} />
          <Route path="/faqs" element={<MainDashBoard />} />

          <Route path="/OtpValidation" element={<OTPValidation />} />
          <Route path="/checkreport" element={<Checkinreport />} />
          <Route path="/waiver" element={<DigitalWaiver />} />
          <Route path="/report" element={<Reports />} />
          <Route path="/report/ga_accounts" element={<BussinessReport />} />
          <Route path="/report/ga_accountssessons" element={<AccountsEventsReports />} />


          <Route path="/upoadlogo" element={<UploadLogo />} />
          <Route path="/emailTemple" element={<EmailTempletes />} />
          <Route path="/emailTemplateView" element={<EmailTemplateView />} />
          <Route path="/pageerror" element={<PageError />} />
          <Route path="/messageSettings" element={<MessageSettings />} />
          <Route path="/smsremainder" element={<SMSRemainder />} />
          <Route path="/editmessage" element={<EditMessage />} />
          <Route path="/p" element={<PaymentLinkPage />} />
          <Route path="/paymentredirect" element={<RedirectScreen />} />
          <Route path="/getresetPassword" element={<ResetPasswords />} />
          <Route path="/login/restpassword/:id" element={<ConfirmPassword />} />
          <Route path="/billingsubscriptionplan" element={<BillingSubscriptionPlan />} />
          {/* <Route path="/segments" element={<Segments />} /> */}
          <Route path="/landingpage" element={<Leads />} />
          <Route path="/offerspage" element={<Offers />} />
          <Route path="/privacy-policy" element={<Privacy />} />
          <Route path="/terms-and-conditions" element={<Terms />} />

          {/* <Route path="/classdashboard" element={<ClassDashboard />} />
          <Route path="/classes" element={<Classes />} />
          <Route path="/classschedules" element={<ClassSchedules />} />
          <Route path="/classesbooking" element={<ClassesBooking />} />
          <Route path="/classusers" element={<ClassUsers />} />
          <Route path="/cancelationpolicy" element={<CancelationPolicy />} />
          <Route path="/schudelecalender" element={<SchudeleCalender />} />
          
          <Route path="/segmentation" element={<Segmentation />} />
          <Route path="/segmentationsettings" element={<SegmentationSettings />} />
          <Route path="/scheduleinvoice" element={<ScheduleInvoice />} /> */}
          <Route path="/qrcheckin" element={<QRCheckin />} />
          <Route path="/qrscanner" element={<QRScanner />} />
          <Route path="/qrlogin" element={<QRLogin />} />






        </Routes>
      </BrowserRouter>
    </ErrorBoundary>
  );
}

export default App;