import React, { useEffect, useState, forwardRef } from "react";
import { deleteGroupData, getGroupData, updateGroupData, getActiveMembersCount, getPlans, getActiveMembersCountByRow } from '../services/apiServices';
import { MDBBtn, MDBCard, MDBCardBody, MDBCardText, MDBCardTitle, MDBCheckbox, MDBCol, MDBDatatable, MDBIcon, MDBInput, MDBModal, MDBModalBody, MDBModalContent, MDBModalDialog, MDBModalFooter, MDBModalHeader, MDBModalTitle, MDBRow, MDBSelect } from 'mdb-react-ui-kit';
import PageError from "../views/PageError";
import calImg from "./../components/images/calendar.svg";
import DatePicker from 'react-datepicker';
import ExcelExportButton from "./exportToExcel";
import ReportsGraph from "./ReportsGraph";
import Loader from "../components/Loader";
import moment from 'moment-timezone';

function ActiveMemberHistoryTable() {
    const today = new Date();
    const currentTime = new Date();
    const startOfToday = new Date(currentTime);
    // startOfToday.setHours(0, 0, 0, 0);

    const endOfToday = new Date(currentTime);
    // endOfToday.setHours(23, 59, 59, 999);

    const yesterday = new Date(currentTime);
    yesterday.setDate(currentTime.getDate() - 1);
    const startOfYesterday = new Date(yesterday);
    // startOfYesterday.setHours(0, 0, 0, 0);

    const endOfYesterday = new Date(yesterday);
    // endOfYesterday.setHours(23, 59, 59, 999);

    const startOfWeek = new Date(currentTime);
    startOfWeek.setDate(currentTime.getDate() - currentTime.getDay());
    // startOfWeek.setHours(12, 0, 0, 0);
    const endOfWeek = new Date(currentTime);
    endOfWeek.setDate(startOfWeek.getDate() + 6);
    // endOfWeek.setHours(12, 0, 0, 0);

    const startOfMonth = new Date(currentTime);
    startOfMonth.setDate(1);
    // startOfMonth.setHours(0, 0, 0, 0);

    const endOfMonth = new Date(currentTime);

    const startOfYear = new Date(currentTime);
    startOfYear.setMonth(0, 1);
    // startOfYear.setHours(0, 0, 0, 0);

    const endOfYear = new Date(currentTime);
    endOfYear.setMonth(11, 31);
    // endOfYear.setHours(23, 59, 59, 999);
    // today.setHours(23, 59, 0, 0);

    const [apiError, setApiError] = useState(false);
    const [selectData, setSelectData] = useState([
        { text: 'Select', value: "Select" },
    ]);
    const [graphCheck, setGraphCheck] = useState(false)
    const [loading, setLoading] = useState(false)
    const [applyFilterClicked, setApplyFilterClicked] = useState(false)
    const [DayFilter1, setDayFilter1] = useState("Date");
    const [dataFormat, setDataFormat] = useState("count");
    const [resetFilterClicked, setResetFilterClicked] = useState(false)
    const [basicModal, setBasicModal] = useState(false);
    const [basicData2, setBasicData2] = useState({
        columns: ["S.no", 'Member Id', 'Member Name', "Plan Name", 'Start Date', "Expirydate"],
        rows: [],
    })
    const [PlanName, setPlanName] = useState("Total");
    const [DayFilter, setDayFilter] = useState("4");
    const [customdate, setcustomdate] = useState(DayFilter === "6");
    const [dateRange, setDateRange] = useState([startOfMonth, endOfMonth]);
    const [startDate, endDate] = dateRange;
    const [plans, setPlans] = useState([]);
    const [selectedPlans, setSelectedPlans] = useState([]);
    const [selectValue, setSelectValue] = useState([]);
    const [groupedPlans, setGroupedPlans] = useState([]);
    const [groupedPlansNew, setGroupedPlansNew] = useState([]);
    const [editingGroupName, setEditingGroupName] = useState(null);
    const [newGroupName, setNewGroupName] = useState("");

    const [basicData, setBasicData] = useState({
        columns: [],
        rows: [],
    });
    const [centredModal, setCentredModal] = useState(false);
    const toggleOpenPlan = () => setCentredModal(!centredModal);
    const [groupInfo, setGropInfo] = useState({})
    const [graphData, setGraphData] = useState({})
    const [graphinfo, setGraphinfo] = useState({})
    const [innerLoading, setInnerLoading] = useState(true);
    const [selectedPlanType, setSelectedPlanType] = useState("Normal");



    useEffect(() => {
        if (basicData === false) {
            setInnerLoading(true);
        }
    }, [basicData]);


    // useEffect(() => {
    //     const planSet = new Set();
    //     if (groupedPlans.length > 0) {
    //         const groupPlanIds = groupedPlans.map(group => group.planids).flat();

    //         groupedPlans.forEach(item => {
    //             planSet.add({ planName: item.groupname, id: `g:${item.groupname}` })
    //         })
    //         plans.forEach(plan => {
    //             if (plan._id && plan._id.length > 0 && !groupPlanIds.includes(plan._id)) {
    //                 planSet.add({ planName: plan.name, id: plan._id });
    //             }
    //         });

    //     } else {
    //         plans.forEach(plan => {
    //             if (plan._id && plan._id.length > 0) {
    //                 planSet.add({ planName: plan.name, id: plan._id });
    //             }
    //         });
    //     }
    //     const uniquePlans = Array.from(planSet);

    //     const formattedTrainers = uniquePlans.map(plan => ({
    //         text: plan.planName,
    //         value: plan.id
    //     }));

    //     setSelectData(formattedTrainers);
    // }, [plans, resetFilterClicked, groupedPlans]);


    useEffect(() => {
        const planSet = new Set();

        if (selectedPlanType === "Group") {
            groupedPlans.forEach(item => {
                planSet.add({ planName: item.groupname, id: `g:${item.groupname}` });
            });
        } else {
            const groupPlanIds = groupedPlans?.map(group => group.planids).flat();
            plans.forEach(plan => {
                if (plan._id) {
                    planSet.add({ planName: plan.name, id: plan._id });
                }
            });
        }

        const uniquePlans = Array.from(planSet);
        const formattedPlans = uniquePlans.map(plan => ({
            text: plan.planName,
            value: plan.id
        }));
        console.log("formattedPlansformattedPlans", formattedPlans)

        setSelectData(formattedPlans);
    }, [selectedPlanType, plans, groupedPlans]);


    const getDates = (obj) => {
        setDateRange(obj);
    };
    useEffect(() => {
        setcustomdate(DayFilter === "6" ? true : false);
    }, [DayFilter]);
    const CalenderInput = forwardRef(({ value, onClick }, ref) => (
        <>
            <span className="gym-input forminput h-40">
                {value === "" ? "Custom Range" : value}
                &nbsp;
                <img
                    src={calImg}
                    onClick={onClick}
                    ref={ref}
                    alt="custom Range"
                    title="Select Custom Range"
                />
            </span>
        </>
    ));

    useEffect(() => {
        fetchGData();
    }, [])
    async function fetchGData() {
        // console.log('hhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhh');

        const response = await getGroupData(
            (localStorage.getItem("loggedUserInfo") && JSON.parse(localStorage.getItem("loggedUserInfo")).gymid), "activeMemberHistory");
        // console.log(response);

        if (response === "false") {
            setApiError(true)
        } else {
            const groupdata = await response.json();
            console.log(groupdata, '9iehhgjgkfj');
            setGropInfo(groupdata)
            setGroupedPlans(groupdata.groupdata)
            setGroupedPlansNew(groupdata.groupdata)
        }
    }

    async function fetchData() {
        try {
            // Fetch active member data

            // const sDate = DayFilter === "1" ? startOfToday : (DayFilter === "2" ? startOfYesterday : (DayFilter === "3" ? startOfWeek : (DayFilter === "4" ? startOfMonth : (DayFilter === "5" ? startOfYear : (DayFilter === "6" ? startDate : null)))))
            // const eDate = DayFilter === "1" ? endOfToday : (DayFilter === "2" ? endOfYesterday : (DayFilter === "3" ? endOfWeek : (DayFilter === "4" ? endOfMonth : (DayFilter === "5" ? endOfYear : (DayFilter === "6" ? endDate : null)))))
            let updates = {
                startDate: new Date(moment(startDate).startOf('day')),
                endDate: new Date(moment(endDate).endOf('day')),
            };
            if (DayFilter === "7") {
                updates = {
                    startDate: null,
                    endDate: null,
                };
            }

            // console.log(updates)

            const activeMembersResp = await getActiveMembersCount(
                JSON.parse(localStorage.getItem("loggedUserInfo")).gymid, updates
            );

            if (!activeMembersResp || activeMembersResp === "false") {
                setApiError(true);
                return;
            }

            const activeMembers = await activeMembersResp.json();
            // Fetch plan data
            const plansResp = await getPlans(
                JSON.parse(localStorage.getItem("loggedUserInfo")).gymid
            );

            if (!plansResp || plansResp === "false") {
                setApiError(true);
                return;
            }

            const plans = await plansResp.json();
            // Filter plans with `ptype === 2`
            let filteredPlans = plans.filter(plan => plan.ptype === 2);
            setPlans(filteredPlans);
            generateDataTable(activeMembers, filteredPlans)
            setApplyFilterClicked(false)
            setResetFilterClicked(false)
        } catch (error) {
            console.error("Error fetching data:", error);
            setApiError(true);
        }
    }

    const getStartAndEndDate = (filter, date) => {
        let startDate, endDate;

        switch (filter) {
            case "Date": // Single day
                startDate = new Date(date);
                endDate = new Date(date);
                break;

            case "Week": // Weekly range
                startDate = new Date(date);
                startDate.setDate(date.getDate() - date.getDay()); // Start of the week (Sunday)
                endDate = new Date(startDate);
                endDate.setDate(startDate.getDate() + 6); // End of the week (Saturday)
                break;

            case "Month": // Monthly range
                startDate = new Date(date.getFullYear(), date.getMonth(), 1); // First day of the month
                endDate = new Date(date.getFullYear(), date.getMonth() + 1, 0); // Last day of the month
                break;

            case "Year": // Yearly range
                startDate = new Date(date.getFullYear(), 0, 1); // January 1st
                endDate = new Date(date.getFullYear(), 11, 31); // December 31st
                break;

            default:
                throw new Error(`Unsupported filter type: ${filter}`);
        }

        // Ensure the time components are set to the start and end of the day
        // startDate.setHours(0, 0, 0, 0); // Start of the day
        // endDate.setHours(23, 59, 59, 999); // End of the day

        startDate = new Date(moment(startDate).startOf('day'));
        endDate = new Date(moment(endDate).endOf('day'));

        return { startDate, endDate };
    };


    const generateDataTable = (activeMembers, filteredPlans) => {
        console.log("generateDataTable22222222", activeMembers, filteredPlans);
        let tableData = {
            columns: [],
            rows: [],
        };


        const columns = [
            { label: DayFilter1, field: "date", sort: "asc" },
            ...(selectValue.length > 0
                ? selectValue
                    .filter(selected =>
                        (selectedPlanType === "Group" && selected.startsWith("g:")) || // Show only groups if Group is selected
                        (selectedPlanType !== "Group" && !selected.startsWith("g:"))  // Show only plans if Group is not selected
                    )
                    .map(selected => {
                        if (selected.startsWith("g:")) {
                            // Group column
                            const groupId = selected.split("g:")[1];
                            return {
                                label: groupId, // Group name
                                field: groupId,
                                sort: "asc",
                            };
                        } else {
                            // Individual plan column
                            const plan = filteredPlans.find(plan => plan._id.toString() === selected);
                            return {
                                label: plan?.name || "Unknown Plan",
                                field: plan?._id.toString(),
                                sort: "asc",
                            };
                        }
                    })
                : selectedPlanType === "Group"
                    ? groupedPlans.map(group => ({
                        label: group.groupname,
                        field: group.groupname,
                        sort: "asc",
                    }))
                    : filteredPlans.map(plan => ({
                        label: plan.name,
                        field: plan._id.toString(),
                        sort: "asc",
                    }))
            ),
            { label: "Total", field: "total", sort: "asc" }, // Total column
        ];

        // Build rows
        const uniquePeriods = [
            ...new Map(
                activeMembers.map(item => {
                    const date = new Date(item.date);
                    const periodDisplay = computePeriod(DayFilter1, date);

                    return [periodDisplay, { date, display: periodDisplay }];
                })
            ).values(),
        ];

        const obj = {
            startdate: new Date(moment(startDate).startOf("day")),
            enddate: new Date(moment(endDate).endOf("day")),
        };

        const rows = uniquePeriods.map(period => {
            const row = { date: period.display };
            let totalMembers = new Set();

            (selectValue.length > 0
                ? selectValue.filter(selected =>
                    (selectedPlanType === "Group" && selected.startsWith("g:")) ||
                    (selectedPlanType !== "Group" && !selected.startsWith("g:"))
                )
                : selectedPlanType === "Group"
                    ? groupedPlans.map(g => `g:${g.groupname}`)
                    : filteredPlans.map(p => p._id.toString())
            ).forEach(selected => {
                if (selected.startsWith("g:")) {
                    // Group logic
                    const groupId = selected.split("g:")[1];
                    const group = groupedPlans.find(g => g.groupname === groupId);
                    if (group) {
                        let groupMembers = new Set();
                        let filterActiveMembers = activeMembers.filter(obj => obj.count > 0 && group.planids.includes(obj.planid.toString()));
                        const { startDate, endDate } = getStartAndEndDate(DayFilter1, new Date(period.date));

                        filterActiveMembers.forEach(obj => {
                            const objDate = new Date(obj.date);
                            if (objDate >= startDate && objDate <= endDate) {
                                obj.memberids.forEach(id => {
                                    totalMembers.add(id);
                                    groupMembers.add(id);
                                });
                            }
                        });

                        const groupMembersArray = Array.from(groupMembers);
                        row[groupId] = (
                            <span
                                className="button-link link-color"
                                onClick={e => handleRowClick(e, group.planids, DayFilter1, DayFilter, period.date, groupMembersArray, filteredPlans, obj, `Group Name: ${groupId}`)}
                            >
                                {groupMembers.size}
                            </span>
                        );
                    }
                } else {
                    // Individual plan logic
                    const plan = filteredPlans.find(plan => plan._id.toString() == selected);
                    if (plan) {
                        let groupMembers = new Set();
                        let filterActiveMembers = activeMembers.filter(obj => obj.count > 0 && obj.planid.toString() == plan._id.toString());
                        const { startDate, endDate } = getStartAndEndDate(DayFilter1, new Date(period.date));

                        filterActiveMembers.forEach(obj => {
                            const objDate = new Date(obj.date);
                            if (objDate >= startDate && objDate <= endDate) {
                                obj.memberids.forEach(id => {
                                    totalMembers.add(id);
                                    groupMembers.add(id);
                                });
                            }
                        });

                        const groupMembersArray = Array.from(groupMembers);
                        row[plan._id.toString()] = (
                            <span
                                className="button-link link-color"
                                onClick={e => handleRowClick(e, [plan._id], DayFilter1, DayFilter, period.date, groupMembersArray, filteredPlans, obj, `Plan Name: ${plan?.name}`)}
                            >
                                {groupMembers.size}
                            </span>
                        );
                    }
                }
            });

            const totalMembersArray = Array.from(totalMembers);
            row.total = (
                <span
                    className="button-link link-color"
                    onClick={e => handleRowClick(e, filteredPlans.map(obj => obj._id), DayFilter1, DayFilter, period.date, totalMembersArray, filteredPlans, obj, "Total")}
                >
                    {totalMembers.size}
                </span>
            );
            return row;
        });

        tableData.columns = columns;
        tableData.rows = rows;
        setBasicData(tableData);
        setLoading(false);
    };






    // const generateDataTable = (activeMembers, filteredPlans) => {
    //     console.log("generateDataTable22222222", activeMembers, filteredPlans)
    //     let tableData = {
    //         columns: [],
    //         rows: [],
    //     };

    //     // Map of grouped plans to their group names
    //     const groupedPlanMap = {};
    //     groupedPlans.forEach(group => {
    //         group.planids.forEach(planId => {
    //             groupedPlanMap[planId] = group.groupname;
    //         });
    //     });
    //     // Build columns
    //     const columns = [
    //         { label: DayFilter1, field: "date", sort: "asc" },
    //         ...(selectValue.length > 0
    //             ? selectValue.map(selected => {
    //                 if (selected.startsWith("g:")) {
    //                     // Group column
    //                     const groupId = selected.split("g:")[1];
    //                     return {
    //                         label: groupId, // Group name
    //                         field: groupId,
    //                         sort: "asc",
    //                     };
    //                 } else {
    //                     // Individual plan column
    //                     const plan = filteredPlans.find(plan => plan._id.toString() === selected);
    //                     return {
    //                         label: plan?.name || "Unknown Plan",
    //                         field: plan?._id.toString(),
    //                         sort: "asc",
    //                     };
    //                 }
    //             })
    //             : // If no selection, show all plans and groups
    //             [
    //                 ...groupedPlans.map(group => ({
    //                     label: group.groupname,
    //                     field: group.groupname,
    //                     sort: "asc",
    //                 })),
    //                 ...filteredPlans.map(plan => ({
    //                     label: plan.name,
    //                     field: plan._id.toString(),
    //                     sort: "asc",
    //                 })),
    //             ]),
    //         { label: "Total", field: "total", sort: "asc" }, // Total column
    //     ];

    //     // Build rows
    //     const uniquePeriods = [
    //         ...new Map(
    //             activeMembers.map((item) => {
    //                 const date = new Date(item.date);
    //                 const periodDisplay = computePeriod(DayFilter1, date);

    //                 const period = {
    //                     date,
    //                     display: periodDisplay, // Use the updated display format
    //                 };

    //                 return [period.display, period]; // Use `display` as the key
    //             })
    //         ).values(),
    //     ];
    //     const obj = {
    //         startdate: new Date(moment(startDate).startOf('day')),
    //         enddate: new Date(moment(endDate).endOf('day')),
    //     }
    //     const rows = uniquePeriods.map(period => {
    //         const row = { date: period.display };
    //         let totalMembers = new Set();

    //         (selectValue.length > 0 ? selectValue : [...groupedPlans.map(g => `g:${g.groupname}`), ...filteredPlans.map(p => p._id.toString())]
    //         ).forEach(selected => {
    //             if (selected.startsWith("g:")) {
    //                 // Group logic
    //                 const groupId = selected.split("g:")[1];
    //                 const group = groupedPlans.find(g => g.groupname === groupId);
    //                 if (group) {
    //                     let groupMembers = new Set();
    //                     // activeMembers.forEach((obj) => {
    //                     //     if (obj.count > 0 && (group.planids.includes(obj.planid.toString()))) {
    //                     //         const memberDate = new Date(obj.date);
    //                     //         const periodValue = computePeriod(DayFilter1, memberDate);
    //                     //         if (periodValue == period.display) {
    //                     //             obj.memberids.forEach(id => { totalMembers.add(id); groupMembers.add(id); });
    //                     //         }
    //                     //     }
    //                     // });
    //                     let filterActiveMembers = activeMembers.filter((obj) => obj.count > 0 && (group.planids.includes(obj.planid.toString())));
    //                     const { startDate, endDate } = getStartAndEndDate(DayFilter1, new Date(period.date));
    //                     filterActiveMembers.forEach((obj) => {
    //                         const objDate = new Date(obj.date); // Convert obj.date to Date object
    //                         if (objDate >= startDate && objDate <= endDate) {
    //                             obj.memberids.forEach(id => { totalMembers.add(id); groupMembers.add(id); })
    //                         }
    //                     });
    //                     const groupMembersArray = Array.from(groupMembers);
    //                     row[groupId] =
    //                         // groupMembers.size
    //                         <span className="button-link link-color" onClick={(e) => handleRowClick(e, group.planids, DayFilter1, DayFilter, period.date, groupMembersArray, filteredPlans, obj, `Group Name: ${groupId}`)}>
    //                             {groupMembers.size}
    //                         </span>
    //                 }
    //             } else {
    //                 // Individual plan logic
    //                 const plan = filteredPlans.find(plan => plan._id.toString() == selected);
    //                 if (plan) {
    //                     let groupMembers = new Set();
    //                     let filterActiveMembers = activeMembers.filter((obj) => obj.count > 0 && (obj.planid.toString() == plan._id.toString()));
    //                     const { startDate, endDate } = getStartAndEndDate(DayFilter1, new Date(period.date));
    //                     filterActiveMembers.forEach((obj) => {
    //                         const objDate = new Date(obj.date); // Convert obj.date to Date object
    //                         if (objDate >= startDate && objDate <= endDate) {
    //                             obj.memberids.forEach(id => { totalMembers.add(id); groupMembers.add(id); })
    //                         }
    //                     });
    //                     const groupMembersArray = Array.from(groupMembers);

    //                     row[plan._id.toString()] =
    //                         // groupMembers.size
    //                         <span className="button-link link-color" onClick={(e) => handleRowClick(e, [plan._id], DayFilter1, DayFilter, period.date, groupMembersArray, filteredPlans, obj, `Plan Name: ${plan?.name}`)}>
    //                             {groupMembers.size}
    //                         </span>
    //                 }
    //             }
    //         });
    //         const totalMembersArray = Array.from(totalMembers);

    //         row.total = <span className="button-link link-color" onClick={(e) => handleRowClick(e, filteredPlans.map((obj) => obj._id), DayFilter1, DayFilter, period.date, totalMembersArray, filteredPlans, obj, "Total")}>
    //             {totalMembers.size}
    //         </span>
    //         return row;
    //     });

    //     tableData.columns = columns;
    //     tableData.rows = rows;
    //     setBasicData(tableData);
    //     setLoading(false);
    // };

    const computePeriod = (filter, date) => {
        const options = { month: "short" }; // Abbreviated month name (e.g., Jan, Feb)
        const monthName = new Intl.DateTimeFormat("en-US", options).format(date);

        if (filter === "Date") {
            return date.toISOString().slice(0, 10); // yyyy-mm-dd
        } else if (filter === "Week") {
            const startOfWeek = new Date(date);
            startOfWeek.setDate(date.getDate() - date.getDay()); // Set to the start of the week (Sunday)

            // Check if the start of the week falls in the previous month
            const adjustedDate = new Date(startOfWeek);
            const weekMonthName = new Intl.DateTimeFormat("en-US", options).format(adjustedDate);

            const weekInMonth = Math.ceil(adjustedDate.getDate() / 7); // Calculate the week number in the month
            const suffix = ["st", "nd", "rd", "th"][Math.min((weekInMonth - 1) % 10, 3)];

            return `${weekMonthName} ${weekInMonth}${suffix} week ${adjustedDate.getFullYear()}`;
        } else if (filter === "Month") {
            return `${monthName}-${date.getFullYear()}`; // Format as "Jan-2025"
        } else if (filter === "Year") {
            return date.getFullYear().toString();
        }
        return null;
    };
    const handleRowClick = async (e, planid, dayfilter, DayFilter, date, allMembersIDs, allplans, dates, planName) => {
        setBasicModal(true);
        e.preventDefault();
        console.log('Row clicked:', planid, dayfilter, DayFilter, date, allMembersIDs, allplans, dates, planName);
        let startDate = moment(date).startOf('day');
        let endDate = moment(date).endOf('day');

        switch (dayfilter) {
            case "Date":
                startDate = moment(date).startOf('day');
                endDate = moment(date).endOf('day');
                break;
            case "Week":
                startDate = moment(date).startOf('week');
                endDate = moment(date).endOf('week');
                break;
            case "Month":
                startDate = moment(date).startOf('month');
                endDate = moment(date).endOf('month');
                break;
            case "Year":
                startDate = moment(date).startOf('year');
                endDate = moment(date).endOf('year');
                break;
            default:
                startDate = moment(date).startOf('day');
                endDate = moment(date).endOf('day');
        }
        const update = {
            gymid: JSON.parse(localStorage.getItem("loggedUserInfo")).gymid,
            startDate: new Date(startDate),
            endDate: new Date(endDate),
            allMembersIDs,
            planid: planid.length === 1 ? planid[0] : null,
        }
        console.log(update)

        const data = await getActiveMembersCountByRow(JSON.parse(localStorage.getItem("loggedUserInfo")).gymid, update);

        const response = await data.json();
        console.log(response);
        let tableData = {
            columns: ["S.no", 'Member Id', 'Member Name', "Plan Name", 'OrderId', 'Start Date', "Expirydate"],
            rows: [],
        };
        response.data.sort((a, b) => b.member.mid - a.member.mid)
        let key = 0;
        response.data.forEach((data, index) => {
            data.transactions.map((row) => {
                console.log(row);
                if (
                    (DayFilter === "7") ||
                    (new Date(row.startdate) <= new Date(dates.enddate)
                        && new Date(row.expirydate) >= new Date(dates.startdate)) // Overlap with the range
                ) {
                    let planFilter = planid.filter((obj) => obj === row.itemid)
                    console.log(planFilter)
                    if (planFilter.length > 0) {

                        let rarray = [];
                        const plan = allplans.find(obj => obj._id === row.itemid);
                        if (!plan) {
                            return; // Skip if the plan doesn't exist
                        }
                        key++
                        console.log(plan);
                        rarray.push(key);
                        rarray.push(data.member.mid);
                        rarray.push(data.member.fname + " " + data.member.lname);
                        rarray.push(plan?.name);
                        rarray.push(row.token);
                        rarray.push(
                            new Date(row.startdate).toLocaleString("en-IN", {
                                timeZone: "Asia/Kolkata",
                            }).replace(/(\d+)\/(\d+)\/(\d+)/, (match, day, month, year) => `${year}/${month.padStart(2, '0')}/${day.padStart(2, '0')}`)
                        );
                        rarray.push(
                            new Date(row.expirydate).toLocaleString("en-IN", {
                                timeZone: "Asia/Kolkata",
                            }).replace(/(\d+)\/(\d+)\/(\d+)/, (match, day, month, year) => `${year}/${month.padStart(2, '0')}/${day.padStart(2, '0')}`)
                        );

                        tableData.rows.push(rarray);

                    }
                }
            })
        });
        console.log(tableData)
        setBasicData2(tableData);
        setPlanName(planName);
        setBasicModal(true);
        setInnerLoading(false);
    };
    const matchPeriod = (filter, memberDate, period) => {
        const formattedDate = memberDate.toISOString().slice(0, 10); // yyyy-mm-dd
        if (filter === "Date") {
            return formattedDate === period;
        } else if (filter === "Week") {
            const startOfWeek = new Date(memberDate);
            startOfWeek.setDate(memberDate.getDate() - memberDate.getDay());
            return `${startOfWeek.toISOString().slice(0, 10)} - Week` === period;
        } else if (filter === "Month") {
            const formattedMonth = `${memberDate.getFullYear()}/${String(memberDate.getMonth() + 1).padStart(2, '0')}`;
            return formattedMonth === period;
        } else if (filter === "Year") {
            return memberDate.getFullYear().toString() === period.toString();
        }
        return false;
    };

    useEffect(() => {
        fetchData();
    }, [applyFilterClicked, resetFilterClicked]);

    const handleReload = () => {
        window.location.reload();
    };
    const handleApplyAndToggle = () => {
        const sDate = DayFilter === "1" ? startOfToday : (DayFilter === "2" ? startOfYesterday : (DayFilter === "3" ? startOfWeek : (DayFilter === "4" ? startOfMonth : (DayFilter === "5" ? startOfYear : (DayFilter === "6" ? startDate : null)))))
        const eDate = DayFilter === "1" ? endOfToday : (DayFilter === "2" ? endOfYesterday : (DayFilter === "3" ? endOfWeek : (DayFilter === "4" ? endOfMonth : (DayFilter === "5" ? endOfYear : (DayFilter === "6" ? endDate : null)))))
        if (sDate !== null && eDate !== null) {
            setDateRange([sDate, eDate])
        } else {
            setDateRange([])
        }
        setLoading(true);
        setApplyFilterClicked(true);
    }
    const handleResetAndToggle = () => {
        setSelectValue([]);
        setDataFormat("count")
        setDayFilter("4")
        setSelectedPlanType("Normal")
        setDateRange([startOfMonth, endOfMonth])
        setDayFilter1("Date")
        handleDeleteGroup()
        setGroupedPlans([])
        setResetFilterClicked(true)
        setLoading(true);
    }
    const downloaddata = (data, title) => {
        console.log(data, title);

        // Extract column labels and keys
        let columns = data.columns.map((obj) => obj.label); // Column headers
        let fields = data.columns.map((obj) => obj.field); // Column keys

        // Map rows to match the structure of columns
        let rows = data.rows.map((row) => {
            return fields.map((field) => row[field] ?? ''); // Extract values in column order
        });

        console.log("Formatted Rows:", rows);

        const exportData = {
            columns: columns, // Column headers
            rows: rows,       // Transformed rows
        };
        if (title == "activereports") {
            ExcelExportButton(exportData, title);
        } else {
            ExcelExportButton(data, title);
        }
    };




    const [optSmModal, setOptSmModal] = useState(false);

    const toggleOpenNew = () => setOptSmModal(!optSmModal);

    // console.log(groupedPlans, selectedPlans, '6666666666663555555555555555553');

    const handleCheckboxChange = (planId, type) => {
        if (type === "plan") {
            if (selectedPlans.includes(planId)) {
                setSelectedPlans(selectedPlans.filter(id => id !== planId));
            } else {
                setSelectedPlans([...selectedPlans, planId]);
            }
        } else if (type === "group") {
            const isGroupSelected = selectedPlans.includes(planId.groupname);

            if (isGroupSelected) {
                setSelectedPlans(selectedPlans.filter(name => name !== planId.groupname));
            } else {
                setSelectedPlans([
                    ...selectedPlans.filter(id => !planId.planids.includes(id)),
                    planId.groupname
                ]);
            }
        }
    };

    const [groupPlanIds, setGroupPlanIds] = useState([]);
    const groupSelectedPlans = () => {
        let tempGroupPlanIds = [];
        let selectedGroups = [];
        let selectedPlansOnly = [];

        selectedPlans.forEach(selected => {
            const group = groupedPlans.find(g => g.groupname === selected);
            // console.log(group);

            if (group) {
                selectedGroups.push(group);

            } else {
                selectedPlansOnly.push(selected);
            }
        });

        // Combine the plan ids from selected groups and selected plans
        selectedGroups.forEach(group => {
            tempGroupPlanIds.push(...group.planids);
        });
        tempGroupPlanIds.push(...selectedPlansOnly);

        setGroupPlanIds([...new Set(tempGroupPlanIds)]);
        // console.log(selectedGroups.length, '63543', selectedPlansOnly.length, "77777hdn");
        if (selectedGroups.length > 1 || (selectedGroups.length == 0 && selectedPlansOnly.length > 1)) {
            // console.log("step 1");

            const updatedGroups = groupedPlans.filter(group => !selectedPlans.includes(group.groupname));
            setGroupedPlans(updatedGroups);

            setSelectedPlans([]);
            setOptSmModal(true);
        } else if (selectedGroups.length === 1 && selectedPlansOnly.length >= 1) {
            // console.log("step 2");
            const updatedGroup = {
                ...selectedGroups[0],
                planids: [...new Set([...selectedGroups[0].planids, ...selectedPlansOnly])]
            };

            const updatedGroups = groupedPlans.map(group =>
                group.groupname === updatedGroup.groupname ? updatedGroup : group
            );
            setGroupedPlans(updatedGroups);

            setSelectedPlans([]);
        }


    };

    // console.log(groupedPlans, groupPlanIds, newGroupName, 'hghfshsj');

    const handleSaveGroup = () => {
        const newGroup = {
            groupname: newGroupName,
            planids: groupPlanIds,

        };
        const updatedGroups = [...groupedPlans, newGroup];
        setGroupedPlans(updatedGroups);
        setNewGroupName("");
        setGroupPlanIds([])
        setOptSmModal(false);
    }

    const handleRemovePlan = (planId) => {
        const updatedGroups = groupedPlans.map(group => ({
            ...group,
            planids: group.planids.filter(id => id !== planId)
        })).filter(group => group.planids.length > 0);
        setGroupedPlans(updatedGroups);
        setSelectedPlans(selectedPlans.filter(id => id !== planId));
    };

    const handleEditGroupName = (groupName) => {
        setEditingGroupName(groupName);
        setNewGroupName(groupName);
    };


    const handleSaveGroupName = (oldGroupName) => {
        const updatedGroups = groupedPlans.map(group => {
            if (group.groupname === oldGroupName) {
                return {
                    ...group,
                    groupname: newGroupName
                };
            }
            return group;
        });
        setGroupedPlans(updatedGroups);
        setEditingGroupName(null);
    };

    // console.log(groupedPlans, '777777777');




    const handleUpdateGrouping = async () => {

        const update = {
            bid: JSON.parse(localStorage.getItem("loggedUserInfo")).gymid,
            groupdata: groupedPlans,

        }
        // console.log(update);

        const res = await updateGroupData(JSON.parse(localStorage.getItem("loggedUserInfo")).gymid, update)
        // console.log(res);
        toggleOpenPlan()
    }

    const handleCancel = () => {
        setGroupedPlans(groupedPlansNew)
        setSelectedPlans([])
        toggleOpenPlan()
    }

    const handleDeleteGroup = async () => {
        console.log(groupInfo);

        const res = await deleteGroupData(JSON.parse(localStorage.getItem("loggedUserInfo")).gymid, "activeMemberHistory")
        // console.log(res);

    }

    useEffect(() => {

        const generatingGraphData = () => {
            console.log(basicData);
            const basrow = basicData.rows;
            const columns = basicData.columns;
            // console.log(basrow);


            const labels = basrow.map(row => row.date);

            // Extracting datasets
            const datasets = columns
                .filter(col => col.field !== 'date' && col.field !== 'total') // Exclude `date` and `total`
                .map(col => {
                    return {
                        label: col.label, // Use the label from columns
                        data: basrow.map(row => Number(row[col.field]?.props?.children) || 0), // Extract data for this field
                        backgroundColor: `rgb(${Math.floor(Math.random() * 256)}, ${Math.floor(Math.random() * 256)}, ${Math.floor(Math.random() * 256)})`,

                    };
                });

            setGraphinfo({
                title: "Active Member(s) History",
                bar: "Active Member(s)",
                xtitle: "Active Member(s)",
                ytitle: `${DayFilter1}(s)`,
            })
            setGraphData(
                {
                    labels,
                    datasets,
                });
        };

        generatingGraphData()

    }, [basicData, DayFilter])


    useEffect(() => {
        const targetNode = document.querySelector('.datatable-header');

        if (targetNode) {
            const observer = new MutationObserver(() => {
                const thElements = targetNode.querySelectorAll('th');

                thElements.forEach((th) => {
                    if (th.textContent.trim().includes("Date")) {
                        const icon = th.querySelector('i');
                        if (icon) {
                            icon.style.display = "none";
                            th.style.pointerEvents = "none";
                            th.style.cursor = "default";
                        }
                    }
                });
            });

            observer.observe(targetNode, {
                childList: true,
                subtree: true,
            });

            return () => observer.disconnect();
        }
    }, []);


    return (
        <>
            {apiError ? (
                <PageError handleReload={handleReload} />
            ) : (
                <div>
                    <div className="trainerSummeryFilters d-flex align-items-center mb-3 mt-4">

                        <div className="mob-w-100 me-2">
                            <label className="me-2 fw-bold"> Plan breakdown: </label>
                            <select onChange={(e) => setSelectedPlanType(e.target.value)} value={selectedPlanType} className="form-select">
                                <option value="Normal">Normal Plans</option>
                                <option value="Group">Group Plans</option>
                            </select>

                        </div>

                        <div className="mob-w-100">
                            {/* <div className="gym-section w-100-mobile">

                                <label className="me-2 fw-bold"> Plan: </label>
                                <MDBSelect
                                    className="ellipseOptions"
                                    multiple
                                    placeholder="All"
                                    data={selectData}
                                    value={selectValue}
                                    onChange={(e) => {
                                        const selectedValues = e.map(item => item.value);
                                        setSelectValue(selectedValues);
                                    }}
                                />
                            </div> */}

                            <label className="me-2 fw-bold"> Plan: </label>
                            <MDBSelect
                                className="ellipseOptions"
                                multiple
                                placeholder="All"
                                data={selectData}
                                value={selectValue}
                                onChange={(e) => {
                                    const selectedValues = e.map(item => item.value);
                                    setSelectValue(selectedValues);
                                }}
                            />


                        </div>
                        <MDBBtn className="ms-2 mt-4" onClick={toggleOpenPlan}>Group</MDBBtn>
                        <div className="d-flex align-items-center ms-2 mob-w-100 ">
                            <div className="gym-section w-100-mobile ms-2">
                                <label className="me-2 fw-bold"> Breakdown: </label>
                                <div className="mob-w-100 ">
                                    <select
                                        name="shrs"
                                        id="shr"
                                        className="form-select placeholder-active active mob-w-100"
                                        onChange={(e) => setDayFilter1(e.target.value)}
                                        value={DayFilter1}
                                    >
                                        <option value="Date">Date</option>
                                        <option value="Week">Weekly</option>
                                        <option value="Month">Monthly</option>
                                        <option value="Year">Yearly</option>

                                    </select>
                                </div>
                            </div>
                            <div className="gym-section w-100-mobile ms-2">
                                <label className="me-2 fw-bold"> Days: </label>
                                <div className="mob-w-100 ">
                                    <select
                                        name="shrs"
                                        id="shr"
                                        className="form-select placeholder-active active mob-w-100"
                                        onChange={(e) => setDayFilter(e.target.value)}
                                        value={DayFilter}
                                    >
                                        <option value="7">All</option>
                                        {/* <option value="1">Today</option> */}
                                        <option value="2">Yesterday</option>
                                        <option value="3">This Week</option>
                                        <option value="4">This Month</option>
                                        <option value="5">This Year</option>
                                        <option value="6">Custom</option>

                                    </select>
                                </div>
                            </div>
                        </div>
                        {customdate && <div className="ms-3  mob-w-100">
                            <label className="me-2 fw-bold"> Select Custom Date: </label>
                            <div className="gym-xlarge  gym-larg-pos mob-w-100">
                                <DatePicker
                                    dateFormat={"d/M/yyyy"}
                                    selectsRange={true}
                                    startDate={startDate}
                                    endDate={endDate}
                                    onChange={(update) => {
                                        getDates(update);
                                    }}
                                    isClearable={false}
                                    customInput={<CalenderInput />}
                                />
                            </div>
                        </div>}

                        <div className="mob-div-center mt-3 pt-1 filterApplyMobile">
                            <MDBBtn className="ms-2" onClick={(e) => handleApplyAndToggle()}>Apply</MDBBtn>
                            <MDBBtn className="ms-2" color="secondary" onClick={(e) => handleResetAndToggle()}>Clear</MDBBtn>
                            <MDBBtn className="ms-2" onClick={() => setGraphCheck(!graphCheck)}>
                                {graphCheck ? <span><MDBIcon fas icon="table" className="me-1" />Table </span> :
                                    <span><MDBIcon fas icon="chart-bar" className="me-1" />Graph</span>}
                            </MDBBtn>
                            {!graphCheck && <MDBBtn className="ms-2" onClick={() => downloaddata(basicData, "activereports")}>Export</MDBBtn>}
                        </div>
                    </div>


                    {!graphCheck && <div className={dataFormat === "count" ? "trainerSummeryDataTableNew plan mt-4" : "trainerSummeryDataTable plan mt-4"}>
                        {loading ? (<div className="loader-height"> <Loader /></div>) : <>
                            <MDBDatatable className="sortOff" entries={25} paging={true} data={basicData} />
                        </>}
                    </div>}

                    {graphCheck && <div className="w-100 border p-4 mb-4 shadow-4 mob-w-100 mob-p-0">
                        {loading ? (<div className="loader-height"> <Loader /></div>) : <>
                            <ReportsGraph data={graphData} info={graphinfo} /></>}
                    </div>}



                    <MDBModal tabIndex='-1' open={centredModal} onClose={() => setCentredModal(false)}>
                        <MDBModalDialog centered size="lg">
                            <MDBModalContent>
                                <MDBModalHeader>
                                    <MDBModalTitle>Grouping</MDBModalTitle>
                                    <MDBBtn className='btn-close' color='none' onClick={toggleOpenPlan}></MDBBtn>
                                </MDBModalHeader>
                                <MDBModalBody>
                                    <MDBRow>
                                        {/* Display Plans */}
                                        <MDBCol size={5}>
                                            <h5>Groups</h5>
                                            <MDBCard>
                                                <MDBCardBody className="GroupingCardHeight">
                                                    {/* Display Groups */}
                                                    {groupedPlans.length > 0 && groupedPlans.map((group, index) => (
                                                        <div key={`group-${index}`}>
                                                            <div
                                                                className={`my-1 p-2 rounded-3 ${selectedPlans.includes(group.groupname) ? 'bg-success bg-opacity-25' : ''}`}
                                                            // className="my-1 p-2 rounded-3"
                                                            >
                                                                {editingGroupName === group.groupname ? (
                                                                    <div className="d-flex align-items-center">
                                                                        <MDBInput
                                                                            value={newGroupName}
                                                                            onChange={(e) => setNewGroupName(e.target.value)}
                                                                        />
                                                                        <MDBBtn size="sm" color="success" className="ms-2" onClick={() => handleSaveGroupName(group.groupname)}>
                                                                            <MDBIcon far icon="save" />
                                                                        </MDBBtn>
                                                                    </div>
                                                                ) : (
                                                                    <div

                                                                        className="d-flex justify-content-between">
                                                                        <MDBCheckbox
                                                                            name='flexCheck'
                                                                            value={group.groupname}
                                                                            id={`group-${group.groupname}`}
                                                                            label={group.groupname}
                                                                            checked={selectedPlans.includes(group.groupname)}
                                                                            onChange={() => handleCheckboxChange(group, 'group')}
                                                                        />
                                                                        <div size="sm" color="info" onClick={() => handleEditGroupName(group.groupname)}>
                                                                            <MDBIcon far icon="edit" />
                                                                        </div>
                                                                    </div>
                                                                )}
                                                            </div>
                                                            <hr class="dropdown-divider" />
                                                        </div>
                                                    ))}

                                                    {/* Display Plans that are not in any group */}
                                                    {plans && plans.length > 0 && plans.map((plan) => {
                                                        if (!groupedPlans.some(group => group.planids.includes(plan._id))) {
                                                            return (
                                                                <>
                                                                    {/* bg-success bg-opacity-25 */}
                                                                    <div
                                                                        className={`my-1 p-2 rounded-3 ${selectedPlans.includes(plan._id) ? 'bg-success bg-opacity-25' : ''}`}
                                                                        key={plan._id} >
                                                                        <MDBCheckbox
                                                                            name='flexCheck'
                                                                            value={plan._id}
                                                                            id={`plan-${plan._id}`}
                                                                            label={plan.name}
                                                                            checked={selectedPlans.includes(plan._id)}
                                                                            onChange={() => handleCheckboxChange(plan._id, 'plan')}
                                                                        />
                                                                    </div>
                                                                    <hr class="dropdown-divider" />
                                                                </>
                                                            );
                                                        }
                                                        return null;
                                                    })}
                                                </MDBCardBody>
                                            </MDBCard>
                                        </MDBCol>

                                        {/* Group Plans */}
                                        <MDBCol>
                                            <div className="d-flex align-items-center justify-content-center h-100">
                                                <MDBBtn disabled={selectedPlans.length > 1 ? false : true} onClick={groupSelectedPlans}>Group</MDBBtn>
                                            </div>
                                        </MDBCol>

                                        {/* Display Selected Plans */}
                                        <MDBCol size={5}>
                                            <h5>Categories</h5>
                                            <MDBCard>
                                                <MDBCardBody className="p-0 GroupingCardHeight">
                                                    {selectedPlans.length > 0 &&
                                                        plans.filter(plan => {
                                                            const isInGroup = groupedPlans.some(group => selectedPlans.includes(group.groupname) && group.planids.includes(plan._id));
                                                            return selectedPlans.includes(plan._id) || isInGroup;
                                                        }).map((item, index, filteredPlans) => (
                                                            <div key={item._id} className={`py-2 px-3 border d-flex justify-content-between align-items-center ${index !== filteredPlans.length - 1 ? 'border-bottom-0' : ''
                                                                }`}>
                                                                {item.name}
                                                                {/* <MDBBtn color='danger' size='sm' onClick={() => handleRemovePlan(item._id)}> */}
                                                                {/* <MDBIcon fas icon='trash' /> */}
                                                                <i className="fw-bold fs-5 fa fa-close ms-2 text-danger" onClick={() => handleRemovePlan(item._id)}></i>
                                                                {/* </MDBBtn> */}
                                                            </div>
                                                        ))}
                                                </MDBCardBody>
                                            </MDBCard>
                                        </MDBCol>
                                    </MDBRow>

                                    <MDBModal open={optSmModal} tabIndex='-1' onClose={handleCancel}>
                                        <MDBModalDialog size='sm'>
                                            <MDBModalContent>
                                                <MDBModalBody>
                                                    <h6>Group Name</h6>
                                                    <MDBInput
                                                        label='Enter Group Name'
                                                        value={newGroupName}
                                                        onChange={(e) => setNewGroupName(e.target.value)}
                                                    />
                                                </MDBModalBody>
                                                <MDBModalFooter>
                                                    <MDBBtn color='secondary' onClick={toggleOpenNew}>
                                                        Close
                                                    </MDBBtn>
                                                    <MDBBtn onClick={handleSaveGroup}>
                                                        Save
                                                    </MDBBtn>
                                                </MDBModalFooter>
                                            </MDBModalContent>
                                        </MDBModalDialog>
                                    </MDBModal>


                                </MDBModalBody>
                                <MDBModalFooter>
                                    <MDBBtn color='secondary' onClick={handleCancel}>Cancel</MDBBtn>
                                    <MDBBtn onClick={handleUpdateGrouping}>Save</MDBBtn>
                                </MDBModalFooter>
                            </MDBModalContent>
                        </MDBModalDialog>
                    </MDBModal>
                    <MDBModal open={basicModal} setOpen={setBasicModal} tabIndex='-1'>
                        <MDBModalDialog size="lg">
                            <MDBModalContent>
                                <MDBModalHeader>
                                    <MDBModalTitle><div className="d-flex"><div>{PlanName}</div></div></MDBModalTitle>
                                    <MDBBtn className='btn-close' color='none' onClick={() => { setBasicModal(false) }}></MDBBtn>
                                </MDBModalHeader>
                                <MDBModalBody>
                                    {basicModal &&
                                        (innerLoading ?
                                            <div className="d-flex align-items-center justify-content-center">
                                                <MDBBtn color="primary">
                                                    <span class="spinner-border spinner-border-sm me-2" role="status">
                                                    </span>
                                                    Loading...
                                                </MDBBtn>
                                            </div>
                                            :
                                            <div className="trainerMemberSummeryDataTable">
                                                <MDBDatatable entries={25} data={basicData2} />
                                            </div>)

                                    }
                                </MDBModalBody>
                                <MDBModalFooter className="pe-4 pb-3">
                                    <MDBBtn className="ms-2" onClick={() => downloaddata(basicData2, PlanName)}>download</MDBBtn>
                                </MDBModalFooter>
                            </MDBModalContent>
                        </MDBModalDialog>
                    </MDBModal>
                </div>
            )}
        </>
    );
}

export default ActiveMemberHistoryTable;
