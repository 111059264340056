import { MDBIcon, MDBInputGroup, MDBTabs, MDBTabsItem, MDBTabsLink, MDBTooltip } from "mdb-react-ui-kit";
import React, { useEffect, useState } from "react";
import ReactGA from 'react-ga';
import { useLocation } from "react-router";
import Header from "../components/Header";
import LeftPane from "../components/LeftPane";
import Loader from "../components/Loader";
import Popup from "../components/Popup";
import LogATime from "../components/logatime";
import SubPopup from "../components/supPopup";
import Timer from "../components/timer";
import {  getNewCheckMembers } from "../services/apiServices.js";
import logtimeImg from "./../components/images/log_timeGreen.svg";
import AttendanceList from "./AttendanceList";
import PageError from "./PageError";

const Check = (props) => {
  const [logtimeShow, setLogtimeShow] = useState(false);
  const [openAttendance, setOpenAttendance] = useState(false);
  const [selData, setSelData] = useState(false);
  const [value, setvalue] = useState("");
  const [apiError, setApiError] = useState(false);
  const [activestatus, setActive] = useState("active");
  const [userData, setData] = useState([]);
  const [selectedId, setSelectedId] = useState(-1);
  const [checkedMembers, setCheckedMembers] = useState([]);
  const [loading, setLoading] = useState(true)
  const [valuee, setValuee] = useState(-1);
  const [Date11, setDate11] = useState(new Date(new Date().getFullYear(), new Date().getMonth(), 1));
  const [Date22, setDate22] = useState(new Date());
  const location = useLocation();
  const [searchQuery, setSearchQuery] = useState("");//1
  const [typingTimeout, setTypingTimeout] = useState(0);//2
  const [activepage, setActivepage] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(30);
  const [totalPages, setTotalPages] = useState(1);
  const [currentPage1, setCurrentPage1] = useState(1);
  const [itemsPerPage1, setItemsPerPage1] = useState(30);

  useEffect(() => {
    ReactGA.pageview(window.location.pathname);
  }, []);


  let filter = {}

  let inputHandler = (event) => {
    if (typingTimeout) {
      clearTimeout(typingTimeout);
    }
    const sTxt = event.target.value;

    setTypingTimeout(
      setTimeout(() => {
        if (sTxt.trim() !== "") {
          setSearchQuery(event.target.value);
          // setPage(1);
        } else {
          setSearchQuery("");
          setData([]);
          setLoading(true);
          // fetchData(sTxt);
        }
      }, 500)
    );


  };


  function openLogTime(e, data, value) {
    console.log(data, value);
    setSelData(data);
    setvalue(value);
    setLogtimeShow(true);
  }

  function checkMember(updates, member) {
    try {
      console.log(updates);
      let inputDate = new Date(updates.date);
      let currentDate = new Date();
      currentDate.setHours(0, 0, 0, 0);
      inputDate.setHours(0, 0, 0, 0);
      setData((prevState) => {
        if (prevState.length > 0) {
          const updatedData = prevState.map((entry) => {
            if (updates && entry._id === updates.mid) {
              entry.checkindate = updates.date;
              entry.checkinflag = Number(updates.checkFlag);
              if (new Date(inputDate) < new Date(currentDate)) {
                entry.checkinflag = 0
              }
              if (updates.checkFlag === "1") {
                let value = {
                  bId: localStorage.getItem("loggedUserInfo") ? JSON.parse(localStorage.getItem("loggedUserInfo")).gymid : "",
                  createdAt: new Date(),
                  updatedAt: new Date(),
                  autocheckout: updates.autocheckout,
                  trainer: updates.trainer ? updates.trainer : "",
                  trainerid: updates.trainerid ? updates.trainerid : "",
                  date: updates.date,
                  id: updates.mid,
                  intime: updates.date,
                  member_type: "1",
                  _id: updates.mid,
                }
                entry.attendance.unshift(value)
              }
              else {
                entry.attendance[0].outtime = updates.date
              }
              // entry.attendance.unshift(updates);
            }
            return entry;
          });
          return updatedData;
        }
        return prevState;
      });

      /**Added newly as per Ranga requirement */
      // if (updates.checkFlag === "1") {
      //     setCheckedMembers([...checkedMembers, member._id])
      // } else {
      //     let tmpmember = checkedMembers.filter((item) => item !== member._id);
      //     setCheckedMembers(tmpmember)
      // }
    } catch (e) { console.error(e) }
    setLogtimeShow(false);
  }
  console.log(userData)
  function closeLogTime(updates, member) {
    try {
      /**Added newly as per Ranga requirement */
      if (updates.checkFlag === "1") {
        setCheckedMembers([...checkedMembers, member._id])
      } else {
        let tmpmember = checkedMembers.filter((item) => item !== member._id);
        setCheckedMembers(tmpmember)
      }
    } catch (e) { console.error(e) }

    setLogtimeShow(false);
  }
  function closeAttenance() {
    setOpenAttendance(false);
  }
  function viewAttendance(e, data) {

    let tmpmember = checkedMembers.filter((item) => item === data._id);
    console.log(checkedMembers)
    console.log(data._id);
    if (tmpmember && tmpmember.length > 0) {
      data.checkedin = true;
    } else {
      data.checkedin = false;
    }

    setSelData(data);
    setSelectedId(data._id);
    setOpenAttendance(true);
    //alert(data._id);
  }
  function getExpiryDate(data) {
    try {
      let startdate = new Date();
      let enddate = data.expirydate;
      let expirydate = new Date(enddate).getTime() - startdate.getTime();
      let cd = 24 * 60 * 60 * 1000;
      // console.log(Math.floor(expirydate / cd));
      let exdays = Math.floor(expirydate / cd) + 1;

      return exdays >= 1
        ? exdays + " Day(s)" :
        data.attendance[0] &&
          new Date(data.attendance[0].intime).getTime() >=
          new Date(enddate).getTime()
          ? "Expired (Overdue)"
          : "Expired";
    } catch (e) { }
    return "";
  }
  const usersData = userData
  useEffect(() => {
    setLoading(true)
    fetchData();
    async function fetchData(sTxt) {
      if (localStorage.getItem("loggedUserInfo")) {
        let search = searchQuery;

        if (sTxt) {
          search = sTxt;
        }
        let date = new Date()
        date.setHours(0, 0, 0, 0);
        if (location && !location.status) {
          location.status = activestatus;
        }
        if (location.status === "active") {
          location.limitmember = itemsPerPage;
          if (currentPage > totalPages) {
            setCurrentPage(1)
          }
          location.currentPage = currentPage > totalPages ? 1 : currentPage;

        } else {
          location.limitmember = itemsPerPage1;
          if (currentPage1 > totalPages) {
            setCurrentPage1(1)
          }
          location.currentPage = currentPage1 > totalPages ? 1 : currentPage1;
        }
        filter.name = search
        location.filter = filter;
        if (location.state === null && valuee !== -1) {
          location.state = {}
          location.state.data = valuee;
          location.state.fdate = Date11;
          location.state.tdate = Date22;
        }
       
        const response = await getNewCheckMembers(
          JSON.parse(localStorage.getItem("loggedUserInfo")).gymid,
          location
        )

        console.log(location);
        if (!response) {
          return;
        }
        if (response === "false") {
          setApiError(true)
        } else {
          const tuserData = !props.internalload
            ? await response.json()
            : props.members;


          if (!tuserData) {
            return;
          }

          console.log(tuserData);


          setTotalPages(tuserData.totalPages)

          setData(tuserData.paginatedMembers);

          setValuee(location && location.state && location.state.data);
          setDate11(location && location.state && location.state.fdate);
          setDate22(location && location.state && location.state.tdate);
          setLoading(false);
        }
      }
    }
  }, [currentPage, currentPage1, itemsPerPage, location, itemsPerPage1, activepage, searchQuery]);

  const handleinactive = () => {
    setActive("inactive");
    location.status = "inactive"
    setLoading(true);
    setActivepage(false)
    // fetchData();
  }
  const handleinactive1 = () => {
    setActive("active");
    location.status = "active"
    setLoading(true);
    setActivepage(true)
    // fetchData();
  }

  const nextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
    setLoading(true);
  };

  const prevPage = () => {
    if (currentPage > 0) {
      setCurrentPage(currentPage - 1);
    }
    setLoading(true);
  };


  
  const nextPage1 = () => {
    if (currentPage1 < totalPages) {
      setCurrentPage1(currentPage1 + 1);
    }
    // fetchData();
  };

  const prevPage1 = () => {
    if (currentPage1 > 0) {
      setCurrentPage1(currentPage1 - 1);
    }
    // fetchData();
  };
  const handleReload = () => {
    window.location.reload();
  }
  return (
    <>
      {!props.internalload && (
        <>
          <LeftPane isactive="5" />
          <Header />
        </>
      )}
      {apiError ? <PageError handleReload={handleReload} /> :
        <div
          className={!props.internalload ? "gym-main gym-main-div rightContent" : "gym-main rightContent"}
          style={{ height: '100vh' }}
        >
          <div className="gym-container gym-cnr" id="showcase">
            <div className="d-flex align-items-center mob-block">

              <h3 className="gym-large m-0">Check-In / Check-Out(s)</h3>
              <div className="gym-xlarge gym-text-red d-flex justify-content-between ms-2" style={{ position: "relative" }}>
                <MDBInputGroup
                  textAfter={<MDBIcon fas icon="search" />}
                >
                  <input
                    className="form-control gym-input forminput ms-2"
                    type="text"
                    placeholder="Search by Member Name"
                    onChange={(event) => inputHandler(event)}
                  />
                </MDBInputGroup>

              </div>
            </div>
            <div className="w-100 d-flex align-items-center justify-content-start">
              <MDBTabs className="mb-3">
                <MDBTabsItem>
                  <MDBTabsLink className={activepage ? "active" : ""}
                    onClick={() => handleinactive1()}
                  // active={basicActive === "tab1"}
                  >
                    Active Members
                  </MDBTabsLink>
                </MDBTabsItem>
                <MDBTabsItem>
                  <MDBTabsLink
                    className={!activepage ? "active" : ""}
                    onClick={() => handleinactive()}
                  >
                    Inactive Members
                  </MDBTabsLink>
                </MDBTabsItem>
              </MDBTabs>
              {/* {activepage ? <MDBBtn color='warning' onClick={() => handleinactive()}>inactive</MDBBtn> : <MDBBtn onClick={() => handleinactive1()}>active</MDBBtn>} */}
            </div>

          </div>

          <div className="row checksHeight">
            <div className="w-100 h-auto row m-0">
              {(usersData && !loading) ?
                usersData.map((data, index) =>
                (<>
                  <div className="col-lg-3 col-md-4 col-lp-6">
                    <div className="checkinbuttonmain boxpadding">

                      <div className="insidecheck">
                        <div className="d-flex align-items-baseline">
                          <MDBTooltip tag='span' wrapperProps={{ className: 'checkIn-Head' }}
                            title={data.fname + " " + data.lname} >
                            {data.fname + " " + data.lname}
                          </MDBTooltip>
                        </div>

                        <span className="mt-1">
                          <span className=" ">
                            {data.sex ? data.sex[0] : ""}{(data.sex && (data.age || data.weight)) ? ', ' : ' '}
                            {data.age ? + data.age + "y" : ""}{(data.age && data.weight) ? ', ' : ''}
                            {data.weight ? + data.weight + "kgs, " : ""}
                          </span>

                          Member ID: {data.mid}
                        </span>




                        {!data.issuspend ? <span className="mt-1">
                          {getExpiryDate(data).includes('Expired') ?
                            <span className="text-danger">Plan {getExpiryDate(data)}</span>
                            : <span> Plan Expires:{getExpiryDate(data)}</span>
                          }

                        </span> : <label className="text-danger mt-1">Member Suspended</label>}

                        <span className="mt-1">
                          <button

                            type="button"
                            className="P-0 button-link link-color"
                            onClick={(e) => viewAttendance(e, data)}
                          >
                            View Attendance
                          </button>
                        </span>
                      </div>
                      {!data.issuspend && <div className="log-expire">
                        <div
                          style={{ cursor: "pointer" }}

                          className={

                            data.checkinflag
                              === 1
                              ? "checkindate checkinbuttonout"
                              : "checkindate"
                          }
                          onClick={(e) => openLogTime(e, data,
                            data.checkinflag === 1 ? "Check-Out" : "Check-In")}


                        >
                          <input
                            type="button"
                            value={
                              data.checkinflag === 1
                                ? "Check-Out"
                                : "Check-In"
                            }
                            className={

                              data.checkinflag === 1
                                ? "checkinbuttonout"
                                : (getExpiryDate(data).includes('Expired') ? "ExpiredCheckin" : "checkinbutton")
                            }
                          />

                          {/* {checkedMembers.filter(
                                                (item) => item == data._id
                                            ) != ""  */}

                          {data.checkinflag === 1
                            && (
                              <p className="d-flex m-inherit f-9">

                                <Timer
                                  member={data.fname}
                                  time={
                                    new Date(data.checkindate)

                                  }
                                />
                                &nbsp;
                              </p>
                            )}
                        </div>

                        <span
                          className="logtime pt-1 pb-1 text-end"
                          data-mdb-toggle="modal"
                          data-mdb-target="#logtime"
                          onClick={(e) => openLogTime(e, data,"Log Time")}
                        >
                          &nbsp;
                          <img className="w-16 logaTimeIcon" src={logtimeImg} alt="logatime" />
                          &nbsp; Log Time
                        </span>
                      </div>}
                    </div>
                  </div>
                </>
                )
                ) : (<div className="loader-height"> <Loader /></div>)}
              {!loading && usersData && usersData.length === 0 && (
                <>No member found to check-in</>
              )}
            </div>
          </div>
          {activepage && <>
            <div className="datatable-pagination">
              Rows per page
              <div className="ms-2 form-control w-auto">
                <select value={itemsPerPage} onChange={(e) => { setItemsPerPage(Number(e.target.value)); }} className="Pagenation-Select">
                  <option value="30">
                    <div className="select-option">
                      30
                    </div>
                  </option>
                  <option value="50">
                    <div className="select-option">
                      50
                    </div>
                  </option>
                  <option value="100">
                    <div className="select-option">
                      100
                    </div>
                  </option>
                  <option value="500">
                    <div className="select-option">
                      500
                    </div>
                  </option>
                  <option value="1000">
                    <div className="select-option">
                      1000
                    </div>
                  </option>
                </select>
              </div>
              <button onClick={prevPage}
                disabled={currentPage === 1}
                className="btn btn-link datatable-pagination-button datatable-pagination-right ripple-surface-dark">
                Previous
              </button>
              <div className="d-flex justify-content-between w-120px align-items-center"> Page
                {/* {currentPage}  */}
                <input className="form-control w-50px" value={currentPage} onChange={(e) => setCurrentPage(Number(e.target.value))} />
                of {totalPages}</div>
              <button onClick={nextPage}
                disabled={currentPage === totalPages}
                className="btn btn-link datatable-pagination-button datatable-pagination-right ripple-surface-dark">
                Next
              </button>
            </div>
          </>}


          {!activepage && <>
            <div className="datatable-pagination">
              Rows per page
              <div className="ms-2 form-control w-auto">
                <select value={itemsPerPage1} onChange={(e) => { setItemsPerPage1(Number(e.target.value)); }} className="Pagenation-Select">
                  <option value="30">
                    <div className="select-option">
                      30
                    </div>
                  </option>
                  <option value="50">
                    <div className="select-option">
                      50
                    </div>
                  </option>
                  <option value="100">
                    <div className="select-option">
                      100
                    </div>
                  </option>
                  <option value="500">
                    <div className="select-option">
                      500
                    </div>
                  </option>
                  <option value="1000">
                    <div className="select-option">
                      1000
                    </div>
                  </option>
                </select>
              </div>
              <button onClick={prevPage1}
                disabled={currentPage1 === 1}
                className="btn btn-link datatable-pagination-button datatable-pagination-right ripple-surface-dark">
                Previous
              </button>
              <div className="d-flex justify-content-between w-140px align-items-center"><span className="mx-1"> Page</span>
                {/* {currentPage}  */}
                <input className="form-control w-50px" value={currentPage1} onChange={(e) => setCurrentPage1(Number(e.target.value))} />
                <span className="mx-1">of</span>{totalPages}</div>
              <button onClick={nextPage1}
                disabled={currentPage1 === totalPages}
                className="btn btn-link datatable-pagination-button datatable-pagination-right ripple-surface-dark">
                Next
              </button>
            </div>
          </>}
        </div >}
      {/* {loading && (<h4><Loader /></h4>)
      } */}
      {
        openAttendance && (
          <Popup
            title="Attendance"
            ptype="modal-lg"
            content={
              <>
                <div>
                  <AttendanceList
                    id={selectedId}
                    data={selData}
                    oncancel={closeLogTime}
                    onsave={checkMember}
                    onclose={closeAttenance}
                    // oncheckin={checkinUser}
                    expires={getExpiryDate(selData).includes('Expired (Overdue)') ? 'Expired(Overdue) ' : (getExpiryDate(selData).includes('Expired') ? 'Expired' : `Expires:${getExpiryDate(selData)}`)}
                    backallback={closeAttenance}
                    submit="0"
                  />
                </div>
              </>
            }
            handleClose={closeAttenance}
          />
        )
      }
      {
        logtimeShow && (
          <SubPopup
            title={value != null ? value : "Log Time"}
            ptype="modal-md"
            content={
              <>
                <div>
                  <LogATime
                    id={selectedId}
                    data={selData}
                    value={value}
                    oncancel={closeLogTime}
                    expires={getExpiryDate(selData).includes('Expired (Overdue)') ? 'Expired(Overdue) ' : (getExpiryDate(selData).includes('Expired') ? 'Expired' : `Expires:${getExpiryDate(selData)}`)}
                    onsave={checkMember}
                  />
                </div>
              </>
            }
            handleClose={closeLogTime}
          />
        )
      }
    </>
  );
};
export default Check;