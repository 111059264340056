import {
  MDBCard,
  MDBCardBody,
  MDBCardText,
  MDBCardTitle,
  MDBCheckbox,
  MDBIcon,
  MDBBadge
} from "mdb-react-ui-kit";
import React, { useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import addImg from "./../components/images/add.svg";
import minusImg from "./../components/images/minus-blue.svg";
import AddMembers from "./../views/AddUsers.js";
import Popup from "./Popup";
import Discount from "./pos/discounts";


const PATemplate = (props) => {
  const [startDate, setStartDate] = useState(new Date());

  const [showAssociateCheck, setShowAssociateCheck] = useState(false);
  const [alreadysel, setAlreadysel] = useState();
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [displayMembers, setDisplayMembers] = useState(false);
  const [discountUnit, setDiscountUnit] = useState([]);
  const [discountValues, setDiscountValues] = useState([]);
  const [discountdata, setDiscountdata] = useState({})
  const [selmember, setSelmember] = useState([])
  function getToken() {
    try {
      const tokenString = localStorage.getItem("transactionId");
      return tokenString;
    } catch (e) {
      console.log("error :::::: " + e);
      return;
    }
  }
  const notSelectedOptions =
    props && props.usersInfo && props.usersInfo.length > 0
      ? props.usersInfo
        .filter((obj) => !selectedOptions.includes(obj._id))
        .map((obj) => obj._id)
      : [];
  if (notSelectedOptions) {
    props.nonselectmem(notSelectedOptions, props.obj._id);
  }
  const handleCheckboxChange = (e, item) => {
    const optionId = item._id;
    if (e.target.checked) {
      // Add the selected option to the array
      setSelectedOptions([...selectedOptions, optionId]);
    } else {
      // Remove the deselected option from the array
      setSelectedOptions(selectedOptions.filter((opt) => opt !== optionId));
    }
  };


  useEffect(() => {
    console.log(props);

    if (props.Alreadyselmem.length > 0) {
      let value = props.Alreadyselmem.find((itm) => itm[0] === props.obj._id);
      if (value) {
        let selectedMembers = value[1];
        setAlreadysel(selectedMembers);
      } else {
        setAlreadysel([]);
      }
    }
    if (props.usersInfo && props.usersInfo.length > 0) {
      setSelectedOptions(props.usersInfo.map((obj) => obj._id));
    }

  }, [displayMembers]);
  function addSelectedMembers(members) {
    props.onAddMembers(props.obj._id, members, "", showAssociateCheck);
    closeMemPopup();
  }
  function dispMembers(eventObj) {
    eventObj.preventDefault();

    let temSelItme = JSON.parse(localStorage.getItem("selItems"));
    setShowAssociateCheck(
      temSelItme.filter((item) => item.isplan === "1").length > 0 &&
      temSelItme.filter((item) => item.isplan === "0").length > 0 &&
      props.obj.isplan === "1"
    );
    setDisplayMembers(true);
  }
  function closeMemPopup() {
    setDisplayMembers(false);
  }

  function decrementCount(eventObj) {
    //**** Function To remove the selected members***//
    //****Chinna --25-10-2023 ***/
    try {
      let item = props.usersInfo.pop();
      handleDeleteMember(eventObj, item, props.item._id);
      // props.decrementCount(eventObj, props.obj._id);
    } catch (e) { }
  }
  function incrementCount(eventObj) {
    //**** Function To add members again***//
    //****Chinna --25-10-2023 ***/
    dispMembers(eventObj);
    // props.incrementCount(eventObj, props.obj._id);
  }
  function setSDate(date) {
    setStartDate(date);
    try {
      props.setStartDate(date, props.obj._id);
    } catch (e) { }
  }

  let taxPercentage = (props.obj.taxamounts &&
    props.obj.taxamounts.length > 0 &&
    props.obj.taxamounts[props.obj.taxamounts.length - 1].TaxCheck === "Included")
    ?
    Number(props.obj.taxamounts.reduce((acc, obj) => {
      if (obj.percentage) {
        return acc + parseFloat(obj.percentage);
      }
      return acc;
    }, 0)
    )
    : 0;

  const handleDiscountChange1 = (index, value, discountUnit, discountAmount, mid) => {
    setDiscountValues((prevValues) => {
      const updatedValues = [...prevValues];
      updatedValues[index] = value;
      return updatedValues;
    });

    setDiscountUnit((prevUnits) => {
      const updatedUnits = [...prevUnits];
      updatedUnits[index] = discountUnit;
      return updatedUnits;
    });
    // calculateTotalDiscount();
    setDiscountdata(
      {
        mid: mid,
        planid: props.obj._id,
        discountUnit: discountUnit,
        enterdiscount: value,
        discountAmount: discountAmount,
        index: index
      }
    )
    tdiscount(mid, props.obj._id, discountUnit, value, discountAmount);
    console.log("hiiiii", index, value, discountUnit, discountAmount, mid);
  };

  function tdiscount(mid, planid, discountUnit, value, discountAmount) {
    console.log("!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!", mid, planid, discountUnit, value, discountAmount);
    props.tdiscount(mid, planid, discountUnit, value, discountAmount);
  }

  function selectedMember(members) {
    setSelmember(members)
  }

  const handleDeleteMember = (e, val, planId) => {
    //**** Function To remove the selected members***//
    //****Chinna --25-10-2023 ***/
    e.preventDefault();
    props.handleDeleteMember(e, val._id, planId);
  };

  const handleDeletePlan = (id) => {
    if (window.confirm("Are you sure you want to delete ?")) {
      console.log("Deleting plan with ID:", id);
      props.hadleDeletePlan(id);
    }
  };

  console.log(props);
  console.log(props.usersInfo);
  console.log(selmember);




  return (
    <>
      <div className="timegym-home  ">
        <MDBCard className="shadow-4">
          <MDBCardBody>
            <MDBCardTitle>
              {" "}   <div className="d-flex justify-content-between">
                <div>
                  {props.item.name ? props.item.name : props.item.props[0].propname} <MDBBadge light className="ms-2" color={props.item.type === "plan" ? "primary" : "success"}>{props.item.type === "plan" ? "Plan" : "Addon"}</MDBBadge>
                </div>
                <button
                  type="button"
                  className="px-2 fs-10 deleteIcon mob-w-10 button-link"
                  onClick={() => handleDeletePlan(props.item._id)}
                >
                  <MDBIcon fas icon="trash" color="##636363" />
                </button>
              </div>
            </MDBCardTitle>
            <MDBCardText>
              <div className="d-flex align-items-center justify-content-between posMobileView">
                <div className="d-flex flex-column justify-content-center align-items-center ">
                  <small> Member(s)</small>
                  <button
                    type="button"
                    className="mt-2 p-0 button-link"
                    onClick={(event) => dispMembers(event)}
                  >
                    <img src={addImg} width="20px" alt="Add" />
                  </button>
                </div>

                <div className="d-flex flex-column justify-content-center align-items-center ">
                  <small>Quantity</small>
                  <div className="d-flex mt-2 align-items-center">

                    <button
                      type="button"
                      className="p-0 pe-2 button-link"
                      onClick={(event) => decrementCount(event)}
                    >
                      <img src={minusImg} style={{ cursor: "pointer" }} width="20px" alt="Remove" />
                    </button>
                    <strong className="pe-2">{props.quantity}</strong>

                    <button
                      type="button"
                      className="p-0  button-link"
                      onClick={(event) => incrementCount(event)}
                    >
                      <img src={addImg} style={{ cursor: "pointer" }} width="20px" alt="Add" />
                    </button>
                  </div>
                </div>

                <div className="d-flex align-items-center justify-content-between">
                  <div className="d-flex flex-column justify-content-center align-items-center ">
                    <small>Start Date</small>
                    <div className="mt-2">
                      <DatePicker
                        selected={startDate}
                        dateFormat="MM-dd-yyyy"
                        className="inp-clrdate-Small"
                        onChange={(date) => setSDate(date)}
                      />
                    </div>
                  </div>
                </div>

                <div>
                  <div className="d-flex align-items-center fw-bold">
                    {/* <MDBIcon className="fs-5 m-0 me-2" fas icon="rupee-sign" /> */}
                    {/* <strike className="me-2">
                      {props.price ? props.price : props.item.price}
                    </strike> */}
                    &#8377;
                    {props.price ? props.price.toLocaleString('en-IN') : props.item.price.toLocaleString('en-IN')}
                  </div>
                  {props.tax && (
                    <small className="m-0 mt-2">
                      {" "}
                      {props.item.taxcheck ? `  Included Tax (${taxPercentage}%)` : ""}
                    </small>
                  )}
                </div>
              </div>

              <div className="mt-2">
                {props.usersInfo && props.usersInfo.length > 0 && (
                  <>
                    <strong className="m-0 mb-2">Member(s) Details:</strong>
                    {props.usersInfo.map((item, index) => (
                      <>
                        <div className="d-flex justify-content-between mt-2 align-items-center posMember posMemberMobile">
                          <p className="w-30 w-m mb-0 pb-2">
                            {index + 1}:{item.fname} {item.lname}
                            {item.number ? ", " : ""} {item.number}
                          </p>

                          <div className="d-flex w-30 mob-w-100">
                            <Discount
                              key={item._id}
                              data={item}
                              obj={props.obj}
                              allData={props}
                              index={index}
                              handleDiscountChange={handleDiscountChange1}
                            />
                          </div>
                          <div className="m-0 w-200 mob-w-80">
                            {item.trans &&
                              item.trans.length > 0 &&
                              new Date(item.expirydate) >= new Date() && (
                                <p
                                  onChange={(e) =>
                                    handleCheckboxChange(e, item)
                                  }
                                >
                                  <MDBCheckbox
                                    className="me-1"
                                    name="tax"
                                    checked={
                                      new Date(item.expirydate) >= new Date()
                                        ? selectedOptions.includes(item._id)
                                        : false
                                    }
                                    inline
                                    label="Start Simultaneously"
                                  />
                                </p>
                              )}
                          </div>

                          <button
                            type="button"
                            className="px-2 fs-6 deleteIcon mob-w-10 button-link"
                            onClick={(e) =>
                              handleDeleteMember(e, item, props.item._id)
                            }
                          >
                            <MDBIcon fas icon="trash" color="##636363" />
                          </button>
                        </div>
                      </>
                    ))}
                  </>
                )}
              </div>
            </MDBCardText>
          </MDBCardBody>
        </MDBCard>
      </div>

      {displayMembers && (
        <Popup
          title={
            <div className="d-flex">
              Add Member(s)
              {/* {selmember.map(member => (
                <MemberDetails key={member._id} member={member} />
              ))} */}
            </div>}
          ptype="modal-lg"
          content={
            <>
              <div>
                <AddMembers
                  id={getToken()}
                  itemid={props.item._id}
                  onAdd={addSelectedMembers}
                  showcheck={showAssociateCheck}
                  alreadyadded={alreadysel}
                  selectedMember={selectedMember}



                />
              </div>
            </>
          }
          handleClose={closeMemPopup}
        />
      )}
    </>
  );
};

export default PATemplate;
