const PRIMARY_SERVER_URL = process.env.REACT_APP_PRIMARY_SERVER_URL
const SECONDARY_SERVER_URL = process.env.REACT_APP_SECONDARY_SERVER_URL
let activeServerURL = PRIMARY_SERVER_URL


const token = localStorage.getItem("token");

/************************************************************************/
async function connectToAPI(tcontextURL, tmethod, data) {
  try {
    const response = await fetch(activeServerURL + tcontextURL, {
      method: tmethod ? tmethod : "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token == null ? localStorage.getItem("token") : token}`,
      },
      body: JSON.stringify(data),
    });

    console.log(response.status);
    if (response.status === 401) {
      localStorage.setItem("SError", "Session Timed out or Unauthorized Access");
      localStorage.removeItem("token");
      localStorage.removeItem("loggedUserInfo");
      window.location.href = "/login";
      return;
    }
    return response;
  } catch (error) {
    console.error('Error connecting to API:', error);
    if (activeServerURL === PRIMARY_SERVER_URL) {
      activeServerURL = SECONDARY_SERVER_URL;
      return connectToAPI(tcontextURL, tmethod, data);
    } else {
      console.error('Both primary and backup servers are down.');
      activeServerURL = PRIMARY_SERVER_URL;
      return "false";
    }
  }
}

/************************************************************************/

export async function loginUser(credentials) {
  console.log(credentials);
  const response = await fetch(activeServerURL + "login", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(credentials),
  });
  console.log("Checking login API response");

  return response;
}
/**************BusinesInfo View ***************/
export async function getGBussinessInfo(bId) {
  return await connectToAPI(`businessinfo/${bId}`);
}
export async function logOutUser(bId) {
  return await connectToAPI(`login/logout/${bId}`, "GET");
}
export async function saveGBussinessInfo(updates, bId) {
  return await connectToAPI(`businessinfo/${bId}`, "PUT", updates);
}
export async function GetBussinessInfo(bId) {
  return await connectToAPI(`businessinfo/accounts/${bId}`);
}
export async function GetBussinessEvents(bId) {
  return await connectToAPI(`businessinfo/accountevents/${bId}`);
}
export async function endBussinessSession(bId, date) {
  return await connectToAPI(`businessinfo/updateaccountevents/${bId}`, "PUT", date);
}

export async function createGBussinessInfo(updates) {
  return await connectToAPI(`businessinfo/create`, "PUT", updates);
}
export async function createWaiver(updates) {
  return await connectToAPI(`waiver/waiver/0`, "POST", updates);
}
export async function getWaiver(id) {
  return await connectToAPI(`waiver/waiver/${id}`);
}
export async function getWaiverSettings(uid) {
  return await connectToAPI(`waiver/Wsetting/${uid}`);
}
export async function updateWaiverSettings(updates, bId) {
  return await connectToAPI(`waiver/Wsetting/${bId}`, "POST", updates);
}
export async function getWaiverSettingsbyGymid(uid) {
  return await connectToAPI(`waiver/Wsetting/gymid/${uid}`);
}
export async function getCheckInfo(bId) {
  return await connectToAPI(`gr/userplans/${bId}`);
}
export async function getGymPlansList(bId) {
  return await connectToAPI(`plans/${bId}`);
}
export async function getGymAddonsList(bId) {
  return await connectToAPI(`addons/${bId}`);
}
export async function saveUser(updates, bId) {
  return await connectToAPI(`members/${bId}`, "POST", updates);
}
export async function saveStaff(updates, bId) {
  return await connectToAPI(`staff/${bId}`, "POST", updates);
}
export async function deleteStaff(bId) {
  return await connectToAPI(`staff/${bId}`, "DELETE");
}
export async function createStaff(updates, bId) {
  return await connectToAPI(`staff/${bId}`, "POST", updates);
}
export async function saveInfo(updates, bId) {
  return await connectToAPI(`businessinfo/${bId}`, "PUT", updates);
}

export async function saveTransaction(updates, bId) {
  return await connectToAPI(`transaction/${bId}`, "PUT", updates);
}

export async function getMembersInfo(bId) {
  return await connectToAPI(`gr/userplans/${bId}`);
}

export async function getMemeberProfile(mId) {
  return await connectToAPI(`members/profile/${mId}`);
}
export async function updateMemberDNDvalue(mId, value) {

  return await connectToAPI(`members/updatednd/${mId}`, "POST", value);
}

/************************************ */
export async function getStaffInfo(bId) {
  return await connectToAPI(`staff/${bId}`);
}
export async function getStaffProfile(sId) {
  return await connectToAPI(`staff/profile/${sId}`);
}
/***************************** */
export async function getAdminById(uId) {
  return await connectToAPI(`staff/profile/${uId}`);
}
export async function saveAdmin(updates, bId) {
  return await connectToAPI(`staff/${bId}`, "PUT", updates);
}
/******************************************** */

export async function logEvent(data, gymId) {
  return await connectToAPI(`logEvent/${gymId}`, "POST", data);
}
export async function getLogEventFilter(data, gymId) {
  return await connectToAPI(`logEvent/filter/${gymId}`, "POST", data);
}
export async function getlogEvent(gymId) {
  return await connectToAPI(`logEvent/${gymId}`);
}
/******************************************** */
export async function CreateAPIsms(data, gymId) {
  return await connectToAPI(`smstemplete/apisms/${gymId}`, "POST", data);
}
export async function DeleteAPIsms(gymId) {
  return await connectToAPI(`smstemplete/apisms/${gymId}`, "DELETE");
}
export async function GetAPIsms(gymId) {
  return await connectToAPI(`smstemplete/apisms/${gymId}`);
}
export async function updateSMSTemp(data, gymId) {
  return await connectToAPI(`smstemplete/${gymId}`, "PUT", data);
}
export async function createSMSTemp(data, gymId) {
  return await connectToAPI(`smstemplete/${gymId}`, "POST", data);
}
export async function resetTemplete(data, gymId) {
  return await connectToAPI(`smstemplete/resettemplete/${gymId}`, "POST", data);
}
export async function resetGateway(gymId) {
  return await connectToAPI(`smstemplete/resetgateway/${gymId}`);
}
export async function getSMSTEMP(gymId) {
  return await connectToAPI(`smstemplete/${gymId}`);
}

export async function updateEmailTemp(data, gymId) {
  return await connectToAPI(`smstemplete/emailTemplete/${gymId}`, "PUT", data);
}
export async function getEmailTEMP(gymId) {
  return await connectToAPI(`smstemplete/emailTemplete/${gymId}`);
}
export async function uploadbanner(updates, tId) {
  return await connectToAPI(`smstemplete/uploadbanner/${tId}`, "POST", updates);
}
export async function getsmsEvents(tId, updates) {
  return await connectToAPI(`smstemplete/smsevents/${tId}`, "POST", updates);
}
export async function updatesmsEvents(tId, updates) {
  return await connectToAPI(`smstemplete/tryagain/${tId}`, "POST", updates);
}

export async function viewbanner(updates, tId) {
  return await connectToAPI(`smstemplete/viewbanner/${tId}`, "GET", updates);
}
export async function getappvariables(id) {
  return await connectToAPI(`smstemplete/appvariables/${id}`, "GET");
}
export async function updateWhatsAppTemp(data, gymId) {
  return await connectToAPI(`whatsapp/${gymId}`, "POST", data);
}
export async function createWhatsAppTemp(data, gymId) {
  return await connectToAPI(`whatsapp/${gymId}`, "POST", data);
}
export async function testwhatAppGateway(data, gymId) {
  console.log(data);
  return await connectToAPI(`whatsapp/testgatway/${gymId}`, "POST", data);
}

export async function resetWhatappTemplete(data, gymId) {
  return await connectToAPI(`whatsapp/resettemplete/${gymId}`, "POST", data);
}
export async function sendWhatsappmessage(gymId, data) {
  return await connectToAPI(`whatsapp/send/${gymId}`, "POST", data);
}
export async function getWhatsappApi(gymId) {
  return await connectToAPI(`whatsapp/whatsappapi/${gymId}`, "GET");
}
export async function CreateWhatsappAPI(data, gymId) {
  return await connectToAPI(`whatsapp/whatsappapi/${gymId}`, "POST", data);
}

export async function resetWhatsAppGateway(gymId) {
  return await connectToAPI(`whatsapp/resetapi/${gymId}`);
}
export async function DeleteWhatsappAPI(gymId) {
  return await connectToAPI(`whatsapp/whatsappapi/${gymId}`, "DELETE");
}
export async function getWhatsAppHistory(id) {
  return await connectToAPI(`whatsapp/whatsapphistory/${id}`);
}
export async function getWhatsAppCampaign(gymId, updates) {
  return await connectToAPI(`whatsapp/whatsappcampaign/${gymId}`, "POST", updates);
}

export async function getWhatsAppNotification(gymId, updates) {
  return await connectToAPI(`whatsapp/whatsappnotification/${gymId}`, "POST", updates);
}
export async function getWhatsAppMemberNotification(gymId, updates) {
  return await connectToAPI(`whatsapp/whatsappmemnotification/${gymId}`, "POST", updates);
}

export async function getWhatsAppTEMP(gymId) {
  return await connectToAPI(`whatsapp/${gymId}`);
}
/************************************************* */
export async function getSinglepageTemp(gymId) {
  return await connectToAPI(`singlepage/${gymId}`);
}
export async function updatesinglepageTemp(updates, gymId) {
  return await connectToAPI(`singlepage/${gymId}`, "POST", updates);
}

/************************************************* */
export async function getSlots(bId) {
  return await connectToAPI(`slots/${bId}`);
}
export async function saveSlot(updates, bId) {
  return await connectToAPI(`slots/${bId}`, "POST", updates);
}
export async function savePlan(updates, bId) {
  return await connectToAPI(`plans/${bId}`, "PUT", updates);
}
export async function getPlanProfile(pId) {
  return await connectToAPI(`plans/profile/${pId}`);
}
export async function getPlans(bId) {
  return await connectToAPI(`plans/${bId}`);
}

export async function getTransactions(bId, tdata) {
  return await connectToAPI(`transaction/${bId}`, "POST", tdata);
}
export async function transactionSSummary(bId, tdata) {
  return await connectToAPI(`transaction/transsummary/${bId}`, "POST", tdata);
}
export async function planSSummary(bId, tdata) {
  return await connectToAPI(`transaction/plansummary/${bId}`, "POST", tdata);
}
export async function sessionReport(bId, tdata) {
  return await connectToAPI(`members/sessionSummary/get/${bId}`, "POST", tdata);
}
export async function sessionSummary(bId, tdata) {
  return await connectToAPI(`members/sessioncount/${bId}`, "POST", tdata);
}
export async function membersessions(bId) {
  return await connectToAPI(`members/sessionProfile/${bId}`, "GET");
}

export async function clearsessions(bId) {
  return await connectToAPI(`members/clearsession/${bId}`, "POST");
}
export async function allsessions(bId) {
  return await connectToAPI(`members/allsession/${bId}`, "GET");
}
export async function deleteTransactions(bId, tdata) {
  return await connectToAPI(`transaction/delete/${bId}`, "POST", tdata);
}
export async function editTransactions(bId, tdata) {
  return await connectToAPI(`transaction/edit/${bId}`, "POST", tdata);
}
export async function getMembers(bId) {
  return await connectToAPI(`members/planassigned/${bId}`);
}

export async function getMembersLazy(bId, page, searchQuery) {
  return await connectToAPI(`members/planassigned/${bId}?page=${page}&search=${searchQuery}`);
}
export async function getFormMembersLazy(bId, update) {
  return await connectToAPI(`members/planassignedform/${bId}`, "POST", update);
}
export async function getTokenCount(bId) {
  return await connectToAPI(`transaction/tokencount/${bId}`);
}
export async function getMemberTransactions(mId) {
  return await connectToAPI(`members/transactions/${mId}`);
}
export async function getUniqueMembers(bId, tdata) {
  return await connectToAPI(`members/unique/${bId}`, "POST", tdata);
}
export async function getAllMembers(bId) {
  return await connectToAPI(`members/get/${bId}`);
}
export async function getCheckStaff(bId) {
  return await connectToAPI(`staff/check/get/${bId}`);
}
export async function getCheckMembers(bId, data) {
  return await connectToAPI(`members/check/get/${bId}`, "POST", data);
}
export async function getCheckMembers1(bId, data) {
  return await connectToAPI(`members/checkreport/get/${bId}`, "POST", data);
}
export async function getCheckInReportCount(bId, data) {
  return await connectToAPI(`members/checkincount/${bId}`, "POST", data);
}
export async function getTrainerSummary(bId, data) {
  return await connectToAPI(`members/TrainerSummary/get/${bId}`, "POST", data);
}
export async function getNewCheckMembers(bId, data) {
  return await connectToAPI(`members/check/get1/${bId}`, "POST", data);
}
export async function getRevenueReports(bId, data) {
  return await connectToAPI(`members/revenuereport/${bId}`, "POST", data);
}
export async function getGroupData(bId, type) {
  return await connectToAPI(`members/group/${bId}?type=${type}`, "GET");
}
export async function updateGroupData(bId, data) {
  return await connectToAPI(`members/group/${bId}`, "POST", data);
}
export async function deleteGroupData(bId, type) {
  return await connectToAPI(`members/group/${bId}?type=${type}`, "DELETE");
}


export async function checkStaff(checkinoutflag, mId) {
  return await connectToAPI(`staff/check/${mId}`, "PUT", checkinoutflag);
}
export async function checkMembers(checkinoutflag, mId) {
  return await connectToAPI(`members/check/${mId}`, "PUT", checkinoutflag);
}
export async function deleteCheckin(mId) {
  return await connectToAPI(`members/check/${mId}`, "DELETE");
}
export async function saveAddon(updates, bId) {
  return await connectToAPI(`addons/${bId}`, "POST", updates);
}
/************************* */
export async function createDiscount(updates, bId) {
  return await connectToAPI(`discount/${bId}`, "POST", updates);
}
export async function updateDiscount(updates, bId) {
  return await connectToAPI(`discount/${bId}`, "PUT", updates);
}
export async function getDiscount(bId) {
  return await connectToAPI(`discount/${bId}`);
}
export async function deleteDiscount(bId) {
  return await connectToAPI(`discount/${bId}`, "DELETE");
}
/*****************************/


export async function deleteMember(mId) {
  return await connectToAPI(`members/${mId}`, "DELETE");
}
export async function getTransaction(tId, bid) {
  return await connectToAPI(`transaction/profile/${tId}?gymid=${bid}`);
}
export async function refundTransaction(updates, tId) {
  return await connectToAPI(`transaction/profile/${tId}`, "PUT", updates);
}
export async function dueTransaction(tId) {
  return await connectToAPI(`transaction/due/${tId}`);
}
export async function getAddonByPId(aId) {
  return await connectToAPI(`addons/profile/${aId}`);
}
export async function getSlotByPId(aId) {
  return connectToAPI(`slots/profile/${aId}`);
}
export async function deleteAddon(aId) {
  return await connectToAPI(`addons/${aId}`, "DELETE");
}
export async function deletePlan(pId) {
  return await connectToAPI(`plans/${pId}`, "DELETE");
}
export async function getAttendance(id, tdata) {
  return await connectToAPI(`gr/attendance/${id}`, null, tdata);
}
export async function getAttendanceList(id, tdata) {
  return await connectToAPI(`gr/attendance/${id}`, "POST", tdata);
}
export async function deleteSlot(sId) {
  return await connectToAPI(`slots/${sId}`, "DELETE");
}
export async function getDashboardData(bId, tdata) {
  return await connectToAPI(`gr/dashboard/${bId}`, "POST", tdata);
}
//****Dashboard widgets*******05122023 Chinna ********/
export async function DBStaffattendance(bId, tdata) {
  return await connectToAPI(`gr/dashboard/staff/${bId}`, "POST", tdata);
}

export async function DBMembershipRenewal(bId, tdata) {
  return await connectToAPI(`gr/dashboard/memRenew/${bId}`, "POST", tdata);
}

export async function DBCheckins(bId, tdata) {
  return await connectToAPI(`gr/dashboard/checkin/${bId}`, "POST", tdata);
}
export async function DBActivMembers(bId, tdata) {
  return await connectToAPI(`gr/dashboard/activeMem/${bId}`, "POST", tdata);
}
export async function DBPayment(bId, tdata) {
  return await connectToAPI(`gr/dashboard/payment/${bId}`, "POST", tdata);
}
export async function DBExpirymemArray(bId) {
  return await connectToAPI(`gr/dashboard/expirymember/${bId}`, "GET");
}
export async function memberTransactions(tId, updates) {
  return await connectToAPI(`transaction/mtrans/${tId}`, "POST", updates);
}
export async function getActiveMembers(bId, data) {
  return await connectToAPI(`members/active/get/${bId}`, "POST", data);
}
export async function verifyEmail(mailID) {
  return await connectToAPI(`staff/verifyemail/${mailID}`);
}
export async function sendVEmail(mailID) {
  return await connectToAPI(`gr/mails/resendverify/${mailID}`);
}
export async function contactus(id, data) {
  return await connectToAPI(`gr/contactus/mail/${id}`, "POST", data);
}
export async function getGAEventTracker() {
  return await connectToAPI(`gr/gaeventtracker`);
}
export async function numberSignUp(updates) {
  return await connectToAPI(`businessinfo/phonenumber`, "POST", updates);
}

export async function emailVerify(updates) {
  return await connectToAPI(`businessinfo/emailotpverify`, "POST", updates);
}
export async function numbermessage(updates) {
  return await connectToAPI(`businessinfo/urlnumber`, "POST", updates);
}

export async function numpaymentalertmessage(updates) {
  return await connectToAPI(`businessinfo/paymentalert`, "POST", updates);
}

export async function uploadlogo(formData, tId) {

  return await connectToAPI(`businessinfo/uploadlogo/${tId}`, "POST", formData);
}
export async function viewlogo(updates, tId) {
  return await connectToAPI(`businessinfo/viewlogo/${tId}`, "Get", updates);
}
export async function pdfdownloader(updates, tId) {
  return await connectToAPI(`transaction/pdfD/${tId}`, "POST", updates);
}

export async function uploadInvoice(updates, tId) {
  return await connectToAPI(`transaction/upload/${tId}`, "POST", updates);
}

/**
 * created on [5-7-2024]
 * Uploads payment link updates for a transaction identified by tId.
 * @param {Object} updates - The updates to be uploaded.
 * @param {string} tId - The transaction ID.
 *  @description:-this api is for uploading invoice while paymentlink method
 * @returns {Promise} A promise that resolves when the upload is complete.
 */
export async function uploadInvoicePaymentlink(updates, tId) {
  return await connectToAPI(`transaction/uploadpayment/${tId}`, "POST", updates);
}

export async function downloadInvoice(updates, tId) {
  return await connectToAPI(`transaction/download/${tId}`, "GET", updates);
}
/**
 * created on [5-7-2024]
 * @param {Object} updates - The updates send as request to function to get html content.
 *  @description:-getInvoicehtmlcontent api for geting html content for invoice.
 * @returns {Promise} A promise that resolves when the htmlcontent received as response.
 */
export async function getInvoicehtmlcontent(updates, tId) {
  return await connectToAPI(`transaction/invoicecontent/${tId}`, "POST", updates);
}
export async function membertable(id, updates) {
  return await connectToAPI(`members/allmembers/${id}`, "POST", updates);
}
export async function memberActiveTransactions(id) {
  return await connectToAPI(`members/activetransactions/${id}`);
}

export async function sendReceiptMail(bId, emailid, reciptLink, logo) {
  const emailList = emailid.join(',');
  console.log(emailList, "99999999999", bId, emailid, reciptLink, logo);
  // console.log(typeof content);
  const updates = {
    link: reciptLink,
    mail: emailList,
    logo: logo,

  }
  return await connectToAPI(`gr/mails/receiptmail/${bId}`, "POST", updates);
}
export async function pauseMemberShip(bId, data) {
  return await connectToAPI(`members/pause/${bId}`, "POST", data);
}
export async function extendMemberShip(bId, data) {
  return await connectToAPI(`members/extend/${bId}`, "POST", data);
}
export async function resumeMemberShip(bId, data) {
  return await connectToAPI(`members/resume/${bId}`, "POST", data);
}
export async function suspendMemberShip(bid) {
  return connectToAPI(`members/suspend/${bid}`);
}
export async function activateMemberShip(bid) {
  return connectToAPI(`members/suspendActivate/${bid}`);
}

export async function getLocation(bId) {
  return await connectToAPI(`geolocation/${bId}`);
}
export async function updateLocation(bId, updates) {
  return await connectToAPI(`geolocation/${bId}`, "POST", updates);
}

export async function updatePaymentLinkSetting(bId, updates) {
  return await connectToAPI(`paymentlink/paymentlinksetting/${bId}`, "POST", updates);
}
export async function getPaymentLinkSetting(bId) {
  return await connectToAPI(`paymentlink/paymentlinksetting/${bId}`);
}
export async function confirmPaymentLink(bId, updates) {
  return await connectToAPI(`paymentlink/generatepaymentlink/${bId}`, "POST", updates);
}
export async function sendPaymentLink(bId, updates) {
  return await connectToAPI(`paymentlink/sendpaymentlink/${bId}`, "POST", updates);
}
export async function checkPaymentStatus(bId) {
  return await connectToAPI(`paymentlink/checkpaymentstatus/${bId}`);
}
export async function reqPaymentStatus(bId) {
  return await connectToAPI(`paymentlink/requestpaymentstatus/${bId}`);
}
export async function getPaymentUrl(uid) {
  return await connectToAPI(`paymentlink/url/${uid}`);
}
export async function getCompressPaymentLink(uid) {
  return await connectToAPI(`paymentlink/uniqueid/${uid}`);
}
export async function reqConfirmPaymentStatus(uid) {
  return await connectToAPI(`paymentlink/requestconfirmpaymentstatus/${uid}`);
}
export async function getAllPaymentTransactions(uid) {
  return await connectToAPI(`paymentlink/alltransactions/${uid}`);
}
export async function deletePaymentLinkTrans(uid) {
  return await connectToAPI(`paymentlink/delete/${uid}`);
}
//Reset Password api
export async function resetPassword(updates) {
  return await connectToAPI(`staff/login/getresetPassword`, "POST", updates);

}
//confirm password api
export async function confirmPassword(updates, id) {
  return await connectToAPI('staff/login/restpassword', "POST", updates)
}
export async function getActiveMembersCount(bId, updates) {
  return await connectToAPI(`plans/activereport/${bId}`, "POST", updates);
}
export async function getActiveMembersCountByRow(bId, updates) {
  return await connectToAPI(`plans/activereportrowdata/${bId}`, "POST", updates);
}
export async function checkEmail(bId, updates) {
  return await connectToAPI(`staff/emailCheck/${bId}`, "POST", updates);
}
// pricing plans 
export async function getPricingPlansById(uid) {
  return await connectToAPI(`pricingplan/planbyid/${uid}`);
}
export async function getAllPricingPlans() {
  return await connectToAPI(`pricingplan/allplans`);
}
// subscriptions
export async function createSubscriptionPlan(uid, updates) {
  return await connectToAPI(`subscription/create/${uid}`, "POST", updates);
}
export async function getSubscriptionPlans(bid,) {
  return await connectToAPI(`subscription/getsubinfo/${bid}`);
}
export async function cancelSubscriptionPlan(bid, updates) {
  return await connectToAPI(`subscription/cancel/${bid}`, "POST", updates);
}
export async function checkrecoveryIdCheck(recoveryId) {
  return await connectToAPI(`staff/password/recoveryIdCheck`, "POST", recoveryId)
}

//generateNewPassword
export async function generateNewPassword(updates) {
  return await connectToAPI(`staff/password/generatepassword`, "POST", updates);
}
export async function customPremium(updates) {
  return await connectToAPI(`gr/mails/contactmail`, "POST", updates);
}

//multigym
export async function creategyms(updates) {
  return await connectToAPI('businessinfo/creategyms', "POST", updates)
}


//getexistgym
export async function getexistgym(updates) {
  return await connectToAPI('businessinfo/getexistgym', "POST", updates)
}


export async function verifymailotp(updates) {
  return await connectToAPI('businessinfo/verifymailotp', "POST", updates)
}



//getmultigyms
export async function getmultigyms(updates) {
  return await connectToAPI('businessinfo/getmultigyms', "POST", updates)
}

//deleteAddGym
export async function deleteAddGym(id) {
  return await connectToAPI("businessinfo/deleteAddGym", "POST", id)
}

//getEnvFileData

export async function getEnvFileData() {
  return await connectToAPI("businessinfo/env-file/data")
}


//getBusinessName

export async function getBusinessName(updates) {
  return await connectToAPI("members/getBusinessName/getBusinessName", "POST", updates)
}




// /***************segment api's********** */
// export async function createSegment(updates, bId) {
//   return await connectToAPI(`segment/${bId}`, "POST", updates);
// }
// export async function updateSegment(updates, bId) {
//   return await connectToAPI(`segment/${bId}`, "PUT", updates);
// }
// export async function getSegment(bId) {
//   return await connectToAPI(`segment/${bId}`);
// }
// export async function deleteSegment(bId) {
//   return await connectToAPI(`segment/${bId}`, "DELETE");
// }
// /*****************************/



//sendCheckInQrCode

export async function sendCheckInQrCode(bid, updates) {
  return await connectToAPI(`members/sendCheckInQrCode/${bid}`, "POST", updates)
}