import React, { useEffect, useState } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import { Col, Container, Card, Row, CardBody } from "reactstrap";
import { MDBCard, MDBCardBody, MDBCardTitle, MDBCol, MDBRow } from "mdb-react-ui-kit";
import PhoneInput, { isValidPhoneNumber } from 'react-phone-number-input'
import { getAllPricingPlans, numberSignUp } from "../../../services/apiServices";
import { useNavigate, useLocation } from "react-router";
import { isEmail, isEmpty } from "../../../validators/helper.js";

function Leadsignup() {
    const [selectedTerm, setSelectedTerm] = useState("1");
    const [showPlans, setShowPlans] = useState(false);
    const [otp, setOtp] = useState("");
    const [notp, setNotp] = useState("");
    const [error, setError] = useState("");
    const [verror, setVerror] = useState({})
    const [phnumber, setPhnumber] = useState("");
    const [userExists, setUserExists] = useState(false);
    const [captchaValue, setCaptchaValue] = useState(false);
    const navigate = useNavigate();
    const [plansinfo, setPlanInfo] = useState([])
    const [signupForm, setsignupForm] = useState({
        firstname: "",
        lastname: "",
        number: "",
        email: "",
        password: "",
        confirmp: "",
        tempcaptcha: "",
    });
    const [verify, setVerify] = useState(false)
    const [planid, setPlanid] = useState("")
    const [selectplan, setSelectplan] = useState("")
    const handleTermSelection = (term) => {
        setSelectedTerm(term);
        setShowPlans(term === "6" || term === "12");
        if (term == "1") {
            const filterPlan = plansinfo && plansinfo.length > 0 ? plansinfo.filter((data) => data.name === "Free Starter")[0]._id : ""
            console.log(filterPlan);

            setPlanid(filterPlan)
            setSelectplan(term == "1" ? "Free Starter" : "")
        }

    };

    console.log(error);

    const handlePlanSelection = (term) => {
        console.log(term);
        setSelectplan(term)
        const filterPlan = plansinfo && plansinfo.length > 0 ? plansinfo.filter((data) => data.name === term)[0]._id : ""
        console.log(filterPlan);

        setPlanid(filterPlan)
    };
    useEffect(() => {
        fetchData()
    }, [])

    const fetchData = async () => {
        const response = await getAllPricingPlans();
        if (!response || response === "false") {
            return;
        }
        const recinfo = await response.json();
        console.log(recinfo);

        if (!recinfo) {
            return;
        }
        if (recinfo === "false") {
            return;
        }
        setPlanInfo(recinfo.pricingPlans);
        const filterPlan = recinfo.pricingPlans && recinfo.pricingPlans.length > 0 ? recinfo.pricingPlans.find(item => item.name === "Free Starter")._id : ""

        setPlanid(filterPlan)
        setSelectplan("Free Starter")
    };

    const finalamount = () => {
        const filterPlan = plansinfo && plansinfo.length > 0 ? plansinfo.find(item => item.name === selectplan) : {};
        console.log(filterPlan);

        return selectedTerm == "12" ? filterPlan.priceannual && Number(filterPlan.priceannual.fpricemontly) * 12 : filterPlan.pricemonthly && Number(filterPlan.pricemonthly * 6)
    }

    const validation = async (e) => {
        e.preventDefault();
        console.log(phnumber);
        console.log(otp, notp);

        if (otp !== notp) {
            setError("Invalid OTP.Please try again.");
        }
        else {
            setError("");
            setVerify(true)
            localStorage.setItem("number", JSON.stringify(phnumber));
            // if (planId) {
            // navigate(`/billingsubscriptionplan?id=${planId}&value=${planTimePeriod}`);
            // } else {
            // navigate(`/signup`);
            // }
        }

    }

    const otpfield = async (e) => {
        e.preventDefault();
        if (!isValidPhoneNumber(phnumber)) {
            setError("Please enter valid contact number!");
        }
        else {
            const response = await numberSignUp({ phone: phnumber });
            let resData = await response.json();
            // console.log(resData);
            if (resData.status !== 200) {
                setError(resData.status === 400 ? "Please try again." : "Number already exits in the system!")
            }
            else {
                setError("OTP Sent Sucessfully");
                setUserExists(true);
                setNotp(resData.otp);
                setsignupForm({
                    ...signupForm,
                    number: phnumber
                })
            }


        }
    }


    function updateLoginUser(value) {
        // e.preventDefault();
        setPhnumber(value);
        setOtp("");
        setUserExists(false);
        setError("");
    }

    function updateForm(value) {
        return setsignupForm((prev) => {
            return { ...prev, ...value };
        });
    }

    let validateSForm = (e) => {
        e.stopPropagation();
        console.log("Validating form...");
        let errors = {};
        // console.log(signupForm.name);
        if (isEmpty(signupForm.firstname)) {
            errors.name = "Name can't be blank";
        }
        if (isEmpty(signupForm.lastname)) {
            errors.name = "Name can't be blank";
        }
        if (isEmpty(signupForm.email)) {
            errors.email = "Email can't be blank";
        } else if (!isEmail(signupForm.email)) {
            errors.email = "Please enter valid email";
        } else if (!captchaValue) {
            errors.tempcaptcha = "Please check the captcha"
        }
        // }
        // if (!isEmpty(phnumber)) {
        //     errors.number = "Please Click Verify";
        // } else {
        if (isEmpty(signupForm.number)) {
            errors.number = "Field can't be empty.";
        } else if (!isEmpty(signupForm.number) && !isValidPhoneNumber(signupForm.number)) {
            errors.number = "Please enter valid Phone Number";
        }
        // }

        if (isEmpty(errors)) {
            return true;
        } else {
            return errors;
        }
    };

    const submitHandler = async (e) => {
        e.preventDefault();
        e.stopPropagation();

        const updates = {
            firstname: signupForm.firstname,
            lastname: signupForm.lastname,
            email: signupForm.email,
            number: signupForm.number,
            planid: planid
        };
        let noerrors = validateSForm(e);
        console.log(noerrors);
        if (noerrors === true) {
            console.log(updates, '00000000000');

            localStorage.setItem("landingpageinfo", JSON.stringify(updates));
            if (planid !== "") {
                const value = (selectedTerm == "12" ? 1 : 0)
                navigate(`/billingsubscriptionplan?id=${planid}&value=${value}`);
            }
            //   let saverep = await createGBussinessInfo(updates);
            //   if (saverep === "false") {
            //     setApiError(true)
            //     return;
            //   }
            //   let jsOBj = await saverep.json();

            //   if (saverep.status !== 200) {
            //     console.log("Email already exits in the system!");
            //   }
            //   else {
            //     console.log(jsOBj);
            //     console.log(jsOBj.token);
            //     setToken(jsOBj.token);
            //     await setUserSession(jsOBj.userInfo);
            //     navigate("/dashboard");

            //   }
        } else {
            setVerror(noerrors)
        }
    };


    function verifyCaptchaCallback(resp) {
        console.log("Respons for captcha");
        console.log(resp);
        if (!resp) {
            setCaptchaValue(false);
        }
        else {
            console.log("true");
            setCaptchaValue(true);
        }
    }
    return (
        <>
            <MDBRow className="d-flex align-items-start">

                {/* Content Card */}
                <MDBCol sm={12} lg={6} md={6} className="content-card">
                    <div className="title-heading">
                        <p className="para-desc text-muted mt-5 text-large">
                            Announcing the launch of <b>EasyMembr</b>, our all-in-one gym management solution. To celebrate, we’re offering an exclusive launch discount:
                        </p>
                        <ul className="para-desc text-muted text-large">
                            <li><b>10% OFF</b> on all plans</li>
                            <li><b>1 extra month free</b></li>
                        </ul>
                        {/* <p className="para-desc text-muted text-large">
                            Simply use the coupon code <b>"NEWYEAR"</b> in the next screen when signing up before <b>31st December</b> to take advantage of this offer.
                        </p> */}
                        {selectedTerm == "1" && (
                            <p className="para-desc text-muted text-large">You pay <b>INR 0</b> now to activate the trial account for <b>30 days</b>. <b>No Credit Card</b> required</p>
                        )}
                    </div>
                </MDBCol>

                <MDBCol sm={12} lg={6} md={6} className="mt-4 pt-2 mt-sm-0 pt-sm-0">
                    <div>
                        <MDBCard className="border w-100">
                            <MDBCardBody>
                                {/* <MDBCardTitle>Sign Up</MDBCardTitle> */}
                                <div className="title-heading my-lg-auto">
                                    <div className="border-0" style={{ zIndex: 1 }}>
                                        <CardBody className="p-0">
                                            <form className="login-form mt-4" onSubmit={submitHandler}>
                                                <Row>


                                                    {/* First Name */}
                                                    <Col md={6}>
                                                        <div className="mb-3">
                                                            <label className="form-label">
                                                                First Name<span className="text-danger">*</span>
                                                            </label>
                                                            <input
                                                                type="text"
                                                                className="form-control"
                                                                placeholder="First Name"
                                                                onChange={(e) => updateForm({ firstname: e.target.value })} value={signupForm.firstname}
                                                            />
                                                        </div>
                                                    </Col>


                                                    {/* Last Name */}
                                                    <Col md={6}>
                                                        <div className="mb-3">
                                                            <label className="form-label">
                                                                Last Name<span className="text-danger">*</span>
                                                            </label>
                                                            <input
                                                                type="text"
                                                                className="form-control"
                                                                placeholder="Last Name"
                                                                onChange={(e) => updateForm({ lastname: e.target.value })} value={signupForm.lastname}
                                                            />
                                                        </div>
                                                    </Col>

                                                    {verror.name && (
                                                        <>
                                                            <p className="clr-red">{verror.name}</p>
                                                        </>
                                                    )}

                                                    {/* Email */}
                                                    <Col md={12}>
                                                        <div className="mb-3">
                                                            <label className="form-label">
                                                                Your Email<span className="text-danger">*</span>
                                                            </label>
                                                            <input
                                                                type="email"
                                                                className="form-control"
                                                                placeholder="Email"
                                                                onChange={(e) => updateForm({ email: e.target.value })} value={signupForm.email}
                                                            />
                                                        </div>
                                                    </Col>
                                                    {verror.email && (
                                                        <>
                                                            <p className="clr-red">{verror.email}</p>
                                                        </>
                                                    )}
                                                    {/* ReCAPTCHA */}
                                                    <Col md={12} className="mt-3">
                                                        <div className="mb-3">
                                                            <ReCAPTCHA
                                                                className="mt-3 w-100 recapthca"
                                                                sitekey={"6LfQZGglAAAAAPPUCjiIsnyCvmGisjTTowbcFP3_"}
                                                                onChange={
                                                                    // (e) =>
                                                                    verifyCaptchaCallback
                                                                    // ({ captcha: e.target.value })
                                                                }
                                                            />
                                                        </div>
                                                    </Col>
                                                    {verror.tempcaptcha && (
                                                        <>
                                                            <p className="clr-red">{verror.tempcaptcha}</p>
                                                        </>
                                                    )}

                                                    {/* Mobile Number */}
                                                    {captchaValue && <div className="mb-3">
                                                        <label className="form-label">Your Mobile No.<span className="text-danger">*</span></label>
                                                        <div className="d-flex">
                                                            <PhoneInput
                                                                className="form-control"
                                                                defaultCountry="IN"
                                                                placeholder="Mobile Number"
                                                                value={phnumber}
                                                                disabled={userExists}
                                                                onInput={(e) => e.target.value = e.target.value.slice(0, 15)}
                                                                onChange={(value) => updateLoginUser(value)}
                                                            />
                                                            {!verify && <button className="btn-primary" onClick={(e) => userExists === true ? validation(e) : otpfield(e)}>{userExists ? "Verify" : "Get OTP"}</button>}
                                                        </div>
                                                    </div>

                                                    }
                                                    {verror.number && (
                                                        <>
                                                            <p className="clr-red">{verror.number}</p>
                                                        </>
                                                    )}
                                                    <div style={(error === "OTP Sent Sucessfully") ? { color: "green" } : { color: "red" }}>{error}</div>

                                                    {userExists && (
                                                        <div className="mb-3">
                                                            <label className="form-label">Enter OTP<span className="text-danger">*</span></label>
                                                            <input type="text"
                                                                className="form-control" placeholder="Enter OTP"
                                                                value={otp}
                                                                onChange={(e) => setOtp(e.target.value)}
                                                                required />
                                                        </div>
                                                    )}

                                                    {/* Terms Selection */}
                                                    <Col md={12} className="mt-3">
                                                        <label className="form-label">
                                                            Choose your term<span className="text-danger">*</span>
                                                        </label>
                                                        <div className="d-flex gap-2">
                                                            <button
                                                                type="button"
                                                                className={`btn ${selectedTerm === "1" ? "btn-primary" : "btn-outline-primary"
                                                                    }`}
                                                                onClick={() => handleTermSelection("1")}
                                                            >
                                                                1 Month Free
                                                            </button>
                                                            <button
                                                                type="button"
                                                                className={`btn ${selectedTerm === "6" ? "btn-primary" : "btn-outline-primary"
                                                                    }`}
                                                                onClick={() => handleTermSelection("6")}
                                                            >
                                                                6 Months
                                                            </button>
                                                            <button
                                                                type="button"
                                                                className={`btn ${selectedTerm === "12" ? "btn-primary" : "btn-outline-primary"
                                                                    }`}
                                                                onClick={() => handleTermSelection("12")}
                                                            >
                                                                12 Months
                                                            </button>
                                                        </div>
                                                    </Col>

                                                    {/* Plans Selection */}
                                                    {showPlans && (
                                                        <Col md={12} className="mt-3">
                                                            <label className="form-label">
                                                                Choose your plan<span className="text-danger">*</span>
                                                            </label>
                                                            <div className="d-flex gap-2">
                                                                <button type="button" className={`btn ${selectplan == "Lean" ? "btn-primary" : "btn-outline-primary"}`}
                                                                    onClick={() => handlePlanSelection("Lean")}
                                                                >
                                                                    Lean Plan
                                                                </button>
                                                                <button type="button" className={`btn ${selectplan == "Pro" ? "btn-primary" : "btn-outline-primary"}`}

                                                                    onClick={() => handlePlanSelection("Pro")}>
                                                                    Pro Plan
                                                                </button>
                                                                <button type="button" className={`btn ${selectplan == "Premium" ? "btn-primary" : "btn-outline-primary"}`}
                                                                    onClick={() => handlePlanSelection("Premium")}>
                                                                    Premium Plan
                                                                </button>
                                                            </div>
                                                        </Col>
                                                    )}


                                                    <span className="mt-2">Your final price exclusive of taxes is <span style={{ color: "green" }}>&#8377;{finalamount()}</span></span>

                                                    {/* Submit Button */}
                                                    <Col md={12}>
                                                        <div className="d-grid mt-4">
                                                            <button type="submit" className="btn btn-primary" disabled={phnumber != "" && !verify ? true : false}>
                                                                Signup
                                                            </button>
                                                        </div>
                                                    </Col>
                                                </Row>
                                            </form>
                                        </CardBody>
                                    </div>
                                </div>
                            </MDBCardBody>
                        </MDBCard>
                    </div>
                </MDBCol>
            </MDBRow>
        </>

    );
}

export default Leadsignup;
