import React, { useEffect, useRef, useState, forwardRef } from "react";
import PageError from "./PageError.js";
import { MDBBtn, MDBDatatable, MDBIcon, MDBModal, MDBModalBody, MDBModalContent, MDBModalDialog, MDBModalHeader, MDBModalTitle } from "mdb-react-ui-kit";
import { getWhatsAppCampaign } from "../services/apiServices.js";
import DatePicker from 'react-datepicker';
import calImg from "./../components/images/calendar.svg";
import ExcelExportButton from "../components/exportToExcel.js";
import Loader from "../components/Loader.js";

function WhatsAppCampaignReports() {
    const [whatsAppData, setwhatsAppData] = useState([])
    const [apiError, setApiError] = useState(false);
    const [DayFilter, setDayFilter] = useState("4");
    const [loading, setLoading] = useState(false)
    const [basicData, setBasicData] = useState({
        columns: [
            { label: 'S.No', field: 'sno', sort: false },
            { label: 'Campaign Name', field: 'campaignName', sort: false },
            { label: 'Sent On', field: 'sentOn', sort: false },
            { label: 'Number of Recipients', field: 'numRecipients', sort: false },
            { label: 'Template', field: 'template', sort: false },
        ],
        rows: [],
    });

    const searchRef = useRef();
    const [customdate, setcustomdate] = useState(DayFilter === "6");
    const today = new Date();
    const currentTime = new Date();
    const startOfToday = new Date(currentTime);
    startOfToday.setHours(0, 0, 0, 0);

    const endOfToday = new Date(currentTime);
    endOfToday.setHours(23, 59, 59, 999);

    const yesterday = new Date(currentTime);
    yesterday.setDate(currentTime.getDate() - 1);
    const startOfYesterday = new Date(yesterday);
    startOfYesterday.setHours(0, 0, 0, 0);

    const endOfYesterday = new Date(yesterday);
    endOfYesterday.setHours(23, 59, 59, 999);

    const startOfWeek = new Date(currentTime);
    startOfWeek.setDate(currentTime.getDate() - currentTime.getDay());
    startOfWeek.setHours(12, 0, 0, 0);
    const endOfWeek = new Date(currentTime);
    endOfWeek.setDate(startOfWeek.getDate() + 6);
    endOfWeek.setHours(12, 0, 0, 0);

    const startOfMonth = new Date(currentTime);
    startOfMonth.setDate(1);
    startOfMonth.setHours(0, 0, 0, 0);

    const endOfMonth = new Date(currentTime);

    const startOfYear = new Date(currentTime);
    startOfYear.setMonth(0, 1);
    startOfYear.setHours(0, 0, 0, 0);

    const endOfYear = new Date(currentTime);
    endOfYear.setMonth(11, 31);
    endOfYear.setHours(23, 59, 59, 999);
    today.setHours(23, 59, 0, 0);
    const [dateRange, setDateRange] = useState([startOfMonth, endOfMonth]);
    const [startDate, endDate] = dateRange;
    const [applyFilterClicked, setApplyFilterClicked] = useState(false)
    const [resetFilterClicked, setResetFilterClicked] = useState(false)
    const [mfilter, setMfilter] = useState({
        tidName: "",
        tempNme: [],
        mType: "",
    })
    const [selectedRowData, setSelectedRowData] = useState(null);
    const CalenderInput = forwardRef(({ value, onClick }, ref) => (
        <>
            <span className="gym-input forminput h-40">
                {value === "" ? "Custom Range" : value}
                &nbsp;
                <img
                    src={calImg}
                    onClick={onClick}
                    ref={ref}
                    alt="custom Range"
                    title="Select Custom Range"
                />
            </span>
        </>
    ));

    const getDates = (obj) => {
        setDateRange(obj);
    };



    /**
* handleApplyAndToggle function is used for applying filters .
* created by vamshi
* @function
* 
* @description
* This handleApplyAndToggle function has been create on [17-7-2024] used to set the filters.
*/
    const handleApplyAndToggle = () => {
        const sDate = DayFilter === "1" ? startOfToday : (DayFilter === "2" ? startOfYesterday : (DayFilter === "3" ? startOfWeek : (DayFilter === "4" ? startOfMonth : (DayFilter === "5" ? startOfYear : (DayFilter === "6" ? startDate : null)))))
        const eDate = DayFilter === "1" ? endOfToday : (DayFilter === "2" ? endOfYesterday : (DayFilter === "3" ? endOfWeek : (DayFilter === "4" ? endOfMonth : (DayFilter === "5" ? endOfYear : (DayFilter === "6" ? endDate : null)))))
        if (sDate !== null && eDate !== null) {
            setDateRange([sDate, eDate])
        } else {
            setDateRange([])
        }

        setApplyFilterClicked(true)
    }


    /**
    * handleResetAndToggle function is used to clear filters .
    * created by vamshi
    * @function
    * 
    * @description
    * This handleResetAndToggle function has been create on [17-7-2024] used for reset the filters .
    */
    const handleResetAndToggle = () => {

        setDayFilter("4")
        setDateRange([startOfMonth, endOfMonth])
        setMfilter({
            tidName: "",
            tempNme: [],
            mType: "",
        })
        setResetFilterClicked(true)

    }
    let filter = {}
    // console.log(mfilter);
    async function fetchData() {
        setLoading(true)
        let startOfDate = startDate
        let endOfDate = endDate
        let Dateobj = {}
        filter.name = mfilter.tidName
        filter.tempNme = mfilter.tempNme
        filter.mtype = mfilter.mType

        if ((startOfDate && endOfDate)) {
            const startDatef = new Date(startOfDate)
            startDatef.setHours(0, 0, 0, 0)
            const utcstartDate = startDatef.toISOString();
            let lastoftheday = new Date(endOfDate);
            lastoftheday.setHours(23, 59, 59, 999);
            const utcEndDate = lastoftheday.toISOString();
            Dateobj = {
                startDate: utcstartDate,
                endDate: utcEndDate,
            }
        }
        filter.dateobj = Dateobj
        const response = await getWhatsAppCampaign(
            JSON.parse(localStorage.getItem("loggedUserInfo")).gymid, filter
        );

        // console.log(response);
        if (!response) {
            return;
        }
        if (response === "false") {
            setApiError(true)
            return;
        } else {


            const record = await response.json();
            if (!record) {


                return;
            }

            console.log(record);
            setwhatsAppData(record);

        }
        setLoading(true)
        setApplyFilterClicked(false)
        setResetFilterClicked(false)

    }

    useEffect(() => {
        fetchData();
    }, [applyFilterClicked, resetFilterClicked])

    useEffect(() => {
        setcustomdate(DayFilter === "6" ? true : false);
    }, [DayFilter]);


    // console.log(basicData);
    // console.log(whatsAppData);

    useEffect(() => {
        const popupRow = [];
        whatsAppData && whatsAppData.length > 0 && whatsAppData.forEach((item, index) => {
            const rowData = {
                sno: index + 1,
                campaignName: item.campainname ? item.campainname : "",
                sentOn: new Date(item.updatedAt).toLocaleString("en-IN", { timeZone: "Asia/Kolkata" }),
                numRecipients: item.templatevalues.length,
                template: <div
                    className='m-0 ms-2'
                    dangerouslySetInnerHTML={{
                        __html: item.template.replace(/\n/g, "<br/>")
                    }}
                ></div>,
                originalItem: item
            };
            popupRow.push(rowData);
        });
        setBasicData(prevData => ({
            ...prevData,
            rows: popupRow
        }));
        setLoading(false)
    }, [whatsAppData]);



    const handleReload = () => {
        window.location.reload();
    }

    /**
    * downloaddata function is used for export data .
    * created by vamshi
    * @function
    * 
    * @description
    * This downloaddata function has been create on [18-7-2024] for export.
    */
    const downloaddata = (data) => {
        const popupRow = [];
        whatsAppData && whatsAppData.length > 0 && whatsAppData.forEach((item, index) => {

            const rarray = [];

            rarray.push(index + 1);
            rarray.push(item.campainname ? item.campainname : "");
            rarray.push(new Date(item.updatedAt).toLocaleString("en-IN", { timeZone: "Asia/Kolkata" }));
            rarray.push(item.templatevalues.length);
            rarray.push(item.template.replace(/%n/g, "<br/>"));

            popupRow.push(rarray);
        })

        const obj = {
            columns: ['S.No', "Campaign Name", "Sent On", 'Number of Recipients', "Template"],
            rows: popupRow
        }
        ExcelExportButton(obj, "whatsApp_Campaign_Reports");
    }
    const [openTogle, setOpenTogle] = useState(false)
    /**
* handleRowClick function is used know the member details .
* created by vamshi
* @function
* 
* @description
* This handleRowClick function has been create on [17-7-2024] for meber details in popup.
*/
    const handleRowClick = (rowData) => {
        setSelectedRowData(rowData.originalItem ? rowData.originalItem : rowData);
        console.log(rowData);
        setOpenTogle(true)
    };
    console.log(selectedRowData);

    return (
        <>
            {apiError ? <PageError handleReload={handleReload} /> : <div className="mt-4">
                <div className=" d-flex flex-wrap">
                    <div className="gym-section w-100-mobile">
                        <label className="fw-bold">Search:</label>
                        <div>
                            <input
                                type="text"
                                ref={searchRef}
                                value={mfilter.tidName}
                                className="form-control select-input placeholder-active form-controlMobile"
                                // className="form-control"
                                placeholder="Search by Campaign Name"
                                onChange={(e) => setMfilter({
                                    ...mfilter,
                                    tidName: e.target.value.toLowerCase()
                                })}
                            />
                        </div>
                    </div>

                    <div className="gym-section w-100-mobile ms-2">
                        <label className="me-2 fw-bold"> Day: </label>
                        <div className="mob-w-100 ">
                            <select
                                name="shrs"
                                id="shr"
                                className="form-control select-input form-select placeholder-active me-3 shadow-1 form-selectMobile"
                                onChange={(e) => setDayFilter(e.target.value)}
                                value={DayFilter}
                            >
                                <option value="7">All</option>
                                <option value="1">Today</option>
                                <option value="2">Yesterday</option>
                                <option value="3">This Week</option>
                                <option value="4">This Month</option>
                                <option value="5">This Year</option>
                                <option value="6">Custom</option>

                            </select>
                        </div>
                    </div>
                    {customdate && <div className="gym-section w-100-mobile ms-3">
                        <span className="customRange align-items-center">
                            <label className="fw-bold">Custom Range</label>
                            <div className="gym-xlarge gym-larg-pos">
                                <DatePicker
                                    dateFormat={"d/M/yyyy"}
                                    selectsRange={true}
                                    startDate={startDate}
                                    endDate={endDate}
                                    onChange={(update) => {
                                        getDates(update)
                                    }}
                                    isClearable={false}
                                    customInput={<CalenderInput />}
                                />
                            </div>
                        </span>
                    </div>}
                    <div className="gym-section w-100-mobile ms-3">
                        <div className="mt-3 pt-1 filterApplyMobile">
                            <button
                                type="submit"
                                className="me-3 btn btn-primary"
                                onClick={() => handleApplyAndToggle()}
                            >
                                Apply
                            </button>
                            <button
                                type="submit"
                                className="mt-2 btn btn-secondary"
                                onClick={() => { handleResetAndToggle() }}
                            >
                                Clear
                            </button>
                            {(localStorage.getItem("loggedUserInfo")) && JSON.parse(localStorage.getItem("loggedUserInfo")).role === "admin" && <button type="submit" className="ms-3 btnheight btn btn-primary"
                                onClick={(e) => downloaddata(basicData)}
                            >
                                <MDBIcon fas icon="download" className="d-inline me-1" />Export
                            </button>}
                        </div>
                    </div>
                </div>
                <div className="">
                    {loading ? (<div className="loader-height"> <Loader /></div>) :
                        <MDBDatatable className="whatsapptable"
                            fixedHeader
                            maxHeight='calc(100vh - 223px)'
                            entries={25}
                            paging={true}
                            data={basicData}
                            onRowClick={handleRowClick}
                        />
                    }
                </div>
            </div>

            }
            <MDBModal staticBackdrop tabIndex='-1' open={openTogle} onClose={() => setOpenTogle(false)}>
                <MDBModalDialog size="md">
                    <MDBModalContent>
                        <MDBModalHeader>
                            <MDBModalTitle>
                                <h6 className="m-0">Recipients Details</h6>
                            </MDBModalTitle>
                            <MDBBtn className='btn-close' color='none' onClick={() => setOpenTogle(false)}></MDBBtn>
                        </MDBModalHeader>
                        <MDBModalBody className="recipientdetails">
                            {selectedRowData !== null && selectedRowData.templatevalues.length > 0 && selectedRowData.templatevalues.map((item, index) => (
                                <div className="d-flex align-items-center">
                                    <p className="bold me-2 mb-1">{`${index + 1}) ${item.memberDetails && item.memberDetails.fname ? item.memberDetails.fname : "Member Deleted"} ${item.memberDetails && item.memberDetails.lname ? item.memberDetails.lname : ""}`}</p>
                                    <p className="me-2 mb-1">{item.memberDetails && item.memberDetails.number ? item.memberDetails.number : ""}</p>
                                </div>
                            ))}
                        </MDBModalBody>
                    </MDBModalContent>
                </MDBModalDialog>
            </MDBModal>

        </>
    )
}

export default WhatsAppCampaignReports