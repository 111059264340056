import React, { useEffect, useState } from 'react';
import {
    MDBCard,
    MDBCardBody,
    MDBBtn,
    MDBRow,
    MDBCol,
    MDBIcon,
    MDBSpinner
} from 'mdb-react-ui-kit';
import GymLogo from '../components/images/Easy-MembrLogo.png';
import { checkMembers } from "../services/apiServices";
import { useLocation, useNavigate } from "react-router-dom";
import { getBusinessName } from "../services/apiServices"

const QRCheckin = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const { data } = location.state || {};
    const [timer, setTimer] = useState(30);
    const [loading, setLoading] = useState(false);
    const [isExpired, setIsExpired] = useState(false);
    const [businessData, setBusinessData] = useState(null);
    const planInfo = data && data.length > 0 && data[0].plan && data[0].plan.length > 0
        ? data[0].plan.find((item) => item._id === data[0].planid) || {}
        : {};




    const handleArrow = () => navigate("/qrscanner");

    useEffect(() => {
        if (!data?.[0]) {
            navigate("/qrlogin");
        }
    }, [data]);



    useEffect(() => {
        if (data?.[0]?.expirydate) {
            const expiryDate = new Date(data[0].expirydate);
            setIsExpired(expiryDate <= new Date());
        }
    }, [data]);


    useEffect(() => {
        if (timer > 0) {
            const interval = setInterval(() => {
                setTimer((prev) => prev - 1);
            }, 1000);

            return () => clearInterval(interval);
        } else if (timer === 0) {

            handleConfirm()
            handleArrow();
        }
    }, [timer]);


    if (timer === 0) {

        if (data?.[0]?.plan?.length === 0) {
            handleArrow();
        }
    }

    const handleConfirm = async () => {
        if (!data || !data[0]) {
            return;
        }

        if (data[0]?.issuspend || isExpired) {
            return;
        }

        const chekinflag = data[0]?.checkinflag === 1 ? "0" : "1";
        const id = data[0]?.bId;
        const mId = data[0]?._id;
        const planName = planInfo;

        if (!id || !mId || !planName) {
            return;
        }

        const updates = {
            checkFlag: chekinflag,
            gymid: id,
            mid: mId,
            _id: mId,
            date: new Date(),
            fvalue: "pos",
            selectedplan: planName,
        };

        setLoading(true);
        try {
            const response = await checkMembers(updates, mId);
            const message =
                chekinflag === "1"
                    ? "Successfully Checked In"
                    : "Successfully Checked Out";

            localStorage.setItem("checkinmsg", message);

            if (!response || !response.ok) {
                throw new Error(`API call failed with status: ${response.status}`);
            }

            const result = await response.json();
            console.log("Check-in response:", result);

            handleArrow();
            setTimer(30);
        } catch (error) {
            console.error("Error during check-in:", error);
        } finally {
            setLoading(false);
        }
    };





    useEffect(() => {
        const fetchData = async () => {
            try {
                const datar = data?.[0]?.bId;
                console.log("datar value:", datar);

                if (datar) {
                    const updates = { bId: datar };
                    console.log("Request payload:", updates);

                    const result = await getBusinessName(updates);

                    console.log("API result:", result);

                    if (result && result.json) {
                        const jsonData = await result.json();
                        setBusinessData(jsonData);
                        console.log("Business data set:", jsonData);
                    } else {
                        console.error("API returned no data or null.");
                    }
                } else {
                    console("datar is undefined or null. Skipping API call.");
                }
            } catch (error) {
                console.error("Error fetching business name:", error);
            }
        };

        fetchData();
    }, []);



    function preaddUser(dataObj) {
        localStorage.setItem("preadd_plan", JSON.stringify(dataObj));
        localStorage.setItem("preadd_member", JSON.stringify(dataObj));
        navigate("/home");
    }

    function getExpiryDate(data) {
        try {
            console.log(data);

            let startdate = new Date();

            let enddate = data.expirydate;
            console.log(startdate);
            console.log(enddate);
            let expirydate = new Date(enddate).getTime() - startdate.getTime();
            let cd = 24 * 60 * 60 * 1000;
            console.log(Math.floor(expirydate / cd));
            let exdays = Math.floor(expirydate / cd) + 1;
            return exdays >= 1
                ? exdays + " Day(s)"
                : data.attendance[0] &&
                    new Date(data.attendance[0].intime).getTime() >=
                    new Date(enddate).getTime()
                    ? "Expired (Overdue)"
                    : "Expired";
        } catch (e) {
            console.log("Error :::::::::::::::: " + e);
        }
    }

    return (
        <div className="h-100vhWave">
            <MDBCard className='waveForm'>
                <MDBCardBody className='py-3 position-relative'>
                    <div className="mobile-Head pb-4">
                        <div className='d-flex align-items-center justify-content-between Waiver-logo'>
                            <img src={GymLogo} className="w-20 mob-w-40" alt="Gym Logo" />
                            {businessData?.logourl && <img
                                src={businessData?.logourl || ""}
                                alt={GymLogo}
                                className="w-20 mob-w-40"
                            />}
                        </div>
                    </div>



                    <div className='mb-4'>
                        <div className="d-flex align-items-center justify-content-between">
                            <div className='d-flex align-items-center'>
                                <MDBIcon fas icon="arrow-left" className="fs-3" onClick={handleArrow} />
                                <h4 className="m-0 ps-3">
                                    {data?.[0]?.fname && data?.[0]?.lname
                                        ? `${data[0].fname} ${data[0].lname}`
                                        : "N/A"}
                                </h4>
                            </div>
                        </div>

                        <MDBRow className="mt-4">
                            <MDBCol sm="12" lg="6" md="6">
                                <p>Member Id: <strong>{data?.[0]?.mid || "N/A"}</strong></p>
                                <p>Contact: <strong>{data?.[0]?.number || "N/A"}</strong></p>
                                <p>Gender: <strong>{data?.[0]?.sex || "N/A"}</strong></p>
                            </MDBCol>
                            <MDBCol sm="12" lg="6" md="6">
                                <p>Height: <strong>{data?.[0]?.height ?? "N/A"}</strong></p>
                                <p>Blood Group: <strong>{data?.[0]?.bloodgroup || "N/A"}</strong></p>
                                <p>Email: <strong>{data?.[0]?.email || "N/A"}</strong></p>
                            </MDBCol>
                        </MDBRow>

                        <p>
                            Plan:
                            <strong
                                className={`px-2 ${data?.[0]?.plan?.length > 0 ? "text-success" : "text-danger"}`}
                            >
                                {planInfo ? `${planInfo.name || "N/A"}`
                                    : "No Plan"}
                            </strong>

                            {data?.[0]?.plan?.length > 0 && (
                                <div className='d-flex align-items-center'>
                                    <div className={parseFloat(getExpiryDate(data && data.length > 0 ? data[0] : {})) > 6 ? "clr-green px-1 button-text" : [parseFloat(getExpiryDate(data && data.length > 0 ? data[0] : {})) > 0 ? "clr-orange button-text" : "clr-red button-text"]}>
                                        ({getExpiryDate(data && data.length > 0 ? data[0] : {}) || "0"} Day(s))
                                    </div>
                                    <div
                                        className="ml0 link-color"
                                        onClick={(e) => {
                                            const member = data?.[0];
                                            const plan = planInfo;

                                            if (member && plan?._id) {
                                                preaddUser({
                                                    member,
                                                    planId: plan._id
                                                });
                                            } else {
                                                console.error("Member or plan is not available.");
                                            }
                                        }}
                                    >
                                        Renew
                                    </div>

                                </div>
                            )}

                        </p>
                        {!data?.[0]?.issuspend ? (
                            isExpired ? (
                                <p className="text-danger mt-3">Plan has been expired</p>
                            ) : null
                        ) : (
                            <p className="text-danger mt-3">Member is suspended</p>
                        )}


                    </div>

                    <div className="d-flex flex-column align-items-center">
                        <div
                            className="rounded-circle d-flex align-items-center justify-content-center mb-4"
                            style={{
                                width: "80px",
                                height: "80px",
                                backgroundColor: "#f0f0f0",
                                fontSize: "24px",
                                fontWeight: "bold",
                            }}
                        >
                            {timer}
                        </div>
                        <div className="d-flex align-items-center justify-content-center w-100">
                            <MDBBtn color="light" size="lg" outline onClick={handleArrow} className="w-50 py-2">
                                Cancel
                            </MDBBtn>
                            {!data?.[0]?.issuspend && !isExpired && data?.[0]?.plan?.length > 0 && (

                                <MDBBtn
                                    className="w-50 ms-2 py-2"
                                    size="lg"
                                    color={data?.[0]?.checkinflag === 1 ? "danger" : "success"}
                                    onClick={handleConfirm}
                                    disabled={loading}
                                    style={{
                                        backgroundColor: data?.[0]?.checkinflag === 1 ? "red" : "",
                                        color: data?.[0]?.checkinflag === 1 ? "black" : "",
                                    }}
                                >
                                    {loading ? (
                                        <MDBSpinner size="sm" role="status" />
                                    ) : (
                                        data?.[0]?.checkinflag === 1 ? "Checkout" : "Checkin"
                                    )}
                                </MDBBtn>
                            )}

                        </div>




                    </div>
                </MDBCardBody>

            </MDBCard>
        </div>
    );
};

export default QRCheckin;
