import React, { useEffect, useState, forwardRef } from "react";
import { getPlans, sessionSummary } from "../services/apiServices";
import { MDBBtn, MDBDatatable, MDBIcon, MDBModal, MDBModalBody, MDBModalContent, MDBModalDialog, MDBModalFooter, MDBModalHeader, MDBModalTitle } from 'mdb-react-ui-kit';
import { format, parseISO } from "date-fns";
import DatePicker from 'react-datepicker';
import calImg from "./../components/images/calendar.svg";
import ExcelExportButton from "../components/exportToExcel.js";
import ReportsGraph from "../components/ReportsGraph";
import PageError from "./PageError";
import ReactGA from 'react-ga';
import Loader from "../components/Loader";


function SessionCountRpt() {
    const [sessionData, setSessionData] = useState([])
    const [apiError, setApiError] = useState(false);
    const [applyFilterClicked, setApplyFilterClicked] = useState(false);
    const [plans, setPlans] = useState([]);
    const [loading, setLoading] = useState(false)
    const [basicData, setBasicData] = useState({
        columns: ['Date', 'Session used', "Complimentary", "Total"],
        rows: [],
    });
    const [basicData2, setBasicData2] = useState({
        columns: ["S.no", 'Member', "Plan Name", 'Date', 'Check-In', 'Check-Out', 'Used Session(s)', "Balance Sessoins", "Status"],
        rows: [],
    });
    const [selectedTrans, setSelectedTrans] = useState([])
    const [graphCheck, setGraphCheck] = useState(false)
    const [basicModal, setBasicModal] = useState(false);
    const [DayFilter1, setDayFilter1] = useState("Date");
    const [DayFilter, setDayFilter] = useState("4");
    const today = new Date();
    const currentTime = new Date();
    const startOfToday = new Date(currentTime);
    startOfToday.setHours(0, 0, 0, 0);

    const endOfToday = new Date(currentTime);
    endOfToday.setHours(23, 59, 59, 999);

    const yesterday = new Date(currentTime);
    yesterday.setDate(currentTime.getDate() - 1);
    const startOfYesterday = new Date(yesterday);
    startOfYesterday.setHours(0, 0, 0, 0);

    const endOfYesterday = new Date(yesterday);
    endOfYesterday.setHours(23, 59, 59, 999);

    const startOfWeek = new Date(currentTime);
    startOfWeek.setDate(currentTime.getDate() - currentTime.getDay());
    startOfWeek.setHours(12, 0, 0, 0);
    const endOfWeek = new Date(currentTime);
    endOfWeek.setDate(startOfWeek.getDate() + 6);
    endOfWeek.setHours(12, 0, 0, 0);

    const startOfMonth = new Date(currentTime);
    startOfMonth.setDate(1);
    startOfMonth.setHours(0, 0, 0, 0);

    const endOfMonth = new Date(currentTime);

    const startOfYear = new Date(currentTime);
    startOfYear.setMonth(0, 1);
    startOfYear.setHours(0, 0, 0, 0);

    const endOfYear = new Date(currentTime);
    endOfYear.setMonth(11, 31);
    endOfYear.setHours(23, 59, 59, 999);



    today.setHours(23, 59, 0, 0);
    const [customdate, setcustomdate] = useState(DayFilter === "6");
    const [dateRange, setDateRange] = useState([startOfMonth, endOfMonth]);
    const [startDate, endDate] = dateRange;

    const [resetFilterClicked, setResetFilterClicked] = useState(false)

    const [dateArray, setDateArray] = useState("");
    const [graphinfo, setGraphinfo] = useState({})
    const [graphData, setGraphData] = useState({})


    const CalenderInput = forwardRef(({ value, onClick }, ref) => (
        <>
            <span className="gym-input forminput h-40">
                {value === "" ? "Custom Range" : value}
                &nbsp;
                <img
                    src={calImg}
                    alt="logo"
                    onClick={onClick}
                    ref={ref}
                    title="Select Custom Range"
                />
            </span>
        </>
    ));

    const getDates = (obj) => {
        setDateRange(obj);
    };

    useEffect(() => {
        fetchData();
        fetchPData();
    }, [applyFilterClicked, resetFilterClicked]);


    async function fetchPData() {

        const response = await getPlans(
            JSON.parse(localStorage.getItem("loggedUserInfo")).gymid
        );
        // console.log(response);

        if (!response) {
            return;
        }
        if (response === "false") {
            setApiError(true)
        } else {
            const plans = await response.json();
            // console.log(plans);

            setPlans(plans);
        }

    }

    useEffect(() => {
        setcustomdate(DayFilter === "6" ? true : false);
    }, [DayFilter]);

    async function fetchData() {
        setLoading(true)
        let startOfDate = startDate
        let endOfDate = endDate
        let Dateobj = {}

        if ((startOfDate && endOfDate)) {
            const startDatef = new Date(startOfDate)
            startDatef.setHours(0, 0, 0, 0)
            const utcstartDate = startDatef.toISOString();
            // location.filter.startDate = utcstartDate;
            let lastoftheday = new Date(endOfDate);
            lastoftheday.setHours(23, 59, 59, 999);
            const utcEndDate = lastoftheday.toISOString();

            //             location.filter.endDate = utcEndDate;
            Dateobj = {
                startDate: utcstartDate,
                endDate: utcEndDate,
            }
        }

        const response = await sessionSummary(
            JSON.parse(localStorage.getItem("loggedUserInfo")).gymid, Dateobj
        );
        if (!response) {
            return;
        }
        if (response === "false") {
            setApiError(true)
            return;
        }

        const sessions = await response.json();
        // console.log(sessions.filter(titem => titem.member!==undefined));

        const filteredsessions = sessions.filter(titem => titem.member !== undefined)
        processData(filteredsessions, DayFilter1)
        setApplyFilterClicked(false)
        setResetFilterClicked(false)
    }

    const handleReload = () => {
        window.location.reload();
    };


    useEffect(() => {
        ReactGA.pageview(window.location.pathname);
    }, []);


    const processData = (checkIns, dateformate) => {


        if (dateformate === "Month") {

            const formattedData = checkIns.reduce((acc, cur) => {
                const date = new Date(cur.intime);
                const month = date.toLocaleString('default', { month: 'short' });
                const year = date.getFullYear();
                const monthYearKey = `${month}-${year}`;

                if (!acc[monthYearKey]) {
                    acc[monthYearKey] = { date: `${month}-${year}`, data: [] };
                }
                acc[monthYearKey].data.push(cur);
                return acc;
            }, {});

            const finalResponse = Object.values(formattedData);

            setSessionData(finalResponse);
        } else if (dateformate === "Week") {



            const formattedData = checkIns.reduce((acc, cur) => {
                const date = new Date(cur.intime);
                const month = date.toLocaleString('default', { month: 'short' });
                const year = date.getFullYear();
                const weekOfMonth = getWeekOfMonth(date);
                const weekLabel = `${month} ${weekOfMonth === 1 ? weekOfMonth + "st" : weekOfMonth === 2 ? weekOfMonth + "nd" : weekOfMonth === 3 ? weekOfMonth + "rd" : weekOfMonth + "th"} week ${year}`;

                if (!acc[weekLabel]) {
                    acc[weekLabel] = { date: weekLabel, data: [] };
                }
                acc[weekLabel].data.push(cur);
                return acc;
            }, {});

            const finalResponse = Object.values(formattedData);
            setSessionData(finalResponse);

        } else if (dateformate === "Year") {
            const formattedData = checkIns.reduce((acc, cur) => {
                const date = new Date(cur.intime);
                const year = date.getFullYear().toString();
                if (!acc[year]) {
                    acc[year] = { date: year, data: [] };
                }
                acc[year].data.push(cur);
                return acc;
            }, {});

            const finalResponse = Object.values(formattedData);
            setSessionData(finalResponse);
        } else {
            const formattedData = checkIns.reduce((acc, cur) => {
                const dateKey = format(new Date(parseISO(cur.intime)), "yyyy-MM-dd");

                if (!acc[dateKey]) {
                    acc[dateKey] = { date: dateKey, data: [] };
                }
                acc[dateKey].data.push(cur);
                return acc;
            }, {});

            const finalResponse = Object.values(formattedData);

            console.log(finalResponse);
            setSessionData(finalResponse);
        }
    }
    const getWeekOfMonth = (date) => {
        const firstDayOfMonth = new Date(date.getFullYear(), date.getMonth(), 1);
        const dayOfWeek = firstDayOfMonth.getDay();
        const firstWeekStart = firstDayOfMonth - (dayOfWeek * 86400000);
        const weekNumber = Math.floor((date - firstWeekStart) / (7 * 86400000)) + 1;
        return weekNumber;
    };




    const handleClik = ({ date, sessiontype, data }) => {
        // console.log(`Clicked on ${date}, Session Type: ${sessiontype}, Data:`, data);
        if (sessiontype === "total") {
            setSelectedTrans(data)
        } else {
            // console.log(data.filter(item => item.sessiontype === sessiontype));
            setSelectedTrans(data.filter(item => item.sessiontype === sessiontype))
        }
        setBasicModal(true)
        setDateArray(sessiontype)
    };


    console.log(selectedTrans)
    useEffect(() => {

        const popupRow = [];
        let snumber = 1;

        selectedTrans && selectedTrans.length > 0 && sessionData.filter(titem => titem.member !== undefined) &&
            selectedTrans.forEach((item, index) => {
                const planUsed = plans && plans.length > 0 ? plans.filter(plan => plan._id === item.planid) : [];

                // console.log(planUsed);
                const rarray = [];
                rarray.push(snumber++);
                // rarray.push(item.trans.token);
                rarray.push(item.member.length > 0 && item.member[0].fname + " " + item.member[0].lname);
                rarray.push(planUsed && planUsed.length > 0 ? planUsed[0].name : "--");
                rarray.push(item.intime ? format(parseISO(item.intime), "yyyy-MM-dd") : "NA");
                rarray.push(
                    new Date(item.intime ? item.intime : "NA")
                        .toLocaleTimeString("en-IN", {
                            timeZone: "Asia/Kolkata",
                            hour: "2-digit",
                            minute: "2-digit",
                            second: "2-digit",
                        })
                );
                rarray.push(
                    (new Date(item.outtime)
                        .toLocaleTimeString("en-IN", {
                            timeZone: "Asia/Kolkata",
                            hour: "2-digit",
                            minute: "2-digit",
                            second: "2-digit",
                        })) === "Invalid Date" ? "NA" : new Date(item.outtime)
                            .toLocaleTimeString("en-IN", {
                                timeZone: "Asia/Kolkata",
                                hour: "2-digit",
                                minute: "2-digit",
                                second: "2-digit",
                            })
                );
                rarray.push(item.session[0].totalsessions - item.session[0].balsessions);
                rarray.push(item.session[0].balsessions);

                rarray.push(item.membershipstatus && item.session[0].balsessions > 0 ?
                    (item.membershipstatus === "1" ? "Active" : "Inactive")
                    : <div className="title">
                        {item.session[0].balsessions > 0 ? (!item.member[0].suspend ?
                            item.member[0].mStatus ?
                                item.member[0].mStatus :
                                (new Date(item.member[0].expirydate) > new Date() ?
                                    "Active" : "Inactive")
                            : "suspend") : "Inactive"}
                    </div>);
                popupRow.push(rarray);
            });
        setBasicData2({
            columns: ["S.no", 'Member', "Plan Name", 'Date', 'Check-In', 'Check-Out', 'Used Session(s)', "Balance Sessoins", "Status"],
            rows: popupRow
        })
    }, [selectedTrans, plans])
    useEffect(() => {

        const generateTableData = () => {
            const popupRow = [];
            sessionData.forEach(data1 => {
                const date = data1.date;
                let sessionCount = 0;
                let complimentaryCount = 0;
                let totalCount = data1.data.length;
                data1.data.forEach(item => {
                    if (item.sessiontype === "1") {
                        sessionCount += item.member.length;
                    } else {
                        complimentaryCount += item.member.length;
                    }

                })
                popupRow.push([date,
                    <button key={`${date}-session`} className="popupmember button-link link-color" onClick={() => handleClik({ date: date, sessiontype: "1", data: data1.data })}>{sessionCount}</button>,
                    <button key={`${date}-complimentary`} className="popupmember button-link link-color" onClick={() => handleClik({ date: date, sessiontype: "2", data: data1.data })}>{complimentaryCount}</button>,
                    <button key={`${date}-total`} className="popupmember button-link link-color" onClick={() => handleClik({ date: date, sessiontype: "total", data: data1.data })}>{totalCount}</button>
                ])
            })
            // console.log(popupRow);
            setBasicData({
                columns: ['Check-In Date', 'Session used', "Complimentary", "Total"],
                rows: popupRow
            })
            setLoading(false)
        }

        generateTableData()
    }, [sessionData])

    const handleClickOutside = () => {
        setSelectedTrans([]);
        setBasicModal(false)

    }


    const handleApplyAndToggle = () => {
        const sDate = DayFilter === "1" ? startOfToday : (DayFilter === "2" ? startOfYesterday : (DayFilter === "3" ? startOfWeek : (DayFilter === "4" ? startOfMonth : (DayFilter === "5" ? startOfYear : (DayFilter === "6" ? startDate : null)))))
        const eDate = DayFilter === "1" ? endOfToday : (DayFilter === "2" ? endOfYesterday : (DayFilter === "3" ? endOfWeek : (DayFilter === "4" ? endOfMonth : (DayFilter === "5" ? endOfYear : (DayFilter === "6" ? endDate : null)))))
        if (sDate !== null && eDate !== null) {
            setDateRange([sDate, eDate])
        } else {
            setDateRange([])
        }

        setApplyFilterClicked(true)
    }

    const handleResetAndToggle = () => {

        setDayFilter("4")
        setDateRange([startOfMonth, endOfMonth])
        setDayFilter1("Date")
        setResetFilterClicked(true)
    }

    useEffect(() => {

        const generatingGraphData = () => {
            const basrow = basicData.rows;
            const columns = basicData.columns;
            const labels = basrow.map(row => row[0]);

            const datasets = [];
            for (let i = 1; i < columns.length - 1; i++) {
                const columnName = columns[i];
                const data = basrow.map(row => parseInt(row[i].props.children));
                datasets.push({
                    label: columnName,
                    data,
                    backgroundColor: `rgb(${Math.floor(Math.random() * 256)}, ${Math.floor(Math.random() * 256)}, ${Math.floor(Math.random() * 256)})`,
                });
            }


            setGraphinfo({
                title: "Session Summary",
                bar: "Check-In(s)",
                xtitle: "Member(s)",
                ytitle: `${DayFilter1}(s)`,
            })
            setGraphData({
                labels,
                datasets,
            });
        };
        generatingGraphData()
    }, [basicData, DayFilter1])





    const downloaddata = (data, title) => {
        ExcelExportButton(data, title);
    }
    return (
        <>
            {apiError ? (
                <PageError handleReload={handleReload} />
            ) : (<div>
                <div className="trainerSummeryFilters d-flex align-items-end mb-3 mt-4">

                    <div className="d-flex align-items-center ms-2 mob-w-100 ">
                        <div className="gym-section w-100-mobile ms-2 mb-0">
                            <label className="me-2 fw-bold"> Breakdown: </label>
                            <div className="mob-w-100 ">
                                <select
                                    name="shrs"
                                    id="shr"
                                    className="form-select placeholder-active active mob-w-100 m-0"
                                    onChange={(e) => setDayFilter1(e.target.value)}
                                    value={DayFilter1}
                                >
                                    <option value="Date">Date</option>
                                    <option value="Week">Weekly</option>
                                    <option value="Month">Monthly</option>
                                    <option value="Year">Yearly</option>

                                </select>
                            </div>
                        </div>
                        <div className="gym-section w-100-mobile ms-2 mb-0">
                            <label className="me-2 fw-bold"> Days: </label>
                            <div className="mob-w-100 ">
                                <select
                                    name="shrs"
                                    id="shr"
                                    className="form-select placeholder-active active mob-w-100 m-0"
                                    onChange={(e) => setDayFilter(e.target.value)}
                                    value={DayFilter}
                                >
                                    <option value="7">All</option>
                                    <option value="1">Today</option>
                                    <option value="2">Yesterday</option>
                                    <option value="3">This Week</option>
                                    <option value="4">This Month</option>
                                    <option value="5">This Year</option>
                                    {/* <option value="Week">Weekly</option> */}
                                    {/* <option value="Month">Monthly</option>
                                    <option value="Year">Yearly</option> */}
                                    <option value="6">Custom</option>

                                </select>
                            </div>
                        </div>
                    </div>
                    {customdate && <div className="d-flex align-items-center ms-4 mob-w-100 mt-3">
                        <label className="me-2 fw-bold"> Select Custom Date: </label>
                        <div className="gym-xlarge  gym-larg-pos mob-w-100">
                            <DatePicker
                                dateFormat={"d/M/yyyy"}
                                selectsRange={true}
                                startDate={startDate}
                                endDate={endDate}
                                onChange={(update) => {
                                    getDates(update);
                                }}
                                isClearable={false}
                                customInput={<CalenderInput />}
                            />
                        </div>
                    </div>}

                    <div className="mob-div-center filterApplyMobile ms-3">
                        <MDBBtn className="ms-2" onClick={(e) => handleApplyAndToggle()}>Apply</MDBBtn>
                        <MDBBtn className="ms-2" color="secondary" onClick={(e) => handleResetAndToggle()}>Clear</MDBBtn>
                        <MDBBtn className="ms-2" onClick={() => setGraphCheck(!graphCheck)}>{graphCheck ? <span><MDBIcon fas icon="table" className="me-1" />Table </span> : <span ><MDBIcon fas icon="chart-bar" className="me-1" />Graph</span>}</MDBBtn>
                        {!graphCheck && <MDBBtn className="ms-2" onClick={() => downloaddata(basicData, "sessionSummary")}>Export</MDBBtn>}
                    </div>
                </div>




                {!graphCheck && <div className="trainerSummeryDataTable mt-4">
                    {loading ? (<div className="loader-height"> <Loader /></div>) : <>
                      <MDBDatatable entries={25} paging={true} className="sessionSummeryDataTable sortOff" data={basicData} /></>}


                </div>}

                {graphCheck && <div className="w-100 border p-4 mb-4 shadow-4 mob-w-100 mob-p-0">
                    {loading ? (<div className="loader-height"> <Loader /></div>) : <>
                        <ReportsGraph data={graphData} info={graphinfo} /></>}

                </div>}


                <MDBModal open={basicModal} setOpen={setBasicModal} tabIndex='-1'>
                    <MDBModalDialog size="lg">
                        <MDBModalContent>
                            <MDBModalHeader>
                                <MDBModalTitle><div className="d-flex"><div>{dateArray !== "total" ? dateArray === "1" ? "session(s)" : "Complimentary" : "Total"}  </div></div></MDBModalTitle>
                                <MDBBtn className='btn-close' color='none' onClick={() => { handleClickOutside() }}></MDBBtn>
                            </MDBModalHeader>
                            <MDBModalBody>
                                {basicModal && <div className="trainerMemberSummeryDataTable">
                                    <MDBDatatable entries={25} data={basicData2} />

                                </div>}
                            </MDBModalBody>
                            <MDBModalFooter className="pe-4 pb-3">
                                <MDBBtn className="ms-2" onClick={() => downloaddata(basicData2, dateArray === "total" ? "Total check-In Members" : dateArray === "1" ? "session(s)" : "Complimentary")}>download</MDBBtn>
                            </MDBModalFooter>

                        </MDBModalContent>
                    </MDBModalDialog>
                </MDBModal>

            </div>)} </>
    )
}

export default SessionCountRpt