import React from "react";

import srcImg from "./../components/images/add.svg";


const PlansHeader = (props) => {
  let plansInfo = localStorage.getItem("planInfo") ? JSON.parse(localStorage.getItem("planInfo")) : null;
  const loggedUserInfo = JSON.parse(localStorage.getItem("loggedUserInfo"));
  const isAdmin = loggedUserInfo?.role !== "staff";


  function openAdd(e, obj) {
    props.onPlanAdd(e, obj)
  }
  function openAddS(e, obj) {
    props.onSlotAdd(e, obj)
  }
  function openAddA(e, obj) {
    props.onAddonAdd(e, obj)
  }
  function openAddDiscount(e, obj) {
    props.onAddDiscount(e, obj)
  }
  return (
    <>
      <div className="gym-container" id="showcase">
        <div className="createplanmain w-100 mob-block">
          <h3 className="gym-large">Plans, Slots & Addons</h3>
          <div className="mobile-d-flex">
            {(plansInfo === null || (plansInfo.planFeature && plansInfo.planFeature.memebershipplans) && isAdmin) ?
              <>
                <a href="#" onClick={(e) => openAdd(e, { id: "-1" })} className="p-1">
                  <div className="createplan">
                    <span className="addic">
                      <img src={srcImg} className="w-16" alt="Create Plan" />
                    </span>
                    <span className="pl-5">Create Plan</span>
                  </div>
                </a>
              </> : ""}
            {(plansInfo === null || (plansInfo.planFeature && plansInfo.planFeature.timeslots) && isAdmin) ?
              <a href="#" onClick={(e) => openAddS(e, { id: "-1" })} className="p-1">
                <div className="createplan">
                  <span className="addic">
                    <img src={srcImg} className="w-16" alt="Create Slot" />
                  </span>
                  <span className="pl-5">Create Slot</span>
                </div>
              </a>
              : ""}
            {(plansInfo === null || (plansInfo.planFeature && plansInfo.planFeature.addons) && isAdmin) ?
              <a href="#" onClick={(e) => openAddA(e, { id: "-1" })} className="p-1">
                <div className="createplan">
                  <span className="addic">
                    <img src={srcImg} className="w-16" alt="Create Addons" />
                  </span>
                  <span className="pl-5">Create Addons</span>
                </div>
              </a>
              : ""}
            {/* {(plansInfo === null || (plansInfo.planFeature && plansInfo.planFeature.discountplans)) ?
              <a href="#" onClick={(e) => openAddDiscount(e, { _id: "-1" })} className="p-1">
              <div className="createplan">
                <span className="addic">
                  <img src={srcImg} className="w-16" alt="Create Addons" />
                </span>
                <span className="pl-5">Create Discount</span>
              </div>
            </a>
              : ""} */}
          </div>
        </div>
      </div>

    </>
  );
}

export default PlansHeader;