import Header from "../components/Header";
import LeftPane from "../components/LeftPane";
import { format, parseISO } from "date-fns";
import { MDBTable, MDBTableBody, MDBTableHead, MDBTooltip } from "mdb-react-ui-kit";
import React, { forwardRef, useEffect, useState, useRef } from "react";
import ReactGA from 'react-ga';
import Loader from "../components/Loader";
import { getLogEventFilter } from "../services/apiServices.js";
import PageError from "./PageError";
import DatePicker from "react-datepicker";
import calImg from "./../components/images/calendar.svg";
import moment from 'moment-timezone';

const Eventtracker = () => {
  const [Data, setData] = useState([]);
  const [loading, setLoading] = useState(true)
  const [apiError, setApiError] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(50);
  const [totalPages, setTotalPages] = useState(1);

  const currentTime = new Date();
  const startOfToday = new Date(currentTime);

  const endOfToday = new Date(currentTime);

  const yesterday = new Date(currentTime);
  yesterday.setDate(currentTime.getDate() - 1);
  const startOfYesterday = new Date(yesterday);

  const endOfYesterday = new Date(yesterday);

  const startOfWeek = new Date(currentTime);
  startOfWeek.setDate(currentTime.getDate() - currentTime.getDay());
  const endOfWeek = new Date(currentTime);
  endOfWeek.setDate(startOfWeek.getDate() + 6);

  const startOfMonth = new Date(currentTime);
  startOfMonth.setDate(1);

  const endOfMonth = new Date(currentTime);

  const startOfYear = new Date(currentTime);
  startOfYear.setMonth(0, 1);

  const endOfYear = new Date(currentTime);
  endOfYear.setMonth(11, 31);

  const [dateRange, setDateRange] = useState([startOfMonth, endOfToday]);
  const [startDate, endDate] = dateRange;

  const [filter, setfilter] = useState({
    inputText: "",
    dayFilter: 4,
  });
  const handleReload = () => {
    window.location.reload();
  }
  const CalenderInput = forwardRef(({ value, onClick }, ref) => (
    <>
      <span className="form-control select-input  placeholder-active me-3 shadow-1" onClick={onClick}>
        <p className="d-inline m-0 me-2">
          {value === "" ? "Custom Range" : value}
        </p>
        <img src={calImg} ref={ref} alt="Custom Range" title="Select Custom Range" />
      </span>
    </>
  ));
  useEffect(() => {
    ReactGA.pageview(window.location.pathname);
  }, []);
  const nextPage = () => {

    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
    setLoading(true);
  };

  const prevPage = () => {
    if (currentPage > 0) {
      setCurrentPage(currentPage - 1);
    }
    setLoading(true);
  };
  const handleApplyAndToggle = () => {
    setCurrentPage(1);
    setData([]);
    setLoading(true);
  }
  const handleResetAndToggle = () => {
    setCurrentPage(1);
    setLoading(true);
    setData([]);
    setfilter({
      inputText: "",
      dayFilter: 4,
    })
  }

  useEffect(() => {
    async function fetchData() {
      if (localStorage.getItem("loggedUserInfo")) {
        const dayFilter = Number(filter.dayFilter);

        let startOfDate = (dayFilter === 6 ? startDate : (dayFilter === 1 ? startOfToday : (dayFilter === 2 ? startOfYesterday : (dayFilter === 3 ? startOfWeek : (dayFilter === 4 ? startOfMonth : (dayFilter === 5 ? startOfYear : startDate))))))
        let endOfDate = (dayFilter === 6 ? endDate : (dayFilter === 1 ? endOfToday : (dayFilter === 2 ? endOfYesterday : (dayFilter === 3 ? endOfWeek : (dayFilter === 4 ? endOfMonth : (dayFilter === 5 ? endOfYear : endDate))))))

        let updates = {
          currentPage: currentPage,
          limitmember: itemsPerPage,
          startDate: dayFilter === 7 ? null : new Date(moment(startOfDate).startOf('day')),
          endDate: dayFilter === 7 ? null : new Date(moment(endOfDate).endOf('day')),
          inputText: filter.inputText,
          staffName: (localStorage.getItem("loggedUserInfo")
            && JSON.parse(localStorage.getItem("loggedUserInfo")).role === "staff")
            ? JSON.parse(localStorage.getItem("loggedUserInfo")).email : null,
        };
        const response = await getLogEventFilter(
          updates,
          JSON.parse(localStorage.getItem("loggedUserInfo")).gymid
        );
        if (!response) {
          return;
        }
        if (response === "false") {
          setApiError(true)
        } else {
          const usersData = await response.json();
          console.log(usersData);
          if (!usersData) {
            return;
          }
          setTotalPages(usersData.totalPages);
          setData(usersData.data);
          setLoading(false);
        }
      }
    };
    fetchData();
  }, [currentPage, itemsPerPage, loading]);
  let tmrow = [];
  Data.forEach((data, index) => {
      let rarray = [];
    rarray.push(index + 1 + ((currentPage - 1) * itemsPerPage));
      rarray.push(data.username1);
      rarray.push(data.ipAddress);
      rarray.push(data.browserInfo);
      rarray.push(format(parseISO(data.date), "dd-MM-yyyy"));
      rarray.push(format(parseISO(data.date), "hh:mm:ss  aaaaa'm'"));
      rarray.push(data.flag);
      tmrow.push(rarray);
  });
  const basicData = {
    columns: [
      "S.no",
      "Logged-In User",
      "IP Address",
      "Browser Info",
      "Date",
      "Time",
      "Description",

    ],
    rows: tmrow,
  };

  useEffect(() => {
    const timeout = setTimeout(() => {
      const thElements = document.querySelectorAll('.datatable-header th');

      thElements.forEach((th) => {
        if (th.textContent.trim().includes("Date")) {

          const icon = th.querySelector('i');
          if (icon) {
            icon.style.display = "none";
          }

          th.style.pointerEvents = "none";
          th.style.cursor = "default";
        }
      });
    }, 1000);


    return () => clearTimeout(timeout);
  }, []);

  return (
    <>
      <LeftPane isactive="12" />
      <Header />
      {!apiError && loading && (<h4><Loader /></h4>)}
      {apiError ? <PageError handleReload={handleReload} />
        : <div className="gym-main rightContent">
        <div
          className="gym-container"
          id="showcase"
        >
          <h3 className="gym-large">Log Events</h3>
          <div className=" d-flex align-items-center flex-wrap w-sm-80 mb-3 mobileResloution" >
            <div className="gym-section order-0">
              <label className="fw-bold">Search by Logged-In User/Description</label>
              <div>
                <input
                  type="text"
                  value={filter.inputText}
                  className="form-control select-input  placeholder-active me-5 shadow-1"
                  placeholder="Search Here..."
                  onChange={(e) => {
                    setfilter({
                      ...filter,
                      inputText: e.target.value
                    })
                  }
                  }
                />
              </div>
            </div>
            <div className="mob-w-100 ms-3 mob-m-0 ">
              <label className="me-2 fw-bold"> Days </label>
              <div>
                <select
                  name="shrs"
                  id="shr"
                  className="form-control select-input form-select placeholder-active me-3 shadow-1 form-selectMobile"
                  onChange={(e) => {
                    setfilter({
                      ...filter,
                      dayFilter: Number(e.target.value)
                    })
                  }}
                  value={filter.dayFilter}
                >
                  <option value={7}>All</option>
                  <option value={1}>Today</option>
                  <option value={2}>Yesterday</option>
                  <option value={3}>This Week</option>
                  <option value={4}>This Month</option>
                  <option value={5}>This Year</option>
                  <option value={6}>Custom</option>
                </select>
              </div>
            </div>

            {(filter.dayFilter === 6) &&
              <div className="gym-section order-2 ms-3">
                <label className="fw-bold">Date</label>
                <div className="gym-xlarge z-index_4 gym-larg-pos">
                  <div className="cursor-pointer">
                    <DatePicker
                      dateFormat={"d/M/yyyy"}
                      selectsRange={true}
                      startDate={startDate}
                      endDate={endDate}
                      onChange={(update) => {
                        setDateRange(update);
                      }}
                      isClearable={false}
                      customInput={<CalenderInput />}
                    />
                  </div>
                </div>
              </div>}
            <div className="gym-section order-5 ms-3 " >
              <div className="mt-3">
                <button type="submit" className="me-2 btn btn-primary"
                  onClick={(e) => handleApplyAndToggle()}
                >
                  Apply
                </button>
                <button type="submit" className="mt-2 btn btn-secondary"
                  onClick={(e) => handleResetAndToggle()}
                >
                  Clear
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className="tableContainer logEvent">
          {/* {!loading &&
            <MDBDatatable className="sno"
              // searchLabel="Search"
              //  search
              fixedHeader
              entries={50}
              data={basicData}
            />} */}

          {!loading &&
            <div className="datatable cursorPointer mmManager_Table" data-mdb-fixed-header="true">
              <div className="mmtablediv">

                <MDBTable hover small scroll>
                  <MDBTableHead>
                    <tr>
                      {basicData.columns.map((column, index) => (
                        <th key={index}>{column}</th>
                      ))}
                    </tr>
                  </MDBTableHead>

                  <MDBTableBody>
                    {basicData.rows.map((row, rowIndex) => (
                      <tr key={rowIndex}>
                        {row.map((cell, cellIndex) => (
                          <td key={cellIndex}>{cell}</td>
                        ))}
                      </tr>
                    ))}
                    {basicData.rows.length === 0 &&
                      <tr>
                        <td colSpan={15}><p className=" d-flex justify-content-center"> No Data Found</p></td>
                      </tr>}
                  </MDBTableBody>
                </MDBTable>
              </div>

              <div className="datatable-pagination">
                Rows per page
                <div className="ms-2 form-control w-auto">
                  <select value={itemsPerPage} onChange={(e) => setItemsPerPage(Number(e.target.value))} className="Pagenation-Select">
                    <option value="50">
                      <div className="select-option">
                        50
                      </div>
                    </option>
                    <option value="100">
                      <div className="select-option">
                        100
                      </div>
                    </option>
                    <option value="500">
                      <div className="select-option">
                        500
                      </div>
                    </option>
                    <option value="1000">
                      <div className="select-option">
                        1000
                      </div>
                    </option>

                  </select>
                </div>
                <button onClick={() => prevPage()}
                  disabled={currentPage === 1}
                  className="btn btn-link datatable-pagination-button datatable-pagination-right ripple-surface-dark">
                  Previous
                </button>
                <div className="d-flex justify-content-between w-120px align-items-center"> Page
                  <input className="form-control w-50px" value={currentPage} onChange={(e) => setCurrentPage(Number(e.target.value))} />
                  of {totalPages}</div>
                <button onClick={() => nextPage()}
                  disabled={currentPage === totalPages}
                  className="btn btn-link datatable-pagination-button datatable-pagination-right ripple-surface-dark">
                  Next
                </button>
              </div>
            </div>}
        </div>
      </div>}
    </>
  );
};

export default Eventtracker;
