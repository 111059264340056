import React, { forwardRef, useEffect, useState } from "react";
import { transactionSSummary } from '../services/apiServices';
import { MDBBtn, MDBDatatable, MDBIcon, MDBModal, MDBModalBody, MDBModalContent, MDBModalDialog, MDBModalFooter, MDBModalHeader, MDBModalTitle } from 'mdb-react-ui-kit';
import DatePicker from 'react-datepicker';
import calImg from "./../components/images/calendar.svg";
import ExcelExportButton from "../components/exportToExcel.js";
import ReportsGraph from "../components/ReportsGraph";
import PageError from "./PageError";
import ReactGA from 'react-ga';
import Loader from "../components/Loader";
import { format, parseISO } from "date-fns";

function TransactionSummary() {
    const [apiError, setApiError] = useState(false);
    const [transdata, setData] = useState([]);
    const [DayFilter, setDayFilter] = useState("4");
    const [customdate, setcustomdate] = useState(DayFilter === "6");
    const [selectedTrans, setSelectedTrans] = useState([])
    const [totalAmount, setTotalAmount] = useState(0)
    const [basicModal, setBasicModal] = useState(false);
    const [graphinfo, setGraphinfo] = useState({})
    const [graphData, setGraphData] = useState({})
    const toggleOpen = () => setBasicModal(!basicModal);
    const [dateArray, setDateArray] = useState({});
    const [DayFilter1, setDayFilter1] = useState("Date");

    const [basicData, setBasicData] = useState({
        columns: [],
        rows: [],
    });
    const [loading, setLoading] = useState(false)
    const today = new Date();
    const currentTime = new Date();
    const startOfToday = new Date(currentTime);
    startOfToday.setHours(0, 0, 0, 0);

    const endOfToday = new Date(currentTime);
    endOfToday.setHours(23, 59, 59, 999);

    const yesterday = new Date(currentTime);
    yesterday.setDate(currentTime.getDate() - 1);
    const startOfYesterday = new Date(yesterday);
    startOfYesterday.setHours(0, 0, 0, 0);

    const endOfYesterday = new Date(yesterday);
    endOfYesterday.setHours(23, 59, 59, 999);

    const startOfWeek = new Date(currentTime);
    startOfWeek.setDate(currentTime.getDate() - currentTime.getDay());
    startOfWeek.setHours(12, 0, 0, 0);
    const endOfWeek = new Date(currentTime);
    endOfWeek.setDate(startOfWeek.getDate() + 6);
    endOfWeek.setHours(12, 0, 0, 0);

    const startOfMonth = new Date(currentTime);
    startOfMonth.setDate(1);
    startOfMonth.setHours(0, 0, 0, 0);

    const endOfMonth = new Date(currentTime);

    const startOfYear = new Date(currentTime);
    startOfYear.setMonth(0, 1);
    startOfYear.setHours(0, 0, 0, 0);

    const endOfYear = new Date(currentTime);
    endOfYear.setMonth(11, 31);
    endOfYear.setHours(23, 59, 59, 999);

    today.setHours(23, 59, 0, 0);
    const [dateRange, setDateRange] = useState([startOfMonth, endOfMonth]);
    const [graphCheck, setGraphCheck] = useState(false)
    const [startDate, endDate] = dateRange;
    const [applyFilterClicked, setApplyFilterClicked] = useState(false)
    const [resetFilterClicked, setResetFilterClicked] = useState(false)
    const [basicData2, setBasicData2] = useState({
        columns: ["S.no", 'Order Id', 'Member(s)', 'Date', 'Payment Mode', 'Paid', 'Due', "Total"],
        rows: [],
    })


    const CalenderInput = forwardRef(({ value, onClick }, ref) => (
        <>
            <span className="gym-input forminput h-40">
                {value === "" ? "Custom Range" : value}
                &nbsp;
                <img
                    src={calImg}
                    onClick={onClick}
                    ref={ref}
                    alt="Custom Range"
                    title="Select Custom Range"
                />
            </span>
        </>
    ));

    useEffect(() => {
        ReactGA.pageview(window.location.pathname);
    }, []);


    const getDates = (obj) => {
        setDateRange(obj);
    };


    const handleApplyAndToggle = () => {
        const sDate = DayFilter === "1" ? startOfToday : (DayFilter === "2" ? startOfYesterday : (DayFilter === "3" ? startOfWeek : (DayFilter === "4" ? startOfMonth : (DayFilter === "5" ? startOfYear : (DayFilter === "6" ? startDate : null)))))
        const eDate = DayFilter === "1" ? endOfToday : (DayFilter === "2" ? endOfYesterday : (DayFilter === "3" ? endOfWeek : (DayFilter === "4" ? endOfMonth : (DayFilter === "5" ? endOfYear : (DayFilter === "6" ? endDate : null)))))
        if (sDate !== null && eDate !== null) {
            setDateRange([sDate, eDate])
        } else {
            setDateRange([])
        }

        setApplyFilterClicked(true)
    }
    const handleResetAndToggle = () => {

        setDayFilter("4")
        setDateRange([startOfMonth, endOfMonth])
        setDayFilter1("Date")
        setResetFilterClicked(true)

    }

    useEffect(() => {
        setcustomdate(DayFilter === "6" ? true : false);
    }, [DayFilter]);

    async function fetchData() {
        setLoading(true)
        let startOfDate = startDate
        let endOfDate = endDate
        let Dateobj = {}

        if ((startOfDate && endOfDate)) {
            const startDatef = new Date(startOfDate)
            startDatef.setHours(0, 0, 0, 0)
            const utcstartDate = startDatef.toISOString();
            // location.filter.startDate = utcstartDate;
            let lastoftheday = new Date(endOfDate);
            lastoftheday.setHours(23, 59, 59, 999);
            const utcEndDate = lastoftheday.toISOString();

            //             location.filter.endDate = utcEndDate;
            Dateobj = {
                startDate: utcstartDate,
                endDate: utcEndDate,
            }
        }
        const response = await transactionSSummary(
            JSON.parse(localStorage.getItem("loggedUserInfo")).gymid, Dateobj
        );
        if (!response) {
            return;
        }
        if (response === "false") {
            setApiError(true)
            return;
        }

        const transactions = await response.json();

        // console.log(transactions);

        processData(transactions.alltrans, DayFilter1)
        setApplyFilterClicked(false)
        setResetFilterClicked(false)

    }


    const processData = (trans, dateformate) => {


        if (dateformate === "Month") {

            const formattedData = trans.reduce((acc, cur) => {
                const date = new Date(cur.createdAt);
                const month = date.toLocaleString('default', { month: 'short' });
                const year = date.getFullYear();
                const monthYearKey = `${month}-${year}`;

                if (!acc[monthYearKey]) {
                    acc[monthYearKey] = { date: `${month}-${year}`, data: [] };
                }
                acc[monthYearKey].data.push(cur);
                return acc;
            }, {});

            const finalResponse = Object.values(formattedData);

            setData(finalResponse);
            generateTableData(finalResponse);

        } else if (dateformate === "Week") {
            const formattedData = trans.reduce((acc, cur) => {
                const date = new Date(cur.createdAt);
                const month = date.toLocaleString('default', { month: 'short' });
                const year = date.getFullYear();
                const weekOfMonth = getWeekOfMonth(date);
                const weekLabel = `${month} ${weekOfMonth === 1 ? weekOfMonth + "st" : weekOfMonth === 2 ? weekOfMonth + "nd" : weekOfMonth === 3 ? weekOfMonth + "rd" : weekOfMonth + "th"} week ${year}`;

                if (!acc[weekLabel]) {
                    acc[weekLabel] = { date: weekLabel, data: [] };
                }
                acc[weekLabel].data.push(cur);
                return acc;
            }, {});

            const finalResponse = Object.values(formattedData);

            setData(finalResponse);
            generateTableData(finalResponse);

        } else if (dateformate === "Year") {
            const formattedData = trans.reduce((acc, cur) => {
                const date = new Date(cur.createdAt);
                const year = date.getFullYear().toString();
                if (!acc[year]) {
                    acc[year] = { date: year, data: [] };
                }
                acc[year].data.push(cur);
                return acc;
            }, {});

            const finalResponse = Object.values(formattedData);

            setData(finalResponse);
            generateTableData(finalResponse)

        } else {
            const formattedData = trans.reduce((acc, cur) => {
                const dateKey = format(new Date(parseISO(cur.createdAt)), "yyyy-MM-dd");

                if (!acc[dateKey]) {
                    acc[dateKey] = { date: dateKey, data: [] };
                }
                acc[dateKey].data.push(cur);
                return acc;
            }, {});

            const finalResponse = Object.values(formattedData);
            // console.log(finalResponse);
            setData(finalResponse);

            generateTableData(finalResponse)

        }
    }

    const getWeekOfMonth = (date) => {
        const firstDayOfMonth = new Date(date.getFullYear(), date.getMonth(), 1);
        const dayOfWeek = firstDayOfMonth.getDay();
        const firstWeekStart = firstDayOfMonth - (dayOfWeek * 86400000);
        const weekNumber = Math.floor((date - firstWeekStart) / (7 * 86400000)) + 1;
        return weekNumber;
    };



    const generateTableData = (data1) => {
        const tableData = [];
        const paymentAmounts = {};
        const paymentMethods = new Set();
        let alltotalamount = 0;

        data1.forEach(data2 => {
            let uniqueTIds = new Set();
            data2.data.forEach(transaction => {
                const dateKey = data2.date;
                if (!uniqueTIds.has(transaction.tId)) {
                    if (!paymentAmounts[dateKey]) {
                        paymentAmounts[dateKey] = {
                            Cash: 0,
                            Card: 0,
                            UPI: 0,
                            Wallet: 0,
                            Coupon: 0,
                            'Total Amount': 0
                        };
                    }
                    transaction.paymenthistory && transaction.paymenthistory.length > 0 && transaction.paymenthistory.forEach((item) => {
                        switch (item.mode) {
                            case 1:
                                paymentAmounts[dateKey].Cash += item.paid;
                                break;
                            case 2:
                                paymentAmounts[dateKey].Card += item.paid;
                                break;
                            case 3:
                                paymentAmounts[dateKey].UPI += item.paid;
                                break;
                            case 4:
                                paymentAmounts[dateKey].Wallet += item.paid;
                                break;
                            case 5:
                                paymentAmounts[dateKey].Coupon += item.paid;
                                break;
                            case 6:
                                const payInfo = JSON.parse(item.payinfo).info;
                                if (payInfo.cash_amt) {
                                    paymentAmounts[dateKey].Cash += parseFloat(payInfo.cash_amt);
                                }
                                if (payInfo.cc_amt) {
                                    paymentAmounts[dateKey].Card += parseFloat(payInfo.cc_amt);
                                }
                                if (payInfo.upi_amt) {
                                    paymentAmounts[dateKey].UPI += parseFloat(payInfo.upi_amt);
                                }
                                if (payInfo.wallet_amt) {
                                    paymentAmounts[dateKey].Wallet += parseFloat(payInfo.wallet_amt);
                                }
                                break;
                            default:
                                if (item.mode >= 7) {
                                    const payInfo = JSON.parse(item.payinfo);
                                    const userPayMethod = payInfo.info.userpaymethod || 'Account Payment Type';
                                    if (!paymentAmounts[dateKey][userPayMethod]) {
                                        paymentAmounts[dateKey][userPayMethod] = 0;
                                        paymentMethods.add(userPayMethod);
                                    }
                                    paymentAmounts[dateKey][userPayMethod] += item.paid || 0;
                                }
                                break;
                        }
                    });
                    paymentAmounts[dateKey]['Total Amount'] += transaction.payment.paid;
                    alltotalamount += transaction.payment.paid;
                    uniqueTIds.add(transaction.tId);
                }
            });
        });


        Object.keys(paymentAmounts).forEach(dateKey => {
            const rowData = paymentAmounts[dateKey];
            if (
                rowData.Cash === 0 &&
                rowData.Card === 0 &&
                rowData.UPI === 0 &&
                rowData.Wallet === 0 &&
                rowData.Coupon === 0 &&
                rowData['Total Amount'] > 0
            ) {
                rowData.Cash = rowData['Total Amount'];
            }
        });

        const columns = [`Date`, "Cash", "Card", "UPI", "Wallet", "Coupon", "Total Amount"];

        paymentMethods.forEach(method => {
            const hasValue = Object.keys(paymentAmounts).some(date => paymentAmounts[date][method] !== 0);
            if (hasValue) {
                columns.splice(-1, 0, method);
            }
        });

        const filteredColumns = columns.filter(col => {
            if (col === "Date" || col === "Total Amount") return true;
            return Object.values(paymentAmounts).some(data => data[col] !== 0);
        });

        Object.keys(paymentAmounts).forEach(date => {
            const rowData = paymentAmounts[date];
            const row = [date];
            // console.log(typeof date, data1);
            filteredColumns.slice(1).forEach(column => {
                row.push(
                    <button className="popupmember button-link link-color"
                        onClick={() => handleClik({ date: date, paytype: column, data: data1.filter(item => item.date === date) })}
                    >
                        ₹{rowData[column] ? (rowData[column]).toLocaleString('en-IN') : 0}
                    </button>
                );
            });

            tableData.push(row);
        });

        setBasicData({
            columns: filteredColumns,
            rows: tableData
        });
        setLoading(false);
        setTotalAmount(alltotalamount);
    };

    const handleClik = (tran) => {

        setBasicData2({
            columns: ["S.no", 'Order Id', 'Member(s)', 'Date', 'Payment Mode', 'Paid', 'Due', "Total"],
            rows: []
        })
        const payment = tran.paytype
        const payvalue = payment !== "Total Amount" ? (payment === "Cash" ? 1 : payment === "UPI" ? 3 : payment === "Card" ? 2 : payment === "Wallet" ? 4 : payment === "Coupon" ? 5 : payment === "Multiple" ? 6 : 7) : null




        let filtereddata = [];

        if (payment === "Total Amount") {
            filtereddata = tran.data[0].data;
        } else {
            filtereddata = tran.data[0].data.filter((item) => {
                if (item.paymenthistory && item.paymenthistory.length > 0) {
                    return item.paymenthistory.some((history) => {
                        if (payvalue < 7) {
                            if (history.mode === payvalue || history.mode === 6) {
                                if (history.mode === 6) {
                                    const payinfo = JSON.parse(history.payinfo).info;
                                    if ((payment === "Cash" && parseFloat(payinfo.cash_amt) > 0) ||
                                        (payment === "UPI" && parseFloat(payinfo.upi_amt) > 0) ||
                                        (payment === "Card" && parseFloat(payinfo.cc_amt) > 0) ||
                                        (payment === "Wallet" && parseFloat(payinfo.wallet_amt) > 0)) {
                                        return true;
                                    }
                                } else {
                                    return history.mode === payvalue;
                                }
                            }
                            return false;
                        } else {
                            return JSON.parse(history.payinfo).info.userpaymethod === payment;
                        }
                    });
                } else {
                    if (payvalue < 7) {
                        if (item.payment.mode === payvalue || item.payment.mode === 6) {
                            if (item.payment.mode === 6) {
                                const payinfo = JSON.parse(item.payment.payinfo).info;
                                if ((payment === "Cash" && parseFloat(payinfo.cash_amt) > 0) ||
                                    (payment === "UPI" && parseFloat(payinfo.upi_amt) > 0) ||
                                    (payment === "Card" && parseFloat(payinfo.cc_amt) > 0) ||
                                    (payment === "Wallet" && parseFloat(payinfo.wallet_amt) > 0)) {
                                    return true;
                                }
                            } else {
                                return item.payment.mode === payvalue;
                            }
                        }
                        return false;
                    } else {
                        return JSON.parse(item.payment.payinfo).info.userpaymethod === payment;
                    }
                }
            });
        }
        // console.log(filtereddata);

        const groupedData = filtereddata.reduce((acc, current) => {
            const { tId } = current;
            if (acc[tId]) {
                acc[tId].alldata.push(current);
            } else {

                acc[tId] = { tId: tId, alldata: [current] };
            }

            return acc;
        }, {});

        const groupedDataArray = Object.values(groupedData);

        // console.log(groupedDataArray);
        setSelectedTrans(groupedDataArray)
        setDateArray(tran)

        setBasicModal(true)
    };



    console.log(selectedTrans);
    useEffect(() => {
        const popupRow = [];
        let snumber = 1
        let uniqueTIds = new Set();
        selectedTrans && selectedTrans.length > 0 && selectedTrans.forEach((item, index) => {

            const alldata = item.alldata
            const group = item.alldata
            const memberNames = group.map(item => item.members ? (item.members.fname + " " + item.members.lname) : "");

            // let tdiscount = group.reduce((acc, itm) => acc + ((itm.payment.discount && itm.payment.discount.amount) ? itm.payment.discount.amount : 0), 0)
            const rarray = [];
            alldata.forEach((data, index) => {
                if (!uniqueTIds.has(data.tId) && data.isplan !== "3") {
                    rarray.push(snumber++);
                    rarray.push(data.token);
                    rarray.push(memberNames.join(", "));
                    rarray.push(<span className='trans-date'>{new Date(data.createdAt).toLocaleString("en-IN", { timeZone: "Asia/Kolkata", })}</span>);
                    rarray.push(
                        data.paymenthistory ? (data.paymenthistory.length > 0 ? data.paymenthistory.map((item, ind) => {
                            return item.mode === 1 ? (
                                "Cash"
                            ) : item.mode === 2 ? (
                                "Card"
                            ) : item.mode === 3 ? (
                                "UPI"
                            ) : item.mode === 4 ? (
                                "Wallet"
                            ) : item.mode === 5 ? (
                                "Coupon"
                            ) : item.mode === 6 ? (
                                <>
                                    {<>Multiple:<br /></>}
                                    {JSON.parse(item.payinfo).info.cash_amt && <pre>  Cash: {JSON.parse(item.payinfo).info.cash_amt}</pre>}
                                    {JSON.parse(item.payinfo).info.cc_amt && <pre>  Credit Card: {JSON.parse(item.payinfo).info.cc_amt}</pre>}
                                    {JSON.parse(item.payinfo).info.upi_amt && <pre>  UPI: {JSON.parse(item.payinfo).info.upi_amt} {JSON.parse(item.payinfo).info.upi_input ? `(${JSON.parse(item.payinfo).info.upi_input})` : ""}</pre>}
                                    {JSON.parse(item.payinfo).info.wallet_amt && <pre>  Wallet: {JSON.parse(item.payinfo).info.wallet_amt}{JSON.parse(item.payinfo).info.wallet_input ? `(${JSON.parse(item.payinfo).info.wallet_input})` : ""}</pre>}
                                </>
                            ) : item.mode >= 7 ? (
                                <>
                                    {JSON.parse(item.payinfo).info.userpaymethod}
                                </>
                            ) : "Account Payment Type";
                        }) : "") : (data.payment.mode === 1 ? "Cash" : (data.payment.mode === 2 ? "Card" : (data.payment.mode === 3 ? "UPI" : (data.payment.mode === 4 ? "Wallet" : data.payment.mode === 5 ? "Coupon" : "Cash")))));
                    rarray.push("₹" + ((data.payment.paid).toLocaleString('en-IN')));
                    // rarray.push("₹" + (tdiscount).toLocaleString('en-IN', { minimumFractionDigits: 0, maximumFractionDigits: 2 }));
                    rarray.push("₹" + data.payment.balance.toLocaleString('en-IN'));
                    rarray.push("₹" + data.payment.totalamount.toLocaleString('en-IN'));

                    popupRow.push(rarray);
                    uniqueTIds.add(data.tId);

                }
            })
        })

        setBasicData2({
            columns: ["S.no", 'Order Id', 'Member(s)', 'Date', 'Payment Mode', 'Paid', 'Due', "Total"],
            rows: popupRow
        })
    }, [selectedTrans])



    useEffect(() => {
        fetchData();
    }, [applyFilterClicked, resetFilterClicked])


    const handleClickOutside = () => {

        setSelectedTrans([])

        toggleOpen();

    }

    const downloaddata = (data) => {
        //   ExcelExportButton(data);

        const tableData = [];
        const paymentAmounts = {};
        const paymentMethods = new Set();

        transdata.forEach(data2 => {
            let uniqueTIds = new Set();
            data2.data.forEach(transaction => {
                const dateKey = data2.date;
                if (!uniqueTIds.has(transaction.tId)) {
                    if (!paymentAmounts[dateKey]) {
                        paymentAmounts[dateKey] = {
                            Cash: 0,
                            Card: 0,
                            UPI: 0,
                            Wallet: 0,
                            Coupon: 0,
                            Multiple: 0,
                            'Total Amount': 0
                        };
                    }

                    transaction.paymenthistory && transaction.paymenthistory.length > 0 && transaction.paymenthistory.forEach((item) => {
                        switch (item.mode) {
                            case 1:
                                paymentAmounts[dateKey].Cash += item.paid;
                                break;
                            case 2:
                                paymentAmounts[dateKey].Card += item.paid;
                                break;
                            case 3:
                                paymentAmounts[dateKey].UPI += item.paid;
                                break;
                            case 4:
                                paymentAmounts[dateKey].Wallet += item.paid;
                                break;
                            case 5:
                                paymentAmounts[dateKey].Coupon += item.paid;
                                break;
                            case 6:

                                if (JSON.parse(item.payinfo).info.cash_amt) {
                                    paymentAmounts[dateKey].Cash += parseFloat(JSON.parse(item.payinfo).info.cash_amt);
                                }
                                if (JSON.parse(item.payinfo).info.cc_amt) {
                                    paymentAmounts[dateKey].Card += parseFloat(JSON.parse(item.payinfo).info.cc_amt);
                                }
                                if (JSON.parse(item.payinfo).info.upi_amt) {
                                    paymentAmounts[dateKey].UPI += parseFloat(JSON.parse(item.payinfo).info.upi_amt)
                                }
                                if (JSON.parse(item.payinfo).info.wallet_amt) {
                                    paymentAmounts[dateKey].Wallet += parseFloat(JSON.parse(item.payinfo).info.wallet_amt)
                                }
                                break;
                            default:
                                if (transaction.payment.mode >= 7) {
                                    const payInfo = JSON.parse(transaction.payment.payinfo);
                                    const userPayMethod = payInfo.info.userpaymethod || 'Account Payment Type';
                                    if (!paymentAmounts[dateKey][userPayMethod]) {
                                        paymentAmounts[dateKey][userPayMethod] = 0;
                                        paymentMethods.add(userPayMethod);
                                    }
                                    paymentAmounts[dateKey][userPayMethod] += transaction.payment.paid || 0;
                                }
                                break;
                        }
                    })
                    paymentAmounts[dateKey]['Total Amount'] += transaction.payment.paid;
                    // totalmount += transaction.payment.paid;
                    uniqueTIds.add(transaction.tId);
                }
            });
        });

        const columns = [`Date`, "Cash", "Card", "UPI", "Wallet", "Coupon", "Multiple", "Total Amount"];

        paymentMethods.forEach(method => {

            const hasValue = Object.keys(paymentAmounts).some(date => paymentAmounts[date][method] !== 0);
            if (hasValue) {
                columns.splice(-1, 0, method);
            }
        });

        const filteredColumns = columns.filter(col => {
            if (col === "Date" || col === "Total Amount") return true;
            return Object.values(paymentAmounts).some(data => data[col] !== 0);
        });

        Object.keys(paymentAmounts).forEach(date => {
            const rowData = paymentAmounts[date];
            const row = [date];

            filteredColumns.slice(1).forEach(column => {

                row.push(rowData[column] || 0);
            });

            tableData.push(row);
        });
        const tabledata = {
            columns: filteredColumns,
            rows: tableData,
        }
        ExcelExportButton(tabledata, "TransactionSummary");
    }

    const downloadminidata = (data) => {
        const popupRow = [];
        let snumber = 1
        let uniqueTIds = new Set();
        selectedTrans && selectedTrans.length > 0 && selectedTrans.forEach((item, index) => {
            const alldata = item.alldata
            const group = item.alldata
            const memberNames = group.map(item => item.members ? (item.members.fname + " " + item.members.lname) : "");
            // let tdiscount = group.reduce((acc, itm) => acc + ((itm.payment.discount && itm.payment.discount.amount) ? itm.payment.discount.amount : 0), 0)
            const rarray = [];
            alldata.forEach((data, index) => {
                if (!uniqueTIds.has(data.tId) && data.isplan !== "3") {
                    rarray.push(snumber++);
                    rarray.push(data.token);
                    rarray.push(memberNames.join(", "));
                    rarray.push(new Date(data.createdAt).toLocaleString("en-IN", { timeZone: "Asia/Kolkata", }));

                    rarray.push(
                        ...(data.paymenthistory.length > 0
                            ? data.paymenthistory.map((item) => {
                                if (item.mode === 1) return "Cash";
                                if (item.mode === 2) return "Card";
                                if (item.mode === 3) return "UPI";
                                if (item.mode === 4) return "Wallet";
                                if (item.mode === 5) return "Coupon";

                                if (item.mode === 6) {
                                    const payinfo = JSON.parse(item.payinfo).info;
                                    const payments = [];

                                    if (payinfo.cash_amt) payments.push(`Cash: ${payinfo.cash_amt}`);
                                    if (payinfo.cc_amt) payments.push(`Credit Card: ${payinfo.cc_amt}`);
                                    if (payinfo.upi_amt) payments.push(`UPI: ${payinfo.upi_amt}${payinfo.upi_input ? ` (${payinfo.upi_input})` : ""}`);
                                    if (payinfo.wallet_amt) payments.push(`Wallet: ${payinfo.wallet_amt}${payinfo.wallet_input ? ` (${payinfo.wallet_input})` : ""}`);

                                    return `Multiple: ${payments.join(", ")}`;
                                }

                                if (data.payment.mode >= 7) {
                                    return `${JSON.parse(data.payment.payinfo).info.userpaymethod}`;
                                }

                                return "Account Payment Type";
                            })
                            : []
                        )
                    );
                    rarray.push(((data.payment.paid).toLocaleString('en-IN')));
                    // rarray.push("₹" + (tdiscount).toLocaleString('en-IN', { minimumFractionDigits: 0, maximumFractionDigits: 2 }));
                    rarray.push(data.payment.balance.toLocaleString('en-IN'));
                    rarray.push(data.payment.totalamount.toLocaleString('en-IN'));

                    popupRow.push(rarray);
                    uniqueTIds.add(data.tId);

                }
            })
        })

        const tabledata = {
            columns: ["S.no", 'Order Id', 'Member(s)', 'Date', 'Payment Mode', 'Paid', 'Due', "Total"],
            rows: popupRow
        }

        ExcelExportButton(tabledata, dateArray.paytype !== "Total Amount" ? `Payment mode:${dateArray.paytype}` : "Total Amount");
    }
    // console.log(basicData);

    useEffect(() => {

        const generatingGraphData = () => {
            const basrow = basicData.rows;
            const columns = basicData.columns;

            // Extracting labels (months)
            const labels = basrow.map(row => row[0]);
            // console.log(columns, basrow);
            // Extracting datasets
            const datasets = [];
            for (let i = 1; i < columns.length - 1; i++) {
                const columnName = columns[i];
                const data = basrow.map(row => {
                    const children = row[i].props.children;
                    const numericString = Array.isArray(children) ? children[1] : '';

                    if (typeof numericString === 'string') {
                        return parseFloat(numericString.replace(/,/g, ''));
                    }

                    return NaN;
                });
                datasets.push({
                    label: columnName,
                    data,
                    backgroundColor: `rgb(${Math.floor(Math.random() * 256)}, ${Math.floor(Math.random() * 256)}, ${Math.floor(Math.random() * 256)})`,
                });
            }


            setGraphinfo({
                title: "Payment Summary",
                bar: "Amount",
                xtitle: "Amount",
                ytitle: `${DayFilter1}(s)`,
            })
            setGraphData(
                {
                    labels,
                    datasets,
                });
        };
        generatingGraphData()
    }, [basicData, DayFilter1])


    const handleReload = () => {
        window.location.reload();
    };
    return (
        <>
            {apiError ? (
                <PageError handleReload={handleReload} />
            ) : (<div>

                <div >
                    <div className="d-flex align-items-center TrainerSummeryCustomHead">
                        <div className="d-flex">
                            <div class="filters_Count bg-success bg-opacity-10"><p class="text-center mb-1 fw-600">Total Amount Received</p>
                                <div className="text-center">
                                    ₹{totalAmount ? (totalAmount).toLocaleString('en-IN') : 0}
                                </div>
                            </div>

                        </div>
                    </div>
                    <div className="trainerSummeryFilters d-flex align-items-center mb-3 mt-4">


                        <div className="d-flex align-items-center ms-2 mob-w-100 ">
                            <div className="gym-section w-100-mobile ms-3">
                                <label className="me-2 fw-bold"> Breakdown: </label>
                                <div className="mob-w-100 ">
                                    <select
                                        name="shrs"
                                        id="shr"
                                        className="form-select placeholder-active active mob-w-100"
                                        onChange={(e) => setDayFilter1(e.target.value)}
                                        value={DayFilter1}
                                    >
                                        <option value="Date">Date</option>
                                        <option value="Week">Weekly</option>
                                        <option value="Month">Monthly</option>
                                        <option value="Year">Yearly</option>

                                    </select>
                                </div>
                            </div>
                            <div className="gym-section w-100-mobile ms-3">
                                <label className="me-2 fw-bold"> Days: </label>
                                <div className="mob-w-100 ">
                                    <select
                                        name="shrs"
                                        id="shr"
                                        className="form-select placeholder-active active mob-w-100"
                                        onChange={(e) => setDayFilter(e.target.value)}
                                        value={DayFilter}
                                    >
                                        <option value="7">All</option>
                                        <option value="1">Today</option>
                                        <option value="2">Yesterday</option>
                                        <option value="3">This Week</option>
                                        <option value="4">This Month</option>
                                        <option value="5">This Year</option>
                                        {/* <option value="Week">Weekly</option> */}
                                        {/* <option value="Month">Monthly</option>
                                        <option value="Year">Yearly</option> */}
                                        <option value="6">Custom</option>

                                    </select>
                                </div>
                            </div>
                        </div>
                        {customdate && <div className="ms-3 gym-section mob-w-100">
                            <label className="me-2 fw-bold"> Select Custom Date: </label>
                            <div className="gym-xlarge  gym-larg-pos mob-w-100">
                                <DatePicker
                                    dateFormat={"d/M/yyyy"}
                                    selectsRange={true}
                                    startDate={startDate}
                                    endDate={endDate}
                                    onChange={(update) => {
                                        getDates(update);
                                    }}
                                    isClearable={false}
                                    customInput={<CalenderInput />}
                                />
                            </div>
                        </div>}

                        <div className="mob-div-center mt-3 pt-1 filterApplyMobile">
                            <MDBBtn className="ms-2" onClick={(e) => handleApplyAndToggle()}>Apply</MDBBtn>
                            <MDBBtn className="ms-2" color="secondary" onClick={(e) => handleResetAndToggle()}>Clear</MDBBtn>
                            <MDBBtn className="ms-2" onClick={() => setGraphCheck(!graphCheck)}>{graphCheck ? <span><MDBIcon fas icon="table" className="me-1" />Table </span> : <span ><MDBIcon fas icon="chart-bar" className="me-1" />Graph</span>}</MDBBtn>
                            {!graphCheck && <MDBBtn className="ms-2" onClick={() => downloaddata(basicData)}>Export</MDBBtn>}
                        </div>
                    </div>

                    {!graphCheck && <div className="trainerSummeryDataTable payments mt-4">
                        {loading ? (<div className="loader-height"> <Loader /></div>) : <>
                            <div className="TransactionSummaryDataTable">
                                <MDBDatatable entries={25} paging={true} data={basicData} />
                            </div>
                        </>}
                    </div>}

                    {graphCheck && <div className="w-100 border p-4 mb-4 shadow-4 mob-w-100 mob-p-0">

                        {loading ? (<div className="loader-height"> <Loader /></div>) : <>
                            <ReportsGraph data={graphData} info={graphinfo} /></>}

                    </div>}
                </div>
                <MDBModal open={basicModal} setOpen={setBasicModal} tabIndex='-1'>
                    <MDBModalDialog size="lg">
                        <MDBModalContent>
                            <MDBModalHeader>
                                <MDBModalTitle><div className="d-flex"><div>{dateArray.paytype !== "Total Amount" ? `Payment mode:${dateArray.paytype}` : "Total Amount"}  </div></div></MDBModalTitle>
                                <MDBBtn className='btn-close' color='none' onClick={() => { handleClickOutside() }}></MDBBtn>
                            </MDBModalHeader>
                            <MDBModalBody>
                                <div className="trainerMemberSummeryDataTable">
                                    <MDBDatatable entries={25} data={basicData2} />

                                </div>
                            </MDBModalBody>
                            <MDBModalFooter className="pe-4 pb-3">
                                <MDBBtn className="ms-2" onClick={() => downloadminidata(basicData2)}>download</MDBBtn>
                            </MDBModalFooter>
                        </MDBModalContent>
                    </MDBModalDialog>
                </MDBModal>

            </div>)} </>
    )
}

export default TransactionSummary