import Header from "../components/Header";
import LeftPane from "../components/LeftPane";
import {
  MDBBtn,
  MDBCard,
  MDBCardBody,
  MDBCardText,
  MDBCardTitle,
  MDBCol,
  MDBDatatable,
  MDBIcon,
  MDBModal,
  MDBModalBody,
  MDBModalContent,
  MDBModalDialog,
  MDBModalFooter,
  MDBModalHeader,
  MDBModalTitle,
  MDBRow,
  MDBTabs,
  MDBTabsContent,
  MDBTabsItem,
  MDBTabsLink,
  MDBTabsPane,
  MDBAlert,
  MDBInput
} from "mdb-react-ui-kit";
import React, { useEffect, useState } from "react";
import { browserName, browserVersion } from "react-device-detect";
import ReactGA from "react-ga";
import { useNavigate } from "react-router";
import Toast from "../components/Toast";
import {
  cancelSubscriptionPlan,
  getAdminById,
  getAllPricingPlans,
  getSubscriptionPlans,
  saveAdmin,
  verifymailotp,
  getmultigyms,
  deleteAddGym
} from "../services/apiServices";
import { logEvent } from "../services/apiServices.js";
import {
  isEmpty,
  isLength,
  isValidAge,
  isValidPhoneNumber,

} from "../validators/helper.js";
import PageError from "./PageError";
import { googleAnalytic } from "../components/googleAnalytics";
import ExcelExportButton from "../components/exportToExcel";
import addImg from "./../components/images/add.svg";
import Popup from "../components/Popup";
import AddGym from "./AddGym";
import logo from "./../components/images/Easy-MembrLogo.png";
import { format, parseISO } from "date-fns";




const Settings = (props) => {


  const [error, setError] = useState({});
  const [form, setForm] = useState({
    firstname: "",
    lastname: "",
    age: "",
    gender: "",
    number: "",
  });
  const [PlansData, setPlansData] = useState([]);
  const navigate = useNavigate();
  const [smessage, setSMessage] = useState(null);
  const [apiError, setApiError] = useState(false);
  const [basicData, setBasicData] = useState({
    columns: [
      "S.no",
      "Date",
      "Plan",
      "Module",
      "Duration",
      "Start Date",
      "End Date",
      "Amount",
    ],
    rows: [],
  });
  const [subPlans, setSubPlans] = useState([]);
  const [activeSubPlan, setActiveSubPlan] = useState(null);
  const [showBusinessPop, setShowBusinessPop] = useState(false);
  const [showPopup, setShowPopup] = useState(false);
  const [Error2, setError2] = useState("")
  const [email, setEmail] = useState()
  const [otp, setOtp] = useState(["", "", "", "", "", ""]);
  const [mdata, setMdata] = useState("")
  const [showModel, setShowModel] = useState(false)
  console.log("333333333333333333", showModel)
  const [selectedGym, setSelectedGym] = useState("")
  console.log(selectedGym)

  const maskedEmail = email ? `${email.slice(0, 4)}***@gmail.com` : "";









  // useEffect(() => {
  //   console.log('showPopup state changed:', showPopup);
  // }, [showPopup]);



  const handleReload = () => {
    window.location.reload();
  };



  const gymid = localStorage.getItem("loggedUserInfo") ? JSON.parse(localStorage.getItem("loggedUserInfo")).gymid : null


  const fetchMData = async () => {
    try {
      const updates = {
        gymid: gymid,
      };

      const response = await getmultigyms(updates);

      if (!response.ok) {
        throw new Error(`Error: ${response.status} - ${response.statusText}`);
      }

      const data = await response.json();
      setMdata(data);  // Update the state with the new data
    } catch (error) {
      console.error("Failed to fetch data:", error.message);
    }
  };

  useEffect(() => {
    if (localStorage.getItem("loggedUserInfo")) {
      fetchMData();
    }
  }, [gymid]);  // This runs when gymid changes




  async function fetchPData() {
    if (localStorage.getItem("loggedUserInfo")) {
      const response = await getSubscriptionPlans(
        JSON.parse(localStorage.getItem("loggedUserInfo")).gymid
      );
      if (!response) {
        return;
      }
      if (response === "false") {
        setApiError(true);
      } else {
        const record = await response.json();
        if (!record) {
          navigate("/");
          return;
        }
        console.log(record);
        setSubPlans(record);
        if (record.length > 0) {
          setActiveSubPlan(record[0]);
        }
      }
    }
  }

  useEffect(() => {
    fetchPData();
  }, [PlansData]);

  useEffect(() => {
    fetchPData();
    async function fetchData() {
      if (localStorage.getItem("loggedUserInfo")) {
        const response = await getAdminById(
          JSON.parse(localStorage.getItem("loggedUserInfo"))._id
        );
        if (!response) {
          return;
        }
        if (response === "false") {
          setApiError(true);
        } else {
          const record = await response.json();
          if (!record) {
            navigate("/");
            return;
          }
          if (localStorage.getItem("setting_save")) {
            setSMessage(localStorage.getItem("setting_save"));
            setTimeout(() => {
              setSMessage(null);
              localStorage.removeItem("setting_save");
            }, 5000);
          }
          setForm(record);
          if (!record.gender) {
            updateForm({ gender: "Male" });
          }
        }
      } else {
        navigate("/login");
      }
    }
    fetchData();
  }, []);

  const isAlphabetical = (str) => /^[a-zA-Z]+$/.test(str);

  let validateForm = (e) => {
    console.log("Validating form...");
    let errors = {};
    if (isEmpty(form.firstname)) {
      errors.firstname = "First Name can't be blank";
    } else if (!isAlphabetical(form.firstname)) {
      errors.firstname = `Name should contain only alphabetical characters`;
    }
    if (isEmpty(form.lastname)) {
      errors.lastname = "Last can't be blank";
    } else if (!isAlphabetical(form.lastname)) {
      errors.lastname = `Name should contain only alphabetical characters`;
    }

    // if (isEmpty(form.age)) {
    //   errors.age = "Age can't be blank";
    // } else if (!isLength(form.age, { gte: 1, lte: 3, trim: true })) {
    //   errors.age = "Age must be between 15 and 100";
    // } else if (!isValidAge(form.age)) {
    //   errors.age = "Please enter valid Age";
    // }

    if (isEmpty(form.number)) {
      errors.number = "Contact Number can't be blank";
    } else if (!isValidPhoneNumber(form.number)) {
      errors.number = "Please enter valid Contact Number";
    }

    console.log(errors);
    if (isEmpty(errors)) {
      return true;
    } else {
      return errors;
    }
  };
  useEffect(() => {
    ReactGA.pageview(window.location.pathname);
  }, []);

  function updateForm(value) {
    return setForm((prev) => {
      return { ...prev, ...value };
    });
  }


  // function handleOtp(value) {
  //   setOtp((prev) => ({ ...prev, ...value }));
  // }


  function processCancel() {
    window.location.href = "/settingview";
  }
  async function onSubmit(e) {
    e.preventDefault();
    const updates = {
      fname: form.firstname,
      lname: form.lastname,
      age: form.age,
      gender: form.gender,
      number: form.number,
      _id: localStorage.getItem("loggedUserInfo")
        ? JSON.parse(localStorage.getItem("loggedUserInfo"))._id
        : "",
    };
    console.log(updates);
    let errors = validateForm(e);
    if (errors === true) {
      let saverep = await saveAdmin(
        updates,
        localStorage.getItem("loggedUserInfo")
          ? JSON.parse(localStorage.getItem("loggedUserInfo"))._id
          : ""
      );
      localStorage.setItem("setting_save", "Profile saved successfully");
      console.log(await saverep.json());
      let lObj = localStorage.getItem("loggedUserInfo")
        ? JSON.parse(localStorage.getItem("loggedUserInfo"))
        : "";
      lObj.name = updates.fname + " " + updates.lname;

      let ip = localStorage.getItem("ipaddress");

      let browserinfo = browserName + "-" + browserVersion + ".0.0";
      let flag = "Settings Changed Successfull";
      let Eventtype = "setting";
      let gymid = localStorage.getItem("loggedUserInfo")
        ? JSON.parse(localStorage.getItem("loggedUserInfo")).gymid
        : "";
      let username1 = localStorage.getItem("loggedUserInfo")
        ? JSON.parse(localStorage.getItem("loggedUserInfo")).email
        : "";
      await googleAnalytic("Settings", "Settings", "Save Button", "Settings");
      logEvent(
        { Eventtype, username1, ip, flag, browserinfo, gymid },
        localStorage.getItem("loggedUserInfo")
          ? JSON.parse(localStorage.getItem("loggedUserInfo")).gymid
          : ""
      );
      localStorage.setItem("loggedUserInfo", JSON.stringify(lObj));
      window.location.href = "/settingview";
    } else {
      setError(errors);
    }
  }

  const [basicModal, setBasicModal] = useState(false);

  const toggleOpen = () => {
    setBasicModal(!basicModal);
  };
  const [basicActive, setBasicActive] = useState("tab1");

  const handleBasicClick = (value) => {
    if (value === basicActive) {
      return;
    }
    setBasicActive(value);
  };

  const [centredModal, setCentredModal] = useState(false);

  const toggleOpenPlan = () => setCentredModal(!centredModal);

  const updateModules = (Plan) => {
    let modules = [];
    if (Plan !== null && Plan.plandetails) {
      if (Plan.plandetails.smsintergration) {
        modules.push("SMS Integration");
      }
      if (Plan.plandetails.whatsappintegration) {
        modules.push("WhatsApp Integration");
      }
      if (Plan.plandetails.accountantrole) {
        modules.push("Accountant Role");
      }
      if (Plan.plandetails.memberportal) {
        modules.push("Member Portal");
      }
    }
    return modules.join(", ");
  };
  useEffect(() => {
    async function fetchData() {
      const response = await getAllPricingPlans();
      if (!response || response == "false") {
        return;
      }
      let recinfo = await response.json();
      if (recinfo && recinfo.pricingPlans) {
        let filterData = recinfo.pricingPlans.filter(
          (obj) => obj.availability == true
        );
        console.log(filterData);
        setPlansData(filterData);
      }
    }
    fetchData();
  }, []);

  const cancelHandler = async (e) => {
    e.preventDefault();
    let gymid = JSON.parse(localStorage.getItem("loggedUserInfo")).gymid;
    let updates = {
      subscriptionId: activeSubPlan.subscriptionid,
    };
    const response = await cancelSubscriptionPlan(gymid, updates);
    if (!response || response == "false") {
      console.log("Subscription cancelled successfully");
      return;
    }
    const res = await response.json();
    console.log(res);
  };

  useEffect(() => {
    const popupRow = [];
    let snumber = 1;
    subPlans &&
      subPlans.length > 0 &&
      subPlans.forEach((item, index) => {
        const rarray = [];
        rarray.push(snumber++);
        rarray.push(format(parseISO(item.createdAt), "yyyy-MM-dd"));

        rarray.push(
          PlansData.find((obj) => obj._id === item.plandetails.id)?.name || ""
        );
        rarray.push(updateModules(item) ? updateModules(item) : "--");
        rarray.push(
          item.subscriptionfrequency
            ? `${item.subscriptionfrequency} months`
            : "--"
        );
        rarray.push(
          item.subscriptionstartdate
            ? new Date(item.subscriptionstartdate).toLocaleString("en-IN", {
              timeZone: "Asia/Kolkata",
            })
            : "--"
        );
        rarray.push(
          item.subscriptionexpirydate
            ? new Date(item.subscriptionexpirydate).toLocaleString("en-IN", {
              timeZone: "Asia/Kolkata",
            })
            : "--"
        );
        rarray.push(item.amount && item.amount);
        popupRow.push(rarray);
      });

    setBasicData({
      columns: [
        "S.no",
        "Date",
        "Plan",
        "Module",
        "Duration",
        "Start Date",
        "End Date",
        "Amount",
      ],
      rows: popupRow,
    });
  }, [subPlans]);

  const downloaddata = (data, title) => {
    ExcelExportButton(data, title);
  };

  const handleAddGym = () => {
    setShowBusinessPop(true);
  };



  const handleAddGymSuccess = (email) => {
    setShowBusinessPop(false);
    setShowPopup(true)
    setEmail(email)
  };





  const handleSubmit = async () => {
    const otpString = otp.join('');

    if (otpString.length < 6) {
      setError2("Please enter all 6 digits.");
      return;
    }

    const updates = {
      email,
      otp: otpString,
    };

    console.log("updates:", updates);

    try {
      const response = await verifymailotp(updates);
      const result = await response.json();

      if (response.ok) {
        console.log('OTP Verified:', result.message);
        setError2("Gym successfully added");
        setShowPopup(false);
        fetchMData();

      } else {
        console.error('Error:', result.message);
        setError2(result.message);
      }
    } catch (error) {
      console.error('Error during API call:', error.message);
    }
  };





  const handleChange = (e, index) => {
    const value = e.target.value.replace(/[^0-9]/g, "");

    if (value.length > 1) return;

    let newOtp = [...otp];
    newOtp[index] = value;
    setOtp(newOtp);

    if (value && index < otp.length - 1) {
      const nextInput = document.getElementById(`otp-input-${index + 1}`);
      if (nextInput) nextInput.focus();
    }
  };



  const handleKeyDown = (e, index) => {
    if (e.key === "Backspace") {
      let newOtp = [...otp];

      if (!otp[index] && index > 0) {

        newOtp[index - 1] = "";
        setOtp(newOtp);

        const previousInput = document.getElementById(`otp-input-${index - 1}`);
        if (previousInput) {
          previousInput.focus();
        }
      } else {

        newOtp[index] = "";
        setOtp(newOtp);
      }
    }
  };



  function handleCardClick(data) {
    console.log("Selected Gym Data:", data);


    const storedgym = JSON.parse(localStorage.getItem("loggedUserInfo"));

    if (storedgym) {

      const updatedGym = {
        ...storedgym,
        gymid: data._id,
        gymname: data.businessName,
        gymlogoUrl: data.logourl,
      };

      localStorage.setItem("loggedUserInfo", JSON.stringify(updatedGym));

      window.location.reload();

    } else {
      console.error("No stored gym information found in localStorage.");
    }
  }





  const handleResendClick = () => {
    setShowPopup(false);
    setShowBusinessPop(true);
  };






  const handleDeleteConfirmation = async (data) => {
    try {
      const id = data._id;

      const response = await deleteAddGym({ id });
      const result = await response.json();

      if (response.status === 200) {
        setError2("Gym successfully deleted");
        setShowModel(false);


        fetchMData();
      } else {
        setError2(result.message);
        console.log("Failed to delete gym. Status:", response.status);
      }
    } catch (e) {
      console.error("Error deleting gym:", e);
    }
  };





  return (
    <>
      <LeftPane isactive="9" />
      <Header />
      {apiError ? (
        <PageError handleReload={handleReload} />
      ) : (
        <div className="gym-main rightContent">
          <h3 class="gym-large mb-3 d-flex justify-content-between">Manage Account

            <MDBBtn
              color="light"
              style={{ cursor: "pointer!important", float: "right" }}
              id="adduserBtn"
              onClick={(e) => handleAddGym(e)}
            >
              <img src={addImg} alt="Add Member" width="16px" className="me-1" />{" "}
              Add gyms
            </MDBBtn>
          </h3>


          <MDBTabs className="mb-3">
            <MDBTabsItem>
              <MDBTabsLink
                onClick={() => handleBasicClick("tab1")}
                active={basicActive === "tab1"}
              >
                Profile Settings
              </MDBTabsLink>
            </MDBTabsItem>
            <MDBTabsItem>
              <MDBTabsLink
                onClick={() => handleBasicClick("tab2")}
                active={basicActive === "tab2"}
              >
                Subscription Plan
              </MDBTabsLink>
            </MDBTabsItem>
            <MDBTabsItem>
              <MDBTabsLink
                onClick={() => handleBasicClick("tab3")}
                active={basicActive === "tab3"}
              >
                Gyms
              </MDBTabsLink>
            </MDBTabsItem>
          </MDBTabs>

          <MDBTabsContent>
            <MDBTabsPane open={basicActive === 'tab1'}>
              <div className="w-100-mobile wp-50 border rounded-4 a p-4 pe-5 shadow-4">
                <MDBCardTitle className="text-dark d-flex justify-content-between">
                  <label>Profile Settings</label>
                  <button
                    className="edit-Btn"
                    title="Edit"
                    onClick={(e) => toggleOpen()}
                  >
                    <MDBIcon fas icon="pencil-alt" className="fs-6 me-2" /> Edit
                  </button>
                </MDBCardTitle>
                <MDBCardText>
                  <div className=" Business__Details__data  mt-2">
                    <label>
                      First Name:<span className="text-danger">*</span>
                    </label>{" "}
                    <label className="fw-600">{form.firstname}</label>
                  </div>
                  <div className=" Business__Details__data  mt-1">
                    <label>
                      Last Name:<span className="text-danger">*</span>
                    </label>{" "}
                    <label className="fw-600">{form.lastname}</label>
                  </div>
                  <div className=" Business__Details__data  mt-1">
                    <label>Age:</label> <label className="fw-600">{form.age}</label>
                  </div>
                  <div className=" Business__Details__data  mt-1">
                    <label>
                      Gender:<span className="text-danger">*</span>
                    </label>{" "}
                    <label className="fw-600">{form.gender}</label>
                  </div>
                  <div className=" Business__Details__data  mt-1">
                    <label>
                      Mobile Number:<span className="text-danger">*</span>
                    </label>{" "}
                    <label className="fw-600">{form.number}</label>
                  </div>
                </MDBCardText>
              </div>
            </MDBTabsPane>
            <MDBTabsPane open={basicActive === 'tab2'}>
              <div className="wp-50 border rounded-4 a p-4 pe-5 shadow-4 w-100-mobile">
                <MDBCardTitle className="text-dark d-flex justify-content-between">
                  <label>Subscription Plan</label>
                  <div>
                    {/* <button className="edit-Btn clr-red" title="Edit" onClick={(e) => cancelHandler(e)}>
                      Cancel Subscription
                    </button> */}
                    <button
                      className="edit-Btn ms-2"
                      title="Edit"
                      onClick={(e) => toggleOpenPlan()}
                    >
                      View History
                    </button>
                  </div>
                </MDBCardTitle>
                <MDBCardText>
                  <div className="Business__Details__data mt-2">
                    <label>Plan Name</label>
                    <label className="fw-600">
                      {(activeSubPlan &&
                        PlansData.find(
                          (obj) => obj._id === activeSubPlan.plandetails.id
                        )?.name) ||
                        ""}
                    </label>
                  </div>
                  <div className="Business__Details__data mt-1">
                    <label>Additional Addons</label>
                    <label className="fw-600">
                      {updateModules(activeSubPlan)
                        ? updateModules(activeSubPlan)
                        : "--"}
                    </label>
                  </div>
                  <div className="Business__Details__data mt-1">
                    <label>Status</label>
                    <label className="fw-600">
                      {activeSubPlan
                        ? activeSubPlan.status == 1
                          ? "Active"
                          : activeSubPlan.status == 0
                            ? "Inactive"
                            : activeSubPlan.status == 2
                              ? "Pending"
                              : activeSubPlan.status == 3
                                ? "Canceled"
                                : ""
                        : ""}
                    </label>
                  </div>
                  {/* <div className="Business__Details__data mt-1">
                    <label>Auto Payment</label>
                    <label className="fw-600">
                      {activeSubPlan && activeSubPlan.autopay
                        ? "Enabled"
                        : "Disabled"}
                    </label>
                  </div> */}
                  <div className="Business__Details__data mt-1">
                    <label>Billing Period</label>
                    <label className="fw-600">
                      {activeSubPlan && activeSubPlan.subscriptionfrequency}{" "}
                      {`Month(s)`}
                    </label>
                  </div>
                  <div className="Business__Details__data mt-1">
                    <label>Start Date</label>
                    <label className="fw-600">
                      {/* {activeSubPlan && activeSubPlan.subscriptionstartdate
                        ? new Date(
                          activeSubPlan.subscriptionstartdate
                        ).toLocaleDateString("en-IN")
                          : "--"} */}
                      {activeSubPlan && activeSubPlan.subscriptionstartdate
                        ? new Date(activeSubPlan.subscriptionstartdate).toISOString().slice(0, 10).replace(/-/g, '/')
                        : "--"}
                    </label>
                  </div>
                  <div className="Business__Details__data mt-1">
                    <label>End Date</label>
                    <label className="fw-600">
                      {activeSubPlan && activeSubPlan.subscriptionexpirydate
                        ? new Date(activeSubPlan.subscriptionexpirydate).toISOString().slice(0, 10).replace(/-/g, '/')
                        : "--"}

                    </label>
                  </div>
                </MDBCardText>
              </div>
            </MDBTabsPane>




            <MDBTabsPane open={basicActive === "tab3"}>
              <MDBRow>
                {mdata && mdata.length > 0 ? (
                  mdata.map((data, index) => (
                    <MDBCol md={3} lg={4} sm={12} className="mb-4">
                      <MDBCard className="gymsCardHeight">
                        <MDBCardBody>
                          <div key={index}
                            style={{ zIndex: "10" }}

                            onClick={() => handleCardClick(data)}
                          >
                            {index !== 0 && <div className="gym-delete"
                              onClick={(event) => {
                                event.stopPropagation();
                                // handleDelect(data, index);
                                setShowModel(true)
                                setSelectedGym(data)

                              }}
                            >
                              <MDBIcon fas icon="times" size="lg" />
                            </div>}

                            {/* <MDBBtn className="btn-close" color="none" onClick={() => setShowModel(false)}></MDBBtn> */}


                            <div className="payment-span chip">
                              <label>Business Name:</label>{" "}
                              <label className="fw-600">{data.businessName}</label>
                            </div>

                            <div className="payment-span chip">
                              <label>Owner First Name:</label>{" "}
                              <label className="fw-600">{data.firstName || "N/A"}</label>
                            </div>

                            <div className="payment-span chip">
                              <label>Owner Last Name:</label>{" "}
                              <label className="fw-600">{data.lastName || "N/A"}</label>
                            </div>

                            <div className="payment-span chip">
                              <label>Email:</label>{" "}
                              <label className="fw-600">{data.businessEmail}</label>
                            </div>

                            <div className="payment-span chip">
                              <label>Contact No:</label>{" "}
                              <label className="fw-600">{data.contactNumber}</label>
                            </div>

                            <div className="payment-span chip">
                              <label>City:</label>{" "}
                              <label className="fw-600">{data.city}</label>
                            </div>

                            <div className="payment-span chip">
                              <label>State:</label>{" "}
                              <label className="fw-600">{data.state || "N/A"}</label>
                            </div>

                            <div className="payment-span chip">
                              <label>Country:</label>{" "}
                              <label className="fw-600">{data.country || "N/A"}</label>
                            </div>

                            <div className="payment-span chip">
                              <label>Business Address:</label>{" "}
                              <label className="fw-600">{data.address}</label>
                            </div>

                            <div className="mt-3">
                              <label className="m-0 fw-600">Business Logo:</label>
                              <div className="py-2">
                                <img
                                  alt="Business Logo"
                                  src={data.logourl || logo}
                                  style={{ maxWidth: "100%", maxHeight: "80px" }}
                                />
                              </div>
                            </div>
                          </div>

                        </MDBCardBody>
                      </MDBCard>
                    </MDBCol>
                  ))
                ) : (
                  <div
                    className="no-data-message d-flex justify-content-center align-items-center"
                    style={{ marginTop: '200px', height: '100%', width: '100%', paddingRight: '420px', }}
                  >
                    <p className="text-muted">No Data Found</p>
                  </div>
                )
                }
              </MDBRow>
            </MDBTabsPane>



          </MDBTabsContent>
          {smessage && (
            <h4>
              <Toast message={smessage} />
            </h4>
          )}
          <MDBModal open={basicModal} setOpen={setBasicModal} tabIndex="-1">
            <MDBModalDialog>
              {/* <form
              action="#"
              target="_blank"
              onSubmit={onSubmit}
              className="business-form w-100"
            > */}
              <MDBModalContent>
                <MDBModalHeader>
                  <MDBModalTitle>Edit Profile</MDBModalTitle>
                  <MDBBtn
                    className="btn-close"
                    color="none"
                    onClick={processCancel}
                  ></MDBBtn>
                </MDBModalHeader>
                <MDBModalBody>
                  <div className="BusinessPage__content">
                    <MDBCard className="shadow-0">
                      <MDBCardBody className=" py-0">
                        <MDBCardTitle></MDBCardTitle>
                        <MDBCardText>
                          <MDBRow>
                            <div className=" Business__Details__data">
                              <label>
                                First Name:
                                <span className="text-danger">*</span>
                              </label>{" "}
                              <label className="fw-600">
                                <input
                                  className="Business__input__adjust"
                                  type="text"
                                  name="firstname"
                                  id="firs_name"
                                  placeholder="First Name"
                                  value={form.firstname}
                                  onChange={(e) =>
                                    updateForm({ firstname: e.target.value })
                                  }
                                />
                                {error.firstname && (
                                  <>
                                    <p className="clr-red">{error.firstname}</p>
                                  </>
                                )}
                              </label>
                            </div>
                            <div className=" Business__Details__data">
                              <label>
                                Last Name:<span className="text-danger">*</span>
                              </label>{" "}
                              <label className="fw-600">
                                <input
                                  className="Business__input__adjust"
                                  type="text"
                                  name="lastname"
                                  id="last_name"
                                  placeholder="Last Name"
                                  value={form.lastname}
                                  onChange={(e) =>
                                    updateForm({ lastname: e.target.value })
                                  }
                                />
                                {error.lastname && (
                                  <>
                                    <p className="clr-red">{error.lastname}</p>
                                  </>
                                )}
                              </label>
                            </div>
                            <div className=" Business__Details__data">
                              <label>Age:</label> <label className="fw-600">
                                <input
                                  className="Business__input__adjust"
                                  type="text"
                                  name="age"
                                  id="age"
                                  placeholder="Age"
                                  value={form.age}
                                  onChange={(e) =>
                                    updateForm({ age: e.target.value })
                                  }
                                />
                              </label>
                            </div>
                            <div className=" Business__Details__data">
                              <label>
                                Gender:<span className="text-danger">*</span>
                              </label>{" "}
                              <label className="fw-600">
                                <select
                                  className="Business__input__adjust"
                                  name="gender"
                                  id="time"
                                  value={form.gender}
                                  onChange={(e) =>
                                    updateForm({ gender: e.target.value })
                                  }
                                >
                                  <option value="Male">Male</option>
                                  <option value="Female">Female</option>
                                </select>
                              </label>
                            </div>

                            <div className=" Business__Details__data">
                              <label>
                                Mobile Number:
                                <span className="text-danger">*</span>
                              </label>{" "}
                              <label className="fw-600">
                                <input
                                  className="Business__input__adjust"
                                  type="text"
                                  name="contact                                                                                                                                                          s_name"
                                  // id="contact"
                                  placeholder="Number"
                                  value={form.number}
                                  onChange={(e) =>
                                    updateForm({ number: e.target.value })
                                  }
                                />
                                {error.number && (
                                  <>
                                    <p className="clr-red">{error.number}</p>
                                  </>
                                )}
                              </label>
                            </div>
                          </MDBRow>
                        </MDBCardText>
                      </MDBCardBody>
                    </MDBCard>
                  </div>
                </MDBModalBody>

                <MDBModalFooter className="pe-3 pb-2">
                  <MDBBtn color="secondary" onClick={processCancel}>
                    cancel
                  </MDBBtn>
                  <MDBBtn onClick={onSubmit}>Save</MDBBtn>
                </MDBModalFooter>
              </MDBModalContent>
              {/* </form> */}
            </MDBModalDialog>
          </MDBModal>

          <MDBModal
            tabIndex="-1"
            open={centredModal}
            onClose={() => setCentredModal(false)}
          >
            <MDBModalDialog size="lg">
              <MDBModalContent>
                <MDBModalHeader>
                    <MDBModalTitle>Subscription History</MDBModalTitle>
                  <MDBBtn
                    className="btn-close"
                    color="none"
                    onClick={toggleOpenPlan}
                  ></MDBBtn>
                </MDBModalHeader>
                <MDBModalBody>
                    <MDBDatatable entries={25} data={basicData} className="SuscriptionHistoryDataTable" />
                </MDBModalBody>
                <MDBModalFooter className="pe-3 pb-2">
                  <MDBBtn color="secondary" onClick={toggleOpenPlan}>
                    Close
                  </MDBBtn>
                  <MDBBtn
                    className="ms-2"
                    onClick={() => downloaddata(basicData, "subscriptionPlans")}
                  >
                    download
                  </MDBBtn>
                </MDBModalFooter>
              </MDBModalContent>
            </MDBModalDialog>
          </MDBModal>

          {showBusinessPop && (
            <>
              <Popup
                title="Add gym"
                ptype="modal-lg"
                content={
                  <>
                    <AddGym onSuccess={handleAddGymSuccess} />
                  </>
                }
                handleClose={() => setShowBusinessPop(false)}
              />
            </>
          )}




          <MDBModal open={showPopup} onClose={() => setShowPopup(false)} tabIndex='-1'>
            <MDBModalDialog>
              <MDBModalContent>
                <MDBModalHeader>
                  <MDBModalTitle> Adding Gym - Verification Code</MDBModalTitle>
                  <MDBBtn className='btn-close' color='none' onClick={() => setShowPopup(false)}></MDBBtn>
                </MDBModalHeader>
                <MDBModalBody>
                  <div className="d-flex justify-content-center align-items-center">
                    <div className="text-center rounded" style={{ maxWidth: "400px", width: "100%" }}>
                      <h6 className="text-muted mb-4">
                        Enter the 6 digit verification code that was sent to{" "}
                        <strong>{maskedEmail}</strong>
                      </h6>

                      <div className="d-flex justify-content-center gap-2 mb-4">
                        {otp.map((data, index) => (
                          <MDBInput
                            id={`otp-input-${index}`}
                            type="text"
                            maxLength="1"
                            value={otp[index] || ""}
                            onChange={(e) => handleChange(e, index)}
                            onKeyDown={(e) => handleKeyDown(e, index)}
                            className="text-center fs-4"
                            style={{ width: "50px", height: "50px" }}
                            key={index}
                          />
                        ))}
                      </div>

                      <MDBBtn color="primary" block onClick={handleSubmit}>
                        Submit
                      </MDBBtn>

                      {/* Resend Option */}
                      <p className="mt-3 text-muted">
                        Didn't receive code?{" "}
                        <a href="#!" className="text-primary" onClick={handleResendClick}>
                          Resend
                        </a>
                      </p>
                    </div>
                  </div>
                </MDBModalBody>
              </MDBModalContent>
            </MDBModalDialog>
          </MDBModal>






          <MDBAlert
            color={(Error2.includes("aggregation") || Error2.includes("try again.")) ? "danger" : (Error2.includes("successfully") || Error2.includes("successfully") ? "success" : "danger")}
            autohide
            position="top-center"
            delay={5000}
            appendToBody
            open={Error2 !== ""}
            onClose={() => setError2("")}
          >
            {Error2}
          </MDBAlert>




          {
            showModel &&
            <MDBModal open={true} onClose={() => setShowModel(false)} tabIndex="-1">
              <MDBModalDialog>
                <MDBModalContent>
                  <MDBModalHeader>
                    <MDBModalTitle>Confirm Deletion</MDBModalTitle>
                    <MDBBtn className="btn-close" color="none" onClick={() => setShowModel(false)}></MDBBtn>
                  </MDBModalHeader>
                  <MDBModalBody>
                    <div className="text-center">
                      <p className="mb-4">
                        Are you sure you want to delete this gym?
                      </p>
                      <MDBBtn color="secondary" onClick={() => setShowModel(false)} className="me-2">
                        Cancel
                      </MDBBtn>
                      <MDBBtn color="danger" onClick={() => handleDeleteConfirmation(selectedGym)}>
                        Delete
                      </MDBBtn>
                    </div>
                  </MDBModalBody>
                </MDBModalContent>
              </MDBModalDialog>
            </MDBModal>
          }





        </div>
      )}
    </>
  );
};
export default Settings;
