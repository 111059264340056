import React, { useState, useEffect } from "react";
import GymLogo from "../components/images/Easy-MembrLogo.png";
import Google from "../components/images/Google.svg";
import {
    MDBBtn,
    MDBCard,
    MDBCardBody,
    MDBIcon,
    MDBInput,
} from "mdb-react-ui-kit";
import { loginUser, getBusinessName, logEvent } from "../services/apiServices";
import axios from "axios";
import { useNavigate } from "react-router";
import jwt_decode from "jwt-decode";
import { browserName, browserVersion } from "react-device-detect";

const QRLogin = () => {
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const [showPassword, setShowPassword] = useState(false);
    const [error, setError] = useState("");
    const navigate = useNavigate();
    const [businessData, setBusinessData] = useState(null);
    const [domain, setDomain] = useState("")

    // console.log("dominnnnnnnnnnnnnnnnnname", domain)

    useEffect(() => {
        const currentUrl = window.location.href;
        const urlObj = new URL(currentUrl);
        const domain = urlObj.hostname;
        console.log(domain);
        setDomain(domain)
    }, []);



    const togglePasswordVisibility = () => setShowPassword(!showPassword);

    useEffect(() => {
        const fetchIPAddress = async () => {
            try {
                const url = "https://api.ipify.org?format=json";
                const res = await axios.get(url);
                localStorage.setItem("ipaddress", res.data.ip);
            } catch (error) {
                console.error("Error fetching IP address:", error);
            }
        };

        fetchIPAddress();
    }, []);


    useEffect(() => {
        const token = localStorage.getItem("token");

        if (token) {
            navigate("/qrscanner");
        }
    }, []);

    const handleLogin = async (googleUser = null, manualCredentials = null) => {
        try {
            const credentials = googleUser || manualCredentials;

            if (!credentials) {
                setError("Missing credentials. Please try again.");
                return;
            }

            const userInfo = await loginUser(credentials);

            if (userInfo.ok) {
                const data = await userInfo.json();
                localStorage.setItem("token", data.token)
                localStorage.setItem("loggedUserInfo", JSON.stringify(data.userInfo));
                let ip = localStorage.getItem("ipaddress") || "--";

                let browserinfo = browserName + "-" + browserVersion + ".0.0";
                let Eventtype = "login";
                let flag = "Qr Login Successfull";
                let username1 = data.userInfo.email;
                let gymid = data.userInfo.gymid
                logEvent({ Eventtype, username1, ip, flag, browserinfo, gymid }, gymid);

                navigate("/qrscanner");
            } else {
                setError("Invalid login credentials. Please try again.");
            }
        } catch (error) {
            console.error("Error during login:", error);
            setError("Something went wrong. Please try again later.");
        }
    };

    const responseGmail = (resp) => {
        try {
            const decoded = jwt_decode(resp.credential);
            const googleUser = {
                username: decoded.email,
                loginsource: "Google",
                password: "defaultGooglePassword123",
            };
            handleLogin(googleUser);
        } catch (error) {
            console.error("Error decoding Google response:", error);
            setError("Google login failed. Please try again.");
        }
    };

    useEffect(() => {
        const initializeGoogleSignIn = () => {
            let CLIENT_ID = "102702637040-eau4e3o86jeoorqtveosi9fggnkduc7j.apps.googleusercontent.com";
            if (domain == "easymembr.zencruz.com" || domain == "easymembr-beta.zencruz.com") {

                CLIENT_ID = "102702637040-eau4e3o86jeoorqtveosi9fggnkduc7j.apps.googleusercontent.com";
            } else if (domain == "www.easymembr.com" || domain == "easymembr.com" || domain == "app.easymembr.com" || domain == "beta.easymembr.com") {
                CLIENT_ID = "102702637040-5o3s1v3v4dv3vndpo3pr3r3tic04npq1.apps.googleusercontent.com"
            }

            console.log(CLIENT_ID);

            if (window.google && window.google.accounts.id) {
                window.google.accounts.id.initialize({
                    client_id: CLIENT_ID,
                    callback: responseGmail,
                });
                window.google.accounts.id.renderButton(
                    document.getElementById("signinDiv"),
                    { theme: "outline", size: "large" }
                );
            } else {
                console.error("Google Sign-In SDK not loaded.");
            }
        };

        initializeGoogleSignIn();
    }, [domain]);




    useEffect(() => {
        const fetchData = async () => {
            try {
                const datar = JSON.parse(localStorage.getItem("loggedUserInfo")).gymid
                console.log("datar value:", datar);

                if (datar) {
                    const updates = { bId: datar };
                    console.log("Request payload:", updates);

                    const result = await getBusinessName(updates);

                    console.log("API result:", result);

                    if (result && result.json) {
                        const jsonData = await result.json();
                        console.log("99999999999999999999999999990", jsonData)

                        //    localStorage.setItem("loggedUserInfo", JSON.stringify(userInfo));

                        setBusinessData(jsonData);
                        console.log("Business data set:", jsonData);
                    } else {
                        console.error("API returned no data or null.");
                    }
                } else {
                    console.warn("datar is undefined or null. Skipping API call.");
                }
            } catch (error) {
                console.error("Error fetching business name:", error);
            }
        };

        fetchData();
    }, []);

    return (
        <div className="h-100vhWave">
            <MDBCard className="waveForm custom-shadow">
                <MDBCardBody className="py-3 position-relative">
                    <div className="mobile-Head mb-4">
                        <div className="d-flex align-items-center justify-content-between Waiver-logo">
                            <img src={GymLogo} className="w-30 mob-w-40" alt="Gym Logo" />
                            {businessData?.logourl ? (
                                <img
                                    src={businessData.logourl}
                                    alt="Business Logo"
                                    className="w-20 mob-w-40"
                                />
                            ) : (
                                <div className="w-20 mob-w-40 bg-gray-200"></div>
                            )}

                        </div>
                    </div>

                    <div className="w-100 d-flex flex-column align-items-center pb-2">
                        <div className="w-70 w-sm-100">
                            <label className="mb-2 fw-bold" htmlFor="email">Email</label>
                            <MDBInput
                                color="success"
                                size="lg"
                                placeholder="Enter Your Email"
                                id="email"
                                className="rounded-0"
                                onChange={(e) => setUsername(e.target.value)}
                            />
                        </div>
                        <div className="mt-4 w-70 w-sm-100">
                            <label className="mb-2 fw-bold" htmlFor="password">Password</label>
                            <div className="position-relative">
                                <MDBInput
                                    size="lg"
                                    placeholder="Password"
                                    id="password"
                                    type={showPassword ? "text" : "password"}
                                    name="password_id"
                                    onChange={(e) => setPassword(e.target.value)}
                                    className="mb-2 pe-5"
                                />
                                <MDBIcon
                                    fas
                                    icon={showPassword ? "eye" : "eye-slash"}
                                    onClick={togglePasswordVisibility}
                                    style={{ cursor: "pointer" }}
                                    className="inputIconEnd"
                                />
                            </div>
                        </div>
                        <div id="signinDiv" className="mt-3 w-100"></div>
                        <div className="mt-4 w-70 w-sm-100">
                            <MDBBtn
                                className="text-capitalize w-100 shadow-3 fs-6"
                                onClick={() => handleLogin(null, { username, password })}
                            >
                                Login
                            </MDBBtn>
                        </div>
                        <p className="text-danger mt-3">{error}</p>
                    </div>
                </MDBCardBody>
                {/* <MDBCardFooter className="border-0 text-center">
                    <p class="mt-2 text-muted">© 2025 Zencruz.</p>
                </MDBCardFooter> */}
            </MDBCard>

        </div>
    );
};

export default QRLogin;
